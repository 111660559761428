import { ChangeDetectionStrategy, ChangeDetectorRef, Component, inject, Input, OnInit } from '@angular/core';
import { ComposerInstance, ComposerMessageType } from '@conversations/composer/state/composers/composers-state.model';
import { AsyncPipe } from '@angular/common';
import { Actions, ofActionCompleted, Store } from '@ngxs/store';
import { combineLatest, defer, Observable, of } from 'rxjs';
import { animate, state, style, transition, trigger } from '@angular/animations';
import { NgScrollbarModule } from 'ngx-scrollbar';
import { CdkScrollableModule } from '@angular/cdk/scrolling';

import { UntilDestroy, untilDestroyed } from '@ngneat/until-destroy';
import { ComposersSelectors } from '@conversations/composer/state/composers/composers.selectors';
import { ConversationSelectors } from '@conversations/conversation/state/conversation/conversation.selectors';
import { filter, map, switchMap, take, tap } from 'rxjs/operators';
import { UserService } from '@core/services/user.service';
import { type CompactMessage } from '@conversations/conversation/state/conversation/conversation-state.model';
import {
  LoadConversation,
  LoadMessagesAfterDate,
} from '@conversations/conversation/state/conversation/conversation.actions';
import { subSeconds } from 'date-fns';
import { Navigate } from '@ngxs/router-plugin';
import { CONVERSATIONS_BASE_URL } from '@conversations/routes';
import { WorkspacesSelectors } from '@conversations/workspaces/state/workspaces/workspaces.selectors';
import {
  ConversationsCategory,
  defaultStatusCategorySegmentOption,
  statusCategorySegmentParam,
} from '@conversations/conversations/categories';
import { ComposerHeaderComponent } from '@conversations/composer/composer-header/composer-header.component';
import { ComposerMessageTypeSelectorComponent } from '@conversations/composer/composer-message-type-selector/composer-message-type-selector.component';
import { ComposerReplyToMessagePreviewComponent } from '@conversations/composer/composer-reply-to-message-preview/composer-reply-to-message-preview.component';
import { ComposerSubjectInputComponent } from '@conversations/composer/composer-subject-input/composer-subject-input.component';
import { ComposerMessageInputComponent } from '@conversations/composer/composer-message-input/composer-message-input.component';
import { ComposerActionsComponent } from '@conversations/composer/composer-actions/composer-actions.component';
import { ComposerQuoteInputComponent } from '@conversations/composer/composer-quote-input/composer-quote-input.component';
import { ComposerAttachmentsComponent } from '@conversations/composer/composer-attachments/composer-attachments.component';

@UntilDestroy()
@Component({
  selector: 'cc-composer',
  standalone: true,
  templateUrl: './composer.component.html',
  styleUrls: ['./composer.component.scss'],
  changeDetection: ChangeDetectionStrategy.OnPush,
  imports: [
    ComposerHeaderComponent,
    ComposerMessageTypeSelectorComponent,
    AsyncPipe,
    ComposerReplyToMessagePreviewComponent,
    ComposerSubjectInputComponent,
    ComposerMessageInputComponent,
    NgScrollbarModule,
    CdkScrollableModule,
    ComposerActionsComponent,
    ComposerQuoteInputComponent,
    ComposerAttachmentsComponent,
  ],
  animations: [
    trigger('minimizeAnimation', [
      state('overlay-collapsed', style({ height: '0', opacity: '0' })),
      state('overlay-expanded', style({ height: '*', opacity: '*' })),
      state('inline', style({ height: '*', opacity: '*' })),
      transition('overlay-collapsed <=> overlay-expanded', animate('0.2s ease-in-out')),
    ]),
  ],
})
export class ComposerComponent implements OnInit {
  @Input()
  composer: ComposerInstance;

  protected currentUserIsAssignee = false;
  protected disabled = false;
  protected allowedMessageTypes$: Observable<Exclude<ComposerMessageType, 'new'>[]>;

  private readonly store = inject(Store);
  private readonly actions$ = inject(Actions);
  private readonly userService = inject(UserService);
  private readonly cdr = inject(ChangeDetectorRef);

  ngOnInit(): void {
    this.allowedMessageTypes$ = this.store.select(ComposersSelectors.allowedMessageTypes(this.composer.id));

    this.store
      .select(ConversationSelectors.details)
      .pipe(
        untilDestroyed(this),
        filter((details) => this.composer.messageType !== 'new' && details?.id === this.composer.conversationId),
        map((details) => details.assignment.assignee),
        tap((assignee) => {
          const privateWorkspace = this.store.selectSnapshot(WorkspacesSelectors.privateWorkspace);
          if (this.composer.workspaceId === privateWorkspace.id) {
            this.currentUserIsAssignee = true;
            this.cdr.detectChanges();
            return;
          }

          this.currentUserIsAssignee = assignee?.id === this.userService.userProfile.id.toString();
          this.cdr.detectChanges();
        }),
      )
      .subscribe();

    combineLatest([
      this.store.select(ConversationSelectors.details),
      this.store.select(ConversationSelectors.parentAccountIssue),
    ])
      .pipe(
        filter(([details]) => !!details),
        filter(([details]) => details.id === this.composer.conversationId),
        // eslint-disable-next-line @typescript-eslint/no-unused-vars
        map(([_, parentAccountIssue]) => !!parentAccountIssue),
      )
      .subscribe((hasParentAccountIssue) => {
        this.disabled = hasParentAccountIssue;
        this.cdr.detectChanges();
      });
  }

  jumpToMessage(message: CompactMessage): void {
    const activeConversationId = this.store.selectSnapshot(ConversationSelectors.details)?.id;

    defer(() => {
      if (activeConversationId === message.conversationId) return of(undefined);

      const workspaceAlias = this.store.selectSnapshot(
        WorkspacesSelectors.workspaceAliasById(this.composer.workspaceId),
      );

      return this.store
        .dispatch(
          new Navigate([
            '/',
            CONVERSATIONS_BASE_URL,
            workspaceAlias,
            ConversationsCategory.Inbox,
            { [statusCategorySegmentParam]: defaultStatusCategorySegmentOption },
            this.composer.conversationId,
          ]),
        )
        .pipe(switchMap(() => this.actions$.pipe(ofActionCompleted(LoadConversation), take(1))));
    })
      .pipe(
        untilDestroyed(this),
        switchMap(() =>
          this.store.dispatch(new LoadMessagesAfterDate(subSeconds(new Date(message.createdAt), 1).toISOString())),
        ),
      )
      .subscribe();
  }
}
