import { CdkConnectedOverlay, ConnectionPositionPair } from '@angular/cdk/overlay';
import { ChangeDetectionStrategy, ChangeDetectorRef, Component, inject, Input, OnInit } from '@angular/core';
import { getOverlayVisibilityAfterOutsideClick } from '@core/helpers/get-overlay-visibility-after-outside-click';
import { ButtonComponent } from '@design/buttons/button/button.component';
import { ButtonSize, ButtonType } from '@design/buttons/button/types';
import { DropdownActionComponent } from '@design/overlays/dropdown/dropdown-action/dropdown-action.component';
import { DropdownDividerComponent } from '@design/overlays/dropdown/dropdown-divider/dropdown-divider.component';
import { DropdownTextComponent } from '@design/overlays/dropdown/dropdown-text/dropdown-text.component';
import { DropdownComponent } from '@design/overlays/dropdown/dropdown.component';
import { TooltipDirective } from '@design/overlays/tooltip/tooltip.directive';
import { UntilDestroy, untilDestroyed } from '@ngneat/until-destroy';
import { TranslateModule } from '@ngx-translate/core';
import { Editor } from '@tiptap/core';
import { Subject } from 'rxjs';

type TableActions =
  | 'insertTable'
  | 'deleteTable'
  | 'addColumnBefore'
  | 'addColumnAfter'
  | 'deleteColumn'
  | 'addRowAbove'
  | 'addRowBelow'
  | 'deleteRow'
  | 'mergeCells'
  | 'splitCell'
  | 'toggleHeaderCell';

@UntilDestroy()
@Component({
  selector: 'cc-composer-actions-table-editor',
  standalone: true,
  imports: [
    ButtonComponent,
    CdkConnectedOverlay,
    DropdownComponent,
    TooltipDirective,
    DropdownTextComponent,
    DropdownActionComponent,
    DropdownDividerComponent,
    TranslateModule,
  ],
  templateUrl: './composer-actions-table-editor.component.html',
  changeDetection: ChangeDetectionStrategy.OnPush,
})
export class ComposerActionsTableEditorComponent implements OnInit {
  @Input()
  editor: Editor;

  @Input()
  selectionUpdate$: Subject<void>;

  protected tableDropdownVisible = false;
  protected readonly dropdownPositionStrategy: ConnectionPositionPair[] = [
    { originX: 'start', originY: 'top', overlayX: 'start', overlayY: 'bottom', offsetY: -4 },
    { originX: 'end', originY: 'top', overlayX: 'end', overlayY: 'bottom', offsetY: -4 },
  ];

  protected readonly ButtonSize = ButtonSize;
  protected readonly getOverlayVisibilityAfterOutsideClick = getOverlayVisibilityAfterOutsideClick;
  protected readonly ButtonType = ButtonType;

  private readonly cdr = inject(ChangeDetectorRef);

  get tableFocused(): boolean {
    return this.editor.isActive('table');
  }

  get canMergeCells(): boolean {
    return this.editor.can().mergeCells();
  }

  get canSplitCell(): boolean {
    return this.editor.can().splitCell();
  }

  get canDeleteColumn(): boolean {
    return this.editor.can().deleteColumn();
  }

  get canDeleteRow(): boolean {
    return this.editor.can().deleteRow();
  }

  get isHeaderCell(): boolean {
    return this.editor.isActive('tableHeader');
  }

  ngOnInit() {
    this.selectionUpdate$.pipe(untilDestroyed(this)).subscribe(() => this.cdr.detectChanges());
  }

  protected handleTableAction(action: TableActions): void {
    switch (action) {
      case 'insertTable':
        this.editor.chain().focus().insertTable({ rows: 3, cols: 3 }).run();
        break;
      case 'deleteTable':
        this.editor.chain().deleteTable().focus().run();
        break;
      case 'addColumnBefore':
        this.editor.chain().addColumnBefore().focus().run();
        break;
      case 'addColumnAfter':
        this.editor.chain().addColumnAfter().focus().run();
        break;
      case 'deleteColumn':
        this.editor.chain().deleteColumn().focus().run();
        break;
      case 'addRowAbove':
        this.editor.chain().addRowBefore().focus().run();
        break;
      case 'addRowBelow':
        this.editor.chain().addRowAfter().focus().run();
        break;
      case 'deleteRow':
        this.editor.chain().deleteRow().focus().run();
        break;
      case 'mergeCells':
        this.editor.chain().mergeCells().focus().run();
        break;
      case 'splitCell':
        this.editor.chain().splitCell().focus().run();
        break;
      case 'toggleHeaderCell':
        this.editor.chain().toggleHeaderCell().focus().run();
        break;
    }

    this.tableDropdownVisible = false;
    this.cdr.detectChanges();
  }
}
