@if (items.length > 0) {
  <cc-dropdown
    [shadow]="false"
    [trapFocus]="false"
  >
    @for (item of items; track item.id) {
      <cc-dropdown-action (click)="accept(item)">
        <ng-container slot="leading"> #{{ item.id }}</ng-container>
        <ng-container slot="title">{{ item.label }}</ng-container>
      </cc-dropdown-action>
    }
  </cc-dropdown>
}
