import { Action, State, StateContext, Store } from '@ngxs/store';
import { inject, Injectable } from '@angular/core';
import {
  ConversationStateModel,
  defaultConversationState,
  Message,
} from '@conversations/conversation/state/conversation/conversation-state.model';
import {
  AddNewMessage,
  AddPendingMessage,
  HandleConversationUpdate,
  LoadConversation,
  LoadMessagesAfterDate,
  LoadNextMessages,
  LoadPreviousMessages,
  PatchConversationDetails,
  PatchConversationMessage,
  ReloadConversationDetails,
  ResetConversationState,
} from '@conversations/conversation/state/conversation/conversation.actions';
import { delay, forkJoin, Observable, of } from 'rxjs';
import { catchError, map, tap } from 'rxjs/operators';
import { ConversationService } from '@conversations/conversation/state/conversation/conversation.service';
import { PagingOrder } from '@core/helpers/paging';
import * as R from 'ramda';
import { append, patch, removeItem, updateItem } from '@ngxs/store/operators';
import { prepend } from '@core/helpers/custom-state-operators';
import { ConversationSelectors } from '@conversations/conversation/state/conversation/conversation.selectors';
import { ConversationScrollService } from '@conversations/conversation/state/conversation/conversation-scroll.service';
import { ConversationActionsDistributorService } from '@conversations/conversation/state/conversation/conversation-actions-distributor.service';

const loadLimit = 50;
const lazyLoadLimit = 25;

@State<ConversationStateModel>({
  name: 'conversation',
  defaults: defaultConversationState,
})
@Injectable()
export class ConversationState {
  private loadedConversationId: string | null = null;

  private readonly conversationService = inject(ConversationService);
  private readonly conversationActionsService = inject(ConversationActionsDistributorService);
  private readonly conversationScrollService = inject(ConversationScrollService);
  private readonly store = inject(Store);

  @Action(LoadConversation, { cancelUncompleted: true })
  loadConversation(ctx: StateContext<ConversationStateModel>, { conversationId }: LoadConversation): Observable<void> {
    const state = ctx.getState();

    const alreadyLoaded = this.loadedConversationId === conversationId && state.loadingStatus === 'loaded';
    if (alreadyLoaded) return of();

    ctx.patchState({ loadingStatus: 'loading', messagesLoadingStatus: 'loading' });

    return forkJoin([
      this.conversationService.getConversationDetails(conversationId),
      this.conversationService.getMessages(conversationId, {
        limit: loadLimit,
        order: PagingOrder.Descending,
        orderBy: 'created_at',
      }),
    ]).pipe(
      tap(([details, messagesResponse]) => {
        ctx.setState(
          patch<ConversationStateModel>({
            details,
            messages: patch({
              ...messagesResponse,
              data: R.reverse(messagesResponse.data),
              cursor: patch({ previous: messagesResponse.cursor.next, next: messagesResponse.cursor.previous }), // Cursors are reversed.
            }),
            pendingMessages: [],
            loadingStatus: 'loaded',
            messagesLoadingStatus: 'loaded',
          }),
        );

        this.loadedConversationId = conversationId;
      }),
      catchError((error) => {
        console.error('Failed to load conversation', error);
        ctx.patchState({ loadingStatus: 'error', messagesLoadingStatus: 'error' });
        return of();
      }),
      map(() => undefined),
    );
  }

  @Action(LoadNextMessages, { cancelUncompleted: true })
  loadNextMessages(ctx: StateContext<ConversationStateModel>): Observable<void> {
    const state = ctx.getState();
    const { loadingStatus } = state;
    const nextCursor = state.messages.cursor.next;

    const conversationId = this.loadedConversationId;
    if (!conversationId || !nextCursor || loadingStatus !== 'loaded') return of();

    ctx.patchState({ messagesLoadingStatus: 'loading-next' });

    return this.conversationService
      .getMessages(conversationId, {
        limit: lazyLoadLimit,
        cursor: nextCursor,
        order: PagingOrder.Ascending,
        orderBy: 'created_at',
      })
      .pipe(
        tap((nextMessagesResponse) => {
          ctx.setState(
            patch<ConversationStateModel>({
              messages: patch({
                data: append(nextMessagesResponse.data),
                count: state.messages.count + nextMessagesResponse.count,
                cursor: patch({ next: nextMessagesResponse.cursor.next }),
                paging: nextMessagesResponse.paging,
              }),
              messagesLoadingStatus: 'loaded',
            }),
          );
        }),
        catchError(() => {
          ctx.patchState({ messagesLoadingStatus: 'error' });
          return of();
        }),
        map(() => undefined),
      );
  }

  @Action(LoadPreviousMessages, { cancelUncompleted: true })
  loadPreviousMessages(ctx: StateContext<ConversationStateModel>): Observable<void> {
    const state = ctx.getState();
    const { loadingStatus } = state;
    const previousCursor = state.messages.cursor.previous;

    const conversationId = this.loadedConversationId;
    if (!conversationId || !previousCursor || loadingStatus !== 'loaded') return of();

    ctx.patchState({ messagesLoadingStatus: 'loading-prev' });

    return this.conversationService
      .getMessages(conversationId, {
        limit: lazyLoadLimit,
        cursor: previousCursor,
        order: PagingOrder.Descending,
        orderBy: 'created_at',
      })
      .pipe(
        tap((prevMessagesResponse) => {
          ctx.setState(
            patch<ConversationStateModel>({
              messages: patch({
                data: prepend(R.reverse(prevMessagesResponse.data)),
                count: state.messages.count + prevMessagesResponse.count,
                cursor: patch({ previous: prevMessagesResponse.cursor.next }), // Cursor is reversed.
                paging: prevMessagesResponse.paging,
              }),
              messagesLoadingStatus: 'loaded',
            }),
          );
        }),
        catchError(() => {
          ctx.patchState({ messagesLoadingStatus: 'error' });
          return of();
        }),
        map(() => undefined),
      );
  }

  @Action(LoadMessagesAfterDate, { cancelUncompleted: true })
  loadMessagesAfterDate(ctx: StateContext<ConversationStateModel>, { date }: LoadMessagesAfterDate): Observable<void> {
    const state = ctx.getState();
    const conversationId = this.loadedConversationId;

    if (!conversationId || state.loadingStatus !== 'loaded') return of();

    // Check if there is a need to reload messages.
    // If state has messages after the provided date, and there are loaded messages before the date,
    // then we can skip reloading messages and just scroll to the first message after the date.
    const hasMessagesBeforeDate = state.messages.data.some((m) => new Date(m.createdAt) < new Date(date));
    const firstMessageAfterDate = state.messages.data.find((m) => new Date(m.createdAt) >= new Date(date));
    if (firstMessageAfterDate && hasMessagesBeforeDate) {
      this.conversationScrollService.triggerScrollToMessage(firstMessageAfterDate.id);
      return of();
    }

    ctx.patchState({ messagesLoadingStatus: 'loading' });

    return forkJoin([
      this.conversationService.getMessages(
        conversationId,
        {
          limit: loadLimit,
          order: PagingOrder.Ascending,
          orderBy: 'created_at',
        },
        date,
      ),
      this.conversationService.getMessages(
        conversationId,
        {
          limit: lazyLoadLimit,
          order: PagingOrder.Descending,
          orderBy: 'created_at',
        },
        undefined,
        date,
      ),
    ]).pipe(
      map(([messagesAfterDateResponse, prevMessagesResponse]) => {
        ctx.setState(
          patch<ConversationStateModel>({
            messages: {
              data: [...R.reverse(prevMessagesResponse.data), ...messagesAfterDateResponse.data],
              count: prevMessagesResponse.count + messagesAfterDateResponse.count,
              total: messagesAfterDateResponse.total,
              cursor: { previous: prevMessagesResponse.cursor.next, next: messagesAfterDateResponse.cursor.next }, // Cursor is reversed.
              paging: prevMessagesResponse.paging,
            },
            messagesLoadingStatus: 'loaded',
          }),
        );

        const firstMessageId = messagesAfterDateResponse?.data[0]?.id;
        if (firstMessageId) this.conversationScrollService.triggerScrollToMessage(firstMessageId);
      }),
      catchError(() => {
        ctx.patchState({ messagesLoadingStatus: 'error' });
        return of();
      }),
      map(() => undefined),
    );
  }

  @Action(PatchConversationDetails)
  patchConversationDetails(
    ctx: StateContext<ConversationStateModel>,
    { conversationId, patch: patchedValue }: PatchConversationDetails,
  ): void {
    const state = ctx.getState();
    if (state.details?.id !== conversationId) return;

    ctx.setState(
      patch<ConversationStateModel>({
        details: patchedValue,
      }),
    );
  }

  @Action(ReloadConversationDetails)
  reloadConversationDetails(
    ctx: StateContext<ConversationStateModel>,
    { conversationId }: ReloadConversationDetails,
  ): Observable<void> {
    const state = ctx.getState();
    if (state.details?.id !== conversationId) return;

    return this.conversationService.getConversationDetails(conversationId).pipe(
      tap((details) => {
        ctx.setState(
          patch<ConversationStateModel>({
            details,
          }),
        );
      }),
      map(() => undefined),
    );
  }

  @Action(AddNewMessage)
  addNewMessage(
    ctx: StateContext<ConversationStateModel>,
    { conversationId, messageId, traceId }: AddNewMessage,
  ): Observable<void> {
    const state = ctx.getState();
    if (state.details?.id !== conversationId) {
      this.conversationActionsService.handleConversationCreation(conversationId);
      return;
    }

    const removePendingMessage = () => {
      if (!traceId) return;

      ctx.setState(
        patch<ConversationStateModel>({
          pendingMessages: removeItem((m) => m.traceId === traceId),
        }),
      );
    };

    const checkIfMessageExists = () => {
      return ctx.getState().messages.data.some((m) => m.id === messageId);
    };

    const latestMessagesLoaded = this.store.selectSnapshot(ConversationSelectors.hasNextMessages) === false;
    const messageAlreadyExists = checkIfMessageExists();
    if (!latestMessagesLoaded || messageAlreadyExists) {
      removePendingMessage();
      return;
    }

    return this.conversationService.getMessage(conversationId, messageId).pipe(
      // If traceId is not provided, than there is a possibility that we will receive
      // a new event for the same message with the traceId in a few moments.
      // When we receive a message with a traceId, we will be able to remove the
      // pending message at the same time as we add the new message, so the UI will
      // not flicker and show the pending message along with the new one.
      delay(traceId ? 0 : 1000),
      tap((message) => {
        // Recheck if the message exists, as it could have been added
        // while the request was in progress.
        const messageAlreadyExists = checkIfMessageExists();
        if (messageAlreadyExists) return;

        ctx.setState(
          patch<ConversationStateModel>({
            messages: patch({
              data: append([message]),
              count: state.messages.count + 1,
            }),
          }),
        );
        removePendingMessage();
      }),
      catchError((error) => {
        removePendingMessage();
        return of(error);
      }),
      map(() => undefined),
    );
  }

  @Action(AddPendingMessage)
  addPendingMessage(
    ctx: StateContext<ConversationStateModel>,
    { conversationId, pendingMessage }: AddPendingMessage,
  ): void {
    const state = ctx.getState();
    if (state.details?.id !== conversationId) return;

    ctx.setState(
      patch<ConversationStateModel>({
        pendingMessages: append([pendingMessage]),
      }),
    );
  }

  @Action(PatchConversationMessage)
  patchConversationMessage(
    ctx: StateContext<ConversationStateModel>,
    { conversationId, messageId, patch: patchedValue }: PatchConversationMessage,
  ): void {
    const state = ctx.getState();
    if (state.details?.id !== conversationId) return;

    const messageIndex = state.messages.data.findIndex((m) => m.id === messageId);
    if (messageIndex === -1) return;

    ctx.setState(
      patch<ConversationStateModel>({
        messages: patch({
          data: updateItem<Message>((m) => m.id === messageId, patchedValue),
        }),
      }),
    );
  }

  @Action(HandleConversationUpdate)
  handleConversationUpdate(
    _: StateContext<ConversationStateModel>,
    { event: { conversationId } }: HandleConversationUpdate,
  ): void {
    this.conversationActionsService.handleConversationUpdate(conversationId);
  }

  @Action(ResetConversationState)
  resetConversationState(ctx: StateContext<ConversationStateModel>): void {
    ctx.setState(defaultConversationState);
  }
}
