import { ChangeDetectionStrategy, Component, inject } from '@angular/core';
import { Store } from '@ngxs/store';
import { ComposersSelectors } from '@conversations/composer/state/composers/composers.selectors';
import { AsyncPipe } from '@angular/common';
import { CdkScrollableModule } from '@angular/cdk/scrolling';
import { NgScrollbarModule } from 'ngx-scrollbar';
import { animate, style, transition, trigger } from '@angular/animations';
import { ComposerComponent } from '@conversations/composer/composer.component';

@Component({
  selector: 'cc-overlay-composers',
  standalone: true,
  templateUrl: './overlay-composers.component.html',
  styleUrls: ['./overlay-composers.component.scss'],
  changeDetection: ChangeDetectionStrategy.OnPush,
  imports: [AsyncPipe, ComposerComponent, CdkScrollableModule, NgScrollbarModule],
  animations: [
    trigger('enterLeaveTransition', [
      transition(':enter', [
        style({ height: 0, marginBottom: 0 }),
        animate(
          '0.3s ease-in-out',
          style({
            height: '*',
            marginBottom: '12px',
          }),
        ),
      ]),
      transition(':leave', [
        style({
          height: '*',
          marginBottom: '12px',
        }),
        animate('0.3s ease-in-out', style({ height: 0, marginBottom: 0 })),
      ]),
    ]),
  ],
})
export class OverlayComposersComponent {
  overlayComposers$ = inject(Store).select(ComposersSelectors.overlayComposers);
}
