<div class="business-object-modal">
  <div class="business-object-modal__header">
    <div class="business-object-modal__header-title">Select business object</div>

    <app-button
      (click)="closeModal.emit()"
      [size]="ButtonSize.Small"
      [type]="ButtonType.Ghost"
    >
      <i
        class="icon-close"
        slot="ghost-icon"
      ></i>
    </app-button>
  </div>

  <div class="business-object-modal__body">
    <cc-tab-selector
      class="business-object-modal__body-tabs"
      (tabSelect)="setActiveTab($event)"
      [inlinePadding]="4"
      [selectedTabId]="activeTabId"
      [tabs]="tabs"
    />

    <div
      class="business-object-modal__body-content"
      [ccAutoAnimate]
    >
      @switch (activeTabId) {
        @case ('orders') {
          <cc-bpi-order-table (selectOrder)="selectOrder($event)" />
        }
        @case ('invoices') {
          <cc-bpi-invoice-table (selectInvoice)="selectInvoice($event)" />
        }
      }
    </div>
  </div>
</div>
