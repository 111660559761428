import { inject, Injectable } from '@angular/core';
import { HttpService } from '@core/services/http.service';
import { Observable } from 'rxjs';
import { ICompanyWikiDocument } from '@network/company/state/wiki/wiki.entities';

@Injectable({
  providedIn: 'root',
})
export class WikiService {
  private readonly _http = inject(HttpService);

  getWiki(companyId: string): Observable<ICompanyWikiDocument[]> {
    return this._http.getV2<ICompanyWikiDocument[]>(`api/companies/${companyId}/resourcePages`);
  }

  addPage(companyId: string, parentPageId: number) {
    return this._http.postV2(`api/companies/${companyId}/resourcePages`, {
      name: 'Untitled',
      parentPageId,
      content: '...',
    });
  }

  getPageContent(companyId: string, resourceId: number) {
    return this._http.getV2(`api/companies/${companyId}/resourcePages/${resourceId}/content`, null, null, 'text');
  }

  updatePageContent(companyId: string, resourceId: string, document: ICompanyWikiDocument) {
    return this._http.putV2(`api/companies/${companyId}/resourcePages/${resourceId}`, {
      name: document.name,
      content: document.markdownContent,
    });
  }

  deletePage(companyId: string, resourceId: number) {
    return this._http.deleteV2(`api/companies/${companyId}/resourcePages/${resourceId}`);
  }

  movePage(companyId: string, resourceId: number, parentPageId: number) {
    return this._http.patchV2(`api/companies/${companyId}/resourcePages/${resourceId}/move`, { parentPageId });
  }
}
