<div class="company-wiki-content-area">
  <div class="company-wiki-content-area__header">
    <div class="company-wiki-content-area__header-breadcrumbs">
      @for (breadcrumb of document.path; track breadcrumb; let isLast = $last) {
        <span
          [attr.role]="isLast ? 'none' : 'button'"
          (click)="navigateToDocument(breadcrumb.id)"
          class="company-wiki-content-area__header-breadcrumbs-item"
          [class.company-wiki-content-area__header-breadcrumbs-item--active]="isLast"
          [ccTooltip]="breadcrumb.title"
          [showOnlyOnOverflow]="true"
          tooltipMaxWidth="min(400px, 90vw)"
        >
          {{ breadcrumb.title }}
        </span>
        @if (!isLast) {
          <span class="company-wiki-content-area__header-breadcrumbs-separator"> / </span>
        }
      }
    </div>

    <div #actionsDropdownTrigger>
      @if (canEdit$ | async) {
        <app-button
          (click)="isActionsDropdownVisible = !isActionsDropdownVisible"
          [size]="ButtonSize.Big"
          [type]="ButtonType.Ghost"
        >
          <i
            class="icon-more"
            slot="ghost-icon"
          ></i>
        </app-button>
      }
    </div>
  </div>

  <div
    #scrollableArea
    class="company-wiki-content-area__content"
  >
    <div class="company-wiki-content-area__content--wrapper">
      <div class="company-wiki-content-area__content-header">
        <div class="company-wiki-content-area__content-header-metadata">
          <div class="company-wiki-content-area__content-header-metadata-author">
            <img
              [alt]="author.fullName"
              [src]="author.logoUrl"
              class="company-wiki-content-area__content-header-metadata-author-avatar"
              height="40px"
              width="40px"
            />

            <span class="company-wiki-content-area__content-header-metadata-author-name">
              {{ author.fullName }}
            </span>
          </div>

          <span class="company-wiki-content-area__content-header-metadata-date">
            {{ document.updatedAt | date: 'MMM dd, yyyy' }}
          </span>
        </div>
      </div>

      <markdown
        (click)="interceptMarkdownLinkClick($event)"
        [data]="document.markdownContent"
        class="company-wiki-content-area__content-markdown"
      ></markdown>
    </div>
  </div>
</div>

<ng-template
  (detach)="isActionsDropdownVisible = false"
  (overlayOutsideClick)="
    isActionsDropdownVisible = getOverlayVisibilityAfterOutsideClick($event, actionsDropdownTrigger)
  "
  [cdkConnectedOverlayOpen]="isActionsDropdownVisible"
  [cdkConnectedOverlayOrigin]="actionsDropdownTrigger"
  [cdkConnectedOverlayPositions]="actionsDropdownPositionStrategy"
  cdkConnectedOverlay
>
  <div class="email-recipient-dropdown">
    <cc-dropdown width="240px">
      <cc-dropdown-action
        (click)="addSubPage(); isActionsDropdownVisible = false"
        leadingIcon="icon-create-task"
      >
        <ng-container slot="title"> Add sub-page</ng-container>
      </cc-dropdown-action>

      <cc-dropdown-action
        (click)="openMovePage(); isActionsDropdownVisible = false"
        leadingIcon="icon-move"
      >
        <ng-container slot="title"> Move page</ng-container>
      </cc-dropdown-action>

      <cc-dropdown-action
        (click)="enterEditMode(); isActionsDropdownVisible = false"
        leadingIcon="icon-edit-pencil"
      >
        <ng-container slot="title"> Edit</ng-container>
      </cc-dropdown-action>

      <cc-dropdown-action
        (click)="deleteDocument(); isActionsDropdownVisible = false"
        leadingIcon="icon-delete"
      >
        <ng-container slot="title"> Delete</ng-container>
      </cc-dropdown-action>
    </cc-dropdown>
  </div>
</ng-template>

<ng-template #movePageModal>
  <cc-move-page
    (cancel)="closeModal()"
    (confirm)="movePage($event)"
  >
  </cc-move-page>
</ng-template>
