import { Extension } from '@tiptap/core';
import { Gapcursor } from '@tiptap/extension-gapcursor';
import { Table } from '@tiptap/extension-table';
import { TableCell } from '@tiptap/extension-table-cell';
import { TableHeader } from '@tiptap/extension-table-header';
import { TableRow } from '@tiptap/extension-table-row';

const tableStyles = 'border-collapse: collapse;' + 'width: 100%;' + 'margin: 8px 0;';

const thTdStyles =
  'min-height: 36px;' +
  'text-align: left;' +
  'border: 1px solid #e8eaeb;' +
  'padding: 8px 12px;' +
  'vertical-align: top;';

const thStyles = thTdStyles + 'background-color: #fafbfc;' + 'font-weight: unset;';
const tdStyles = thTdStyles + 'background-color: #ffffff;';

export const TableKit = Extension.create({
  name: 'tableKit',

  addExtensions() {
    return [
      Table.configure({
        resizable: true,
        lastColumnResizable: false,
        HTMLAttributes: {
          style: tableStyles,
        },
      }),
      TableRow,
      TableHeader.configure({
        HTMLAttributes: {
          style: thStyles,
        },
      }),
      TableCell.configure({
        HTMLAttributes: {
          style: tdStyles,
        },
      }),
      Gapcursor,
    ];
  },
});
