import { Clipboard } from '@angular/cdk/clipboard';
import { Dialog, DialogModule } from '@angular/cdk/dialog';
import { CdkScrollableModule } from '@angular/cdk/scrolling';

import { ChangeDetectionStrategy, Component, inject, Input, output } from '@angular/core';
import { NgScrollbarModule } from 'ngx-scrollbar';
import { take } from 'rxjs/operators';

import { CoreModule } from '@core/core.module';
import { AutoAnimateDirective } from '@core/directives/auto-animate.directive';
import { CdkPortalService } from '@core/services/cdk-portal.service';
import { ConfigService } from '@core/services/config.service';
import { ButtonComponent } from '@design/buttons/button/button.component';
import { ButtonSize, ButtonType } from '@design/buttons/button/types';
import {
  ConfirmationDialogAppearance,
  ConfirmationDialogComponent,
  ConfirmationDialogData,
  ConfirmationDialogResult,
} from '@design/overlays/confirmation-dialog/confirmation-dialog.component';
import { TabSelectorComponent, TabSelectorItem } from '@design/navigation/tab-selector/tab-selector.component';
import { ToastType } from '@design/overlays/toast/toast';
import { TooltipAlignment } from '@design/overlays/tooltip/tooltip';
import { TooltipDirective } from '@design/overlays/tooltip/tooltip.directive';
import { getTaskAssignedAtLocale } from '@conversations/tasks/task-card/task-card.component';
import { TaskDrawerActivityComponent } from '@conversations/tasks/task-drawer/task-drawer-activity/task-drawer-activity.component';
import { TaskDrawerDetailsComponent } from '@conversations/tasks/task-drawer/task-drawer-details/task-drawer-details.component';
import { TaskDrawerResultsComponent } from '@conversations/tasks/task-drawer/task-drawer-results/task-drawer-results.component';
import { TaskStatusChipComponent } from '@conversations/tasks/task-status-chip/task-status-chip.component';
import { TasksService } from '@conversations/tasks/tasks.service';

import { Task, TaskRole, TaskStatus } from '../tasks.model';
import { DateFormatDistancePipe } from '@core/pipes/date-format.pipe';
import { TranslateModule, TranslateService } from '@ngx-translate/core';

type TaskDrawerTab = 'details' | 'activity' | 'results';

const tabs: TabSelectorItem[] = [
  { id: 'details', labelTranslationKey: 'conversations-v4.tasks.drawerTabs.details', disabled: false },
  { id: 'activity', labelTranslationKey: 'conversations-v4.tasks.drawerTabs.activity', disabled: false },
  { id: 'results', labelTranslationKey: 'conversations-v4.tasks.drawerTabs.results', disabled: false },
];

@Component({
  selector: 'cc-task-drawer',
  standalone: true,
  templateUrl: './task-drawer.component.html',
  styleUrls: ['./task-drawer.component.scss'],
  changeDetection: ChangeDetectionStrategy.OnPush,
  imports: [
    TaskStatusChipComponent,
    CoreModule,
    ButtonComponent,
    TooltipDirective,
    TabSelectorComponent,
    TaskDrawerDetailsComponent,
    TaskDrawerActivityComponent,
    TaskDrawerResultsComponent,
    AutoAnimateDirective,
    CdkScrollableModule,
    NgScrollbarModule,
    DialogModule,
    DateFormatDistancePipe,
    TranslateModule,
  ],
})
export class TaskDrawerComponent {
  @Input()
  task: Task;

  drawerClose = output<void>();

  protected activeTab: TaskDrawerTab = 'details';

  protected readonly getTaskAssignedAtLocale = getTaskAssignedAtLocale;
  protected readonly tabs = tabs;
  protected readonly ButtonSize = ButtonSize;
  protected readonly ButtonType = ButtonType;
  protected readonly TooltipAlignment = TooltipAlignment;

  private readonly tasksService = inject(TasksService);
  private readonly clipboard = inject(Clipboard);
  private readonly portalService = inject(CdkPortalService);
  private readonly dialog = inject(Dialog);
  private readonly translate = inject(TranslateService);

  get canCloseTask(): boolean {
    return (
      this.task.status !== TaskStatus.Closed &&
      this.task.status !== TaskStatus.Completed &&
      this.task.taskRole === TaskRole.Assigner
    );
  }

  get canSendReminder(): boolean {
    return (
      (this.task.status === TaskStatus.NotStarted || this.task.status === TaskStatus.InProgress) &&
      this.task.taskRole === TaskRole.Assigner
    );
  }

  selectTab(tabItem: TabSelectorItem): void {
    this.activeTab = tabItem.id as TaskDrawerTab;
  }

  closeTask(): void {
    const dialog = this.dialog.open<ConfirmationDialogResult, ConfirmationDialogData>(ConfirmationDialogComponent, {
      data: {
        title: this.translate.instant('conversations-v4.tasks.closePrompt.title'),
        message: this.translate.instant('conversations-v4.tasks.closePrompt.message'),
        confirmText: this.translate.instant('conversations-v4.tasks.closePrompt.closeTask'),
        cancelText: this.translate.instant('common.buttons.cancel'),
        destructive: true,
        style: ConfirmationDialogAppearance.Compact,
      },
    });

    dialog.closed.pipe(take(1)).subscribe((result: ConfirmationDialogResult | undefined) => {
      if (result !== 'confirm') return;
      this.tasksService.closeTask(this.task.id);
    });
  }

  sendReminder(): void {
    this.tasksService.sendReminder(this.task.id);
  }

  copyLink(): void {
    const link = `${ConfigService.settings.apiUrl}/tasks/task_id=${this.task.id}`;
    this.clipboard.copy(link);

    this.portalService.presentToast(this.translate.instant('common.strings.linkCopied'), ToastType.Info);
  }
}
