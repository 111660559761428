import { ChangeDetectionStrategy, Component, Input } from '@angular/core';
import { Task } from '@conversations/tasks/tasks.model';

@Component({
  selector: 'cc-task-drawer-activity',
  standalone: true,
  templateUrl: './task-drawer-activity.component.html',
  styleUrls: ['./task-drawer-activity.component.scss'],
  changeDetection: ChangeDetectionStrategy.OnPush,
})
export class TaskDrawerActivityComponent {
  @Input()
  task: Task;
}
