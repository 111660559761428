<app-button
  (click)="focused() || insertTable()"
  #dropdownTrigger="ccDropdownTrigger"
  [ccDropdownTrigger]="dropdown"
  [ccDropdownPositions]="{
    offsetY: 6,
    positions: ['bottom-start', 'top-start', 'bottom-end', 'top-end'],
  }"
  [ccDropdownDisabled]="!focused()"
  [active]="focused()"
  [size]="ButtonSize.Big"
  [type]="ButtonType.Ghost"
  [ccTooltip]="
    focused()
      ? ('conversations-v4.composer.tableEditor.modifyTable' | translate)
      : ('conversations-v4.composer.tableEditor.insertTable' | translate)
  "
>
  <i
    class="icon-table"
    slot="ghost-icon"
  ></i>
</app-button>

<ng-template #dropdown>
  <cc-dropdown [shadow]="false">
    <!-- Columns -->
    <cc-dropdown-text>
      <ng-container slot="title">
        {{ 'conversations-v4.composer.tableEditor.columns.title' | translate }}
      </ng-container>
    </cc-dropdown-text>

    <cc-dropdown-action
      (click)="handleTableAction('addColumnBefore'); dropdownTrigger.close()"
      leadingIcon="icon-column-insert-left"
    >
      <ng-container slot="title">
        {{ 'conversations-v4.composer.tableEditor.columns.addColumnBefore' | translate }}
      </ng-container>
    </cc-dropdown-action>

    <cc-dropdown-action
      (click)="handleTableAction('addColumnAfter'); dropdownTrigger.close()"
      leadingIcon="icon-column-insert-right"
    >
      <ng-container slot="title">
        {{ 'conversations-v4.composer.tableEditor.columns.addColumnAfter' | translate }}
      </ng-container>
    </cc-dropdown-action>

    @if (canDeleteColumn()) {
      <cc-dropdown-action
        (click)="handleTableAction('deleteColumn'); dropdownTrigger.close()"
        leadingIcon="icon-column-remove"
      >
        <ng-container slot="title">
          {{ 'conversations-v4.composer.tableEditor.columns.deleteColumn' | translate }}
        </ng-container>
      </cc-dropdown-action>
    }

    <!-- Rows -->
    <cc-dropdown-text>
      <ng-container slot="title">
        {{ 'conversations-v4.composer.tableEditor.rows.title' | translate }}
      </ng-container>
    </cc-dropdown-text>

    <cc-dropdown-action
      (click)="handleTableAction('addRowAbove'); dropdownTrigger.close()"
      leadingIcon="icon-row-insert-top"
    >
      <ng-container slot="title">
        {{ 'conversations-v4.composer.tableEditor.rows.addRowAbove' | translate }}
      </ng-container>
    </cc-dropdown-action>

    <cc-dropdown-action
      (click)="handleTableAction('addRowBelow'); dropdownTrigger.close()"
      leadingIcon="icon-row-insert-bottom"
    >
      <ng-container slot="title">
        {{ 'conversations-v4.composer.tableEditor.rows.addRowBelow' | translate }}
      </ng-container>
    </cc-dropdown-action>

    @if (canDeleteRow()) {
      <cc-dropdown-action
        (click)="handleTableAction('deleteRow'); dropdownTrigger.close()"
        leadingIcon="icon-row-remove"
      >
        <ng-container slot="title">
          {{ 'conversations-v4.composer.tableEditor.rows.deleteRow' | translate }}
        </ng-container>
      </cc-dropdown-action>
    }

    <!-- Cells -->
    <cc-dropdown-text>
      <ng-container slot="title">
        {{ 'conversations-v4.composer.tableEditor.cells.title' | translate }}
      </ng-container>
    </cc-dropdown-text>

    <cc-dropdown-action
      (click)="handleTableAction('toggleHeaderCell'); dropdownTrigger.close()"
      [leadingIcon]="isHeaderCell() ? 'icon-table-down' : 'icon-table-up'"
    >
      <ng-container slot="title">
        @if (isHeaderCell()) {
          {{ 'conversations-v4.composer.tableEditor.cells.disableHeaderCell' | translate }}
        } @else {
          {{ 'conversations-v4.composer.tableEditor.cells.enableHeaderCell' | translate }}
        }
      </ng-container>
    </cc-dropdown-action>

    <cc-dropdown-action
      (click)="handleTableAction('toggleBorderless'); dropdownTrigger.close()"
      [leadingIcon]="isBorderless() ? 'icon-border-all' : 'icon-border-none'"
    >
      <ng-container slot="title">
        @if (isBorderless()) {
          {{ 'conversations-v4.composer.tableEditor.cells.enableBorder' | translate }}
        } @else {
          {{ 'conversations-v4.composer.tableEditor.cells.disableBorder' | translate }}
        }
      </ng-container>
    </cc-dropdown-action>

    <cc-dropdown-action
      (click)="handleTableAction('togglePaddingless'); dropdownTrigger.close()"
      [leadingIcon]="isPaddingless() ? 'icon-padding-all' : 'icon-padding-none'"
    >
      <ng-container slot="title">
        @if (isPaddingless()) {
          {{ 'conversations-v4.composer.tableEditor.cells.enablePadding' | translate }}
        } @else {
          {{ 'conversations-v4.composer.tableEditor.cells.disablePadding' | translate }}
        }
      </ng-container>
    </cc-dropdown-action>

    @if (canMergeCells()) {
      <cc-dropdown-action
        leadingIcon="icon-merge-alt"
        (click)="handleTableAction('mergeCells'); dropdownTrigger.close()"
      >
        <ng-container slot="title">
          {{ 'conversations-v4.composer.tableEditor.cells.mergeCells' | translate }}
        </ng-container>
      </cc-dropdown-action>
    }

    @if (canSplitCell()) {
      <cc-dropdown-action
        leadingIcon="icon-split"
        (click)="handleTableAction('splitCell'); dropdownTrigger.close()"
      >
        <ng-container slot="title">
          {{ 'conversations-v4.composer.tableEditor.cells.splitCell' | translate }}
        </ng-container>
      </cc-dropdown-action>
    }

    <cc-dropdown-divider />

    <cc-dropdown-action
      (click)="handleTableAction('deleteTable'); dropdownTrigger.close()"
      leadingIcon="icon-table-minus"
    >
      <ng-container slot="title">
        {{ 'conversations-v4.composer.tableEditor.deleteTable' | translate }}
      </ng-container>
    </cc-dropdown-action>
  </cc-dropdown>
</ng-template>
