<div
  [class.message--compact]="appearance === 'compact'"
  [class.message--note]="type === 'note'"
  [class.message--pending]="mode === 'pending'"
  class="message"
>
  <div class="message__side-container">
    @if (appearance === 'default') {
      <cc-user-avatar
        [src]="sender.avatarUrl"
        [name]="sender.name"
        [id]="sender.id"
        [size]="32"
      />
    }

    @if (appearance === 'compact') {
      <div class="message__side-container-timestamp">
        {{ message.createdAt | ccDateFormat: { time: 'default' } }}
      </div>
    }
  </div>

  <div class="message__content-container">
    @if (appearance === 'default') {
      <div class="message__content-container-metadata">
        <div
          [attr.tabindex]="canViewContactInfo ? 0 : -1"
          (keyup.enter)="canViewContactInfo && (senderInfoDropdownVisible = !senderInfoDropdownVisible)"
          (click)="canViewContactInfo && (senderInfoDropdownVisible = !senderInfoDropdownVisible)"
          #senderInfoButton
          [ccTooltip]="'conversations-v4.conversation.messages.message.senderInfo.tooltip' | translate"
          [tooltipAlignment]="TooltipAlignment.Start"
          [tooltipDisabled]="!canViewContactInfo"
          class="message__content-container-metadata-sender-name"
          [class.message__content-container-metadata-sender-name--activatable]="canViewContactInfo"
        >
          {{ sender.name }}
        </div>

        <ng-template
          (detach)="senderInfoDropdownVisible = false"
          (overlayOutsideClick)="
            senderInfoDropdownVisible = getOverlayVisibilityAfterOutsideClick($event, senderInfoButton)
          "
          [cdkConnectedOverlayOpen]="senderInfoDropdownVisible"
          [cdkConnectedOverlayOrigin]="senderInfoButton"
          [cdkConnectedOverlayPositions]="senderInfoDropdownPositionStrategy"
          cdkConnectedOverlay
        >
          <cc-message-sender-info
            [sender]="sender"
            (dismiss)="senderInfoDropdownVisible = false"
          />
        </ng-template>

        @if (hasEmailRecipients) {
          <div class="message__content-container-metadata-email-recipients">
            <div class="message__content-container-metadata-email-recipients-text">
              {{ emailRecipientsText }}
            </div>
            <i class="icon-chevron-down-small message__content-container-metadata-email-recipients-toggle"></i>
          </div>
        }

        @if (mode !== 'pending') {
          <div class="message__content-container-metadata-timestamp">
            {{ message.createdAt | ccDateFormat: { time: 'default' } }}
          </div>
        }
      </div>

      @if (message.replyToMessage; as reference) {
        <cc-message-reply-to-reference
          (click)="jumpToMessage.emit(reference.createdAt)"
          [reference]="reference"
        />
      }
    }

    <div class="message__content-container-body">
      @if (type !== 'system') {
        <cc-message-body [content]="message.content" />
      }

      @if (type === 'system' && systemMetadata) {
        <cc-system-message-body [systemMetadata]="systemMetadata" />
      }
    </div>

    @if (message.tasksIds?.length) {
      <cc-message-task-references [tasksIds]="message.tasksIds" />
    }

    @if (message.attachments?.length) {
      <cc-message-attachments
        [attachments]="message.attachments"
        [conversationId]="message.conversationId || undefined"
        [messageOrDraftId]="message.id"
        [mode]="mode"
      />
    }

    @if (mode === 'draft') {
      <div class="message__content-container-draft-indicator">
        <div class="message__content-container-draft-indicator-title">
          {{ 'conversations-v4.conversation.messages.message.draft' | translate }}
        </div>

        <div class="message__content-container-draft-indicator-timestamp">
          @if (updatedJustNow) {
            {{ 'conversations-v4.conversation.messages.message.lastEditedNow' | translate }}
          } @else {
            {{
              'conversations-v4.conversation.messages.message.lastEditedAt'
                | translate
                  : {
                      date: message.updatedAt | ccDateFormatDistance: { strict: true },
                    }
            }}
          }
        </div>
      </div>
    }
  </div>

  @if (!actionsDisabled && mode !== 'pending') {
    <div class="message__actions">
      @if (mode !== 'draft') {
        @if (type === 'email' && !replyForbidden) {
          <app-button
            (click)="reply.emit()"
            [size]="ButtonSize.Big"
            [type]="ButtonType.Ghost"
            [ccTooltip]="'conversations-v4.conversation.messages.message.reply' | translate"
          >
            <i
              class="icon-reply"
              slot="ghost-icon"
            ></i>
          </app-button>
        }

        @if (type !== 'system') {
          <div #moreActionsButton>
            <app-button
              (click)="moreActionsDropdownVisible = !moreActionsDropdownVisible"
              [size]="ButtonSize.Big"
              [tooltipAlignment]="TooltipAlignment.End"
              [type]="ButtonType.Ghost"
              [ccTooltip]="'common.buttons.moreActions' | translate"
            >
              <i
                class="icon-more"
                slot="ghost-icon"
              ></i>
            </app-button>
          </div>

          <ng-template
            (detach)="moreActionsDropdownVisible = false"
            (overlayOutsideClick)="
              moreActionsDropdownVisible = getOverlayVisibilityAfterOutsideClick($event, moreActionsButton)
            "
            [cdkConnectedOverlayOpen]="moreActionsDropdownVisible"
            [cdkConnectedOverlayOrigin]="moreActionsButton"
            [cdkConnectedOverlayPositions]="moreActionsDropdownPositionStrategy"
            cdkConnectedOverlay
          >
            <cc-dropdown width="120px">
              <cc-dropdown-action>
                <ng-container slot="title">
                  {{ 'common.buttons.edit' | translate }}
                </ng-container>
              </cc-dropdown-action>

              <cc-dropdown-action>
                <ng-container slot="title">
                  {{ 'common.buttons.delete' | translate }}
                </ng-container>
              </cc-dropdown-action>
            </cc-dropdown>
          </ng-template>
        }
      }

      @if (mode === 'draft') {
        <app-button
          (click)="draftEdit.emit()"
          [size]="ButtonSize.Big"
          [type]="ButtonType.Ghost"
          [ccTooltip]="'conversations-v4.conversation.messages.message.editDraft' | translate"
        >
          <i
            class="icon-edit"
            slot="ghost-icon"
          ></i>
        </app-button>

        <app-button
          (click)="draftDelete.emit()"
          [size]="ButtonSize.Big"
          [tooltipAlignment]="TooltipAlignment.End"
          [type]="ButtonType.Ghost"
          [ccTooltip]="'conversations-v4.conversation.messages.message.deleteDraft' | translate"
        >
          <i
            class="icon-delete"
            slot="ghost-icon"
          ></i>
        </app-button>
      }
    </div>
  }
</div>
