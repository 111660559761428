<div class="move-page-modal">
  <div class="move-page-modal__header">
    <div class="move-page-modal__title">Choose a page to move the selected page under</div>
  </div>

  <div class="move-page-modal__content">
    <nz-tree-select
      [formControl]="treeControl"
      [nzPlaceHolder]="'Choose a page.'"
      [nzNodes]="tree$ | async"
    >
    </nz-tree-select>
  </div>

  <div class="move-page-modal__actions">
    <app-button
      (click)="confirm.emit(treeControl.value)"
      [size]="ButtonSize.Small"
      [type]="ButtonType.Primary"
    >
      Confirm
    </app-button>

    <app-button
      (click)="cancel.emit()"
      [size]="ButtonSize.Small"
      [type]="ButtonType.Secondary"
    >
      Cancel
    </app-button>
  </div>
</div>
