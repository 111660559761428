import * as i0 from '@angular/core';
import { inject, Injector, signal, runInInjectionContext, booleanAttribute, Directive, Input, Output } from '@angular/core';
import { toObservable } from '@angular/core/rxjs-interop';
import { NgControl, NgModel } from '@angular/forms';
import { createInjectionToken } from 'ngxtension/create-injection-token';
import { rxEffect } from 'ngxtension/rx-effect';
import { skip } from 'rxjs';
const noop = () => undefined;
const [injectCvaCompareTo, provideCvaCompareTo] = createInjectionToken(() => Object.is);
const [injectCvaDefaultValue, provideCvaDefaultValue] = createInjectionToken(() => null);
/**
 * Provides a {@link NgxControlValueAccessorCompareTo comparator} based on a property of `T`.
 *
 * @example
 * ```ts
 * interface User {
 * 	id: string;
 * 	name: string;
 * }
 *
 * provideCvaCompareToByProp<User>('id');
 * ```
 */
const provideCvaCompareToByProp = prop => provideCvaCompareTo((a, b) => Object.is(a?.[prop], b?.[prop]), true);
/**
 * `NgxControlValueAccessor` is a directive to reduce boilerplate when building components, which implement the [ControlValueAccessor](https://angular.io/api/forms/ControlValueAccessor) interface.
 *
 * ## Usage
 *
 * `NgxControlValueAccessor` implements the [ControlValueAccessor](https://angular.io/api/forms/ControlValueAccessor) interface and exposes a _simpler_ api. Declare `NgxControlValueAccessor` in the `hostDirectives` section of your component and inject the instance in order to wire up your template:
 *
 * - `NgxControlValueAccessor.value` for syncing the value
 * - `NgxControlValueAccessor.disabled` for syncing the disabled state
 * - `NgxControlValueAccessor.markAsTouched` for marking the view as _touched_
 *
 * The value and disabled state are also available as signals:
 *
 * - `NgxControlValueAccessor.value$`
 * - `NgxControlValueAccessor.disabled$`
 *
 * ### Example
 *
 * In this example `NgxControlValueAccessor` is used to create a `CustomInput` component.
 *
 * ```ts
 * @Component({
 *   selector: 'custom-input',
 *   hostDirectives: [NgxControlValueAccessor],
 *   template: `
 *     <label>
 *       <b>Custom label</b>
 *       <input
 *         type="text"
 *         (input)="cva.value = $event.target.value"
 *         [value]="cva.value$()"
 *         [disabled]="cva.disabled$()"
 *         (blur)="cva.markAsTouched()"
 *       />
 *     </label>
 *   `,
 *   standalone: true,
 * })
 * export class CustomInput {
 *   protected cva = inject<NgxControlValueAccessor<string>>(
 *     NgxControlValueAccessor,
 *   );
 * }
 * ```
 *
 * With usage:
 *
 * ```html
 * <custom-input [formControl]="control" />
 * <custom-input [(ngModel)]="value" />
 * ```
 *
 * ## Non Primitive Values
 *
 * When your model is a non primitive datatype, you should provide a _comparator_. It is a pure function which tells `NgxControlValueAccessor`, whether two values are _semantically_ equal:
 *
 * ```ts
 * (a, b) => boolean;
 * ```
 *
 * ### Example
 *
 * In this example `NgxControlValueAccessor` is used to create a `User` select. A `User` is identified by its `id`.
 *
 * ```ts
 * interface User {
 *   id: string;
 *   name: string;
 * }
 *
 * const userComparator: NgxControlValueAccessorCompareTo<User> = (a, b) =>
 *   a?.id === b?.id;
 *
 * provideCvaCompareTo(userComparator, true);
 *
 * // or
 *
 * provideCvaCompareToByProp<User>('id');
 * ```
 *
 * Full example:
 *
 * ```ts
 * @Component({
 *   selector: 'user-select',
 *   standalone: true,
 *   hostDirectives: [NgxControlValueAccessor],
 *   providers: [provideCvaCompareToByProp<User>('id')],
 *   template: `
 *     <label>
 *       <b>Select a user:</b>
 *       <select
 *         [disabled]="cva.disabled$()"
 *         (blur)="cva.markAsTouched()"
 *         (change)="onChange($event)"
 *       >
 *         <option [selected]="cva.value === null">-- no user selected --</option>
 *         @for (user of users; track user.id) {
 *           <option [value]="user.id" [selected]="user.id === cva.value?.id">
 *             {{ user.name }}
 *           </option>
 *         }
 *       </select>
 *     </label>
 *   `,
 * })
 * export class UserSelect {
 *   protected cva = inject<NgxControlValueAccessor<User | null>>(
 *     NgxControlValueAccessor,
 *   );
 *
 *   protected onChange = (event: Event) =>
 *     (this.cva.value =
 *       this.users.find(({ id }) => event.target.value === id) ?? null);
 *
 *   @Input()
 *   users: User[] = [];
 * }
 * ```
 *
 * With usage:
 *
 * ```html
 * <user-select [formControl]="userControl" [options]="users" />
 * <user-select [(ngModel)]="user" [options]="users" />
 * ```
 *
 * ## Without `NgControl`
 *
 * Optionally you can expose `inputs` and `outputs` in the `hostDirectives` declaration
 * and use it without a `NgControl` directive.
 *
 * ```ts
 * hostDirectives: [
 *   {
 *     directive: NgxControlValueAccessor,
 *     inputs: ['value'],
 *     outputs: ['valueChange'],
 *   },
 * ];
 * ```
 *
 * ```html
 * <custom-input [(value)]="value" />
 * ```
 */
class NgxControlValueAccessor {
  /** @ignore */
  constructor() {
    /** @ignore */
    this.injector = inject(Injector);
    /** @ignore */
    this.ngControl = inject(NgControl, {
      self: true,
      optional: true
    });
    /** @ignore */
    this.initialValue = () => {
      if (this.ngControl != null) return this.ngControl.value;
      return injectCvaDefaultValue();
    };
    /** The value of this. If a control is present, it reflects it's value. */
    this.value$ = signal(this.initialValue(), {
      equal: (a, b) => this.compareTo(a, b)
    });
    /** Whether this is disabled. If a control is present, it reflects it's disabled state. */
    this.disabled$ = signal(this.ngControl?.disabled ?? false);
    /**
     * A comparator, which determines value changes. Should return true, if two values are considered semanticly equal.
     *
     * Defaults to {@link Object.is} in order to align with change detection behavior for inputs.
     */
    this.compareTo$ = signal(injectCvaCompareTo());
    /**
     * Emits whenever this {@link NgxControlValueAccessor.value$ value} changes.
     */
    this.valueChange = toObservable(this.value$).pipe(skip(1)); // -> hot observable
    /**
     * This function should be called when this host is considered `touched`.
     *
     * NOTE: Whenever a `blur` event is triggered on this host, this function is called.
     *
     * @see {@link NgxControlValueAccessor.registerOnTouched}
     * @see {@link NgxControlValueAccessor.ngControl}
     */
    this.markAsTouched = () => this.onTouched();
    /** This function is set by the forms api, if a control is present. */
    this.onChange = noop;
    /** This function is set by the forms api, if a control is present. */
    this.onTouched = noop;
    // control value accessor implementation
    this.writeValue = value => {
      if (this.registered) this.value = value;
    };
    this.registerOnChange = onChange => this.onChange = onChange;
    this.registerOnTouched = onTouched => this.onTouched = onTouched;
    this.setDisabledState = disabled => {
      if (this.registered) this.disabled$.set(disabled);
    };
    if (this.ngControl != null) this.ngControl.valueAccessor = this;
  }
  /** @ignore */
  ngOnInit() {
    if (this.ngControl != null) {
      runInInjectionContext(this.injector, () => {
        // NOTE: Don't use 'effect' because we have no idea if we are setting other signals here.
        // sync value
        rxEffect(toObservable(this.value$), value => {
          if (!this.compareTo(this.ngControl?.value, value)) this.onChange(value);
        });
        // sync disabled state
        rxEffect(toObservable(this.disabled$), disabled => {
          if (this.ngControl != null && this.ngControl.control != null && this.ngControl.disabled !== disabled) this.ngControl.control[disabled ? 'disable' : 'enable']();
        });
      });
    }
  }
  /** The value of this. If a control is present, it reflects it's value. */
  set value(value) {
    this.value$.set(value);
  }
  get value() {
    return this.value$();
  }
  /** Whether this is disabled. If a control is present, it reflects it's disabled state. */
  set disabled(disabled) {
    this.disabled$.set(disabled);
  }
  get disabled() {
    return this.disabled$();
  }
  /**
   * A comparator, which determines value changes. Should return true, if two values are considered semanticly equal.
   *
   * Defaults to {@link Object.is} in order to align with change detection behavior for inputs.
   */
  set compareTo(compareTo) {
    if (typeof compareTo === 'function') this.compareTo$.set(compareTo);
  }
  get compareTo() {
    return this.compareTo$();
  }
  /**
   * `NgModel` sets up the control in `ngOnChanges`. Idk if bug or on purpose, but `writeValue` and `setDisabledState` are called before the inputs are set.
   * {@link https://github.com/angular/angular/blob/main/packages/forms/src/directives/ng_model.ts#L223}
   *
   * @ignore
   */
  get registered() {
    return this.ngControl instanceof NgModel ? this.ngControl._registered : true;
  }
  static {
    this.ɵfac = function NgxControlValueAccessor_Factory(t) {
      return new (t || NgxControlValueAccessor)();
    };
  }
  static {
    this.ɵdir = /* @__PURE__ */i0.ɵɵdefineDirective({
      type: NgxControlValueAccessor,
      inputs: {
        value: "value",
        disabled: [i0.ɵɵInputFlags.HasDecoratorInputTransform, "disabled", "disabled", booleanAttribute],
        compareTo: "compareTo"
      },
      outputs: {
        valueChange: "valueChange"
      },
      standalone: true,
      features: [i0.ɵɵInputTransformsFeature]
    });
  }
}
(() => {
  (typeof ngDevMode === "undefined" || ngDevMode) && i0.ɵsetClassMetadata(NgxControlValueAccessor, [{
    type: Directive,
    args: [{
      standalone: true
    }]
  }], () => [], {
    value: [{
      type: Input
    }],
    disabled: [{
      type: Input,
      args: [{
        transform: booleanAttribute
      }]
    }],
    compareTo: [{
      type: Input
    }],
    valueChange: [{
      type: Output
    }]
  });
})();

/**
 * Generated bundle index. Do not edit.
 */

export { NgxControlValueAccessor, injectCvaCompareTo, injectCvaDefaultValue, provideCvaCompareTo, provideCvaCompareToByProp, provideCvaDefaultValue };
