import { sanitize } from '@clover/core/helpers/sanitize-email/sanitizer';

export default function isHTMLEmpty(content: string): boolean {
  const plainText = sanitize(content, null, {
    noWrapper: true,
    dropAllHtmlTags: true,
  });
  const hasImages = /<img[^>]+>/g.test(content);

  return !plainText.trim() && !hasImages;
}
