import { inject, Injectable } from '@angular/core';
import {
  AddSubPage,
  CreatePage,
  DeleteDocument,
  EnterEditMode,
  LeaveEditMode,
  LoadDocumentContent,
  LoadDocuments,
  LoadMockDocuments,
  MovePage,
  SaveDocument,
  SelectDocument,
  SelectDocumentByPath,
  UpdateDocument,
} from '@network/company/state/wiki/wiki.actions';
import {
  DEFAULT_WIKI_STATE,
  ICompanyWikiDocument,
  ISubPageResponse,
  MockDocumentsType,
  WikiEditorMode,
  WikiStateModel,
} from '@network/company/state/wiki/wiki.entities';
import { Action, State, StateContext } from '@ngxs/store';

import { CdkPortalService } from '@core/services/cdk-portal.service';
import { ToastType } from '@design/overlays/toast/toast';
import { DEFAULT_DOCUMENT } from '@network/company/state/wiki/wiki.mock';
import { WikiService } from '@network/company/state/wiki/wiki.service';
import * as R from 'ramda';
import { of, tap } from 'rxjs';
import { catchError } from 'rxjs/operators';
import { RETAILER_COMPANY_DOCUMENTS } from '../../../../../assets/markdown/company-wiki/demo-assets/Living Spaces Supplier Portal/content';

@State<WikiStateModel>({
  name: 'companyWiki',
  defaults: DEFAULT_WIKI_STATE,
})
@Injectable()
export class WikiState {
  private readonly _wikiService = inject(WikiService);
  private readonly portalService = inject(CdkPortalService);

  @Action(LoadDocuments)
  loadDocuments(ctx: StateContext<WikiStateModel>, { companyId }: LoadDocuments) {
    ctx.patchState({
      documents: [],
      activeDocument: null,
      editorMode: WikiEditorMode.Preview,
      companyId: null,
    });

    return this._wikiService.getWiki(companyId).pipe(
      tap((response) => {
        if (response && response.length > 0) {
          ctx.patchState({
            documents: response,
            activeDocument: response[0],
            editorMode: WikiEditorMode.Preview,
            companyId,
          });

          ctx.dispatch(new LoadDocumentContent(response[0].id, companyId));
        } else {
          ctx.patchState({
            documents: [DEFAULT_DOCUMENT],
            activeDocument: DEFAULT_DOCUMENT,
            editorMode: WikiEditorMode.Preview,
            companyId,
          });
        }
      }),
    );
  }

  @Action(LoadMockDocuments)
  loadMockDocuments(ctx: StateContext<WikiStateModel>, { mockType }: LoadMockDocuments) {
    const documents = mockType === MockDocumentsType.RetailerInc ? RETAILER_COMPANY_DOCUMENTS : [DEFAULT_DOCUMENT];

    ctx.patchState({
      documents,
      activeDocument: documents[0],
      editorMode: WikiEditorMode.Preview,
      companyId: null,
    });
  }

  @Action(LoadDocumentContent)
  loadDocumentContent(ctx: StateContext<WikiStateModel>, { documentId, companyId }: LoadDocumentContent) {
    return this._wikiService.getPageContent(companyId, documentId).pipe(
      tap((response: string) => {
        const state = ctx.getState();
        const activeDocument = this.findDocumentById(state.documents, documentId);

        if (!activeDocument) return;
        ctx.patchState({
          activeDocument: {
            ...activeDocument,
            markdownContent: response,
          },
        });
      }),
    );
  }

  @Action(SelectDocument)
  selectDocument(ctx: StateContext<WikiStateModel>, action: SelectDocument) {
    const state = ctx.getState();
    const activeDocument = this.findDocumentById(state.documents, action.documentId);

    if (!activeDocument) return;
    ctx.patchState({
      activeDocument,
    });

    ctx.dispatch(new LoadDocumentContent(activeDocument.id, state.companyId));

    if (action.withEdit) {
      ctx.dispatch(new EnterEditMode());
    }
  }

  @Action(SelectDocumentByPath)
  selectDocumentByPath(ctx: StateContext<WikiStateModel>, action: SelectDocumentByPath): void {
    const state = ctx.getState();
    const document = this.findDocumentByPath(state.documents, action.path);
    const activeDocument = this.findDocumentById(state.documents, document.id);

    if (!activeDocument) return;
    ctx.patchState({
      activeDocument,
    });
  }

  @Action(EnterEditMode)
  enterEditMode(ctx: StateContext<WikiStateModel>): void {
    ctx.patchState({
      editorMode: WikiEditorMode.Edit,
    });
  }

  @Action(LeaveEditMode)
  leaveEditMode(ctx: StateContext<WikiStateModel>): void {
    const state = ctx.getState();

    const editedDocument = state.activeDocument;
    const unmodifiedDocument = this.findDocumentById(state.documents, editedDocument.id);

    ctx.patchState({
      activeDocument: unmodifiedDocument,
      editorMode: WikiEditorMode.Preview,
    });
  }

  @Action(SaveDocument)
  saveDocument(ctx: StateContext<WikiStateModel>) {
    const state = ctx.getState();

    const editedDocument = state.activeDocument;
    const patchedDocuments = R.clone(this.patchDocument(state.documents, editedDocument));
    const updatedDocument = this.findDocumentById(patchedDocuments, editedDocument.id);

    return this._wikiService.updatePageContent(state.companyId, editedDocument.id.toString(), editedDocument).pipe(
      tap(() => {
        ctx.patchState({
          activeDocument: updatedDocument,
          documents: patchedDocuments,
          editorMode: WikiEditorMode.Preview,
        });
      }),
    );
  }

  @Action(AddSubPage)
  addSubPage(ctx: StateContext<WikiStateModel>) {
    const state = ctx.getState();
    const parentPageId = state.activeDocument.id;

    return this._wikiService.addPage(state.companyId, parentPageId).pipe(
      tap((response: ISubPageResponse) => {
        const documents = R.clone(state.documents);
        const parentDocument = this.findDocumentById(documents, parentPageId);

        parentDocument.children = parentDocument.children || [];
        parentDocument.children.push(response);

        ctx.patchState({
          documents,
        });

        // Enter preview mode after create the subpage
        ctx.dispatch(new SelectDocument(response.id, true));
      }),
    );
  }

  @Action(UpdateDocument)
  updateDocument(ctx: StateContext<WikiStateModel>, action: UpdateDocument): void {
    const state = ctx.getState();
    const editedDocument = state.activeDocument;

    ctx.patchState({
      activeDocument: {
        ...editedDocument,
        ...action.document,
      },
    });
  }

  @Action(MovePage)
  movePage(ctx: StateContext<WikiStateModel>, action: MovePage) {
    const state = ctx.getState();
    const activeDocument = state.activeDocument;

    if (!activeDocument) return;

    return this._wikiService.movePage(state.companyId, activeDocument.id, action.parentPageId).pipe(
      tap((response) => {
        ctx.dispatch(new LoadDocuments(state.companyId));
        ctx.dispatch(new SelectDocument(response.id));
      }),
    );
  }

  @Action(DeleteDocument)
  deleteDocument(ctx: StateContext<WikiStateModel>) {
    const state = ctx.getState();

    const activeDocument = state.activeDocument;

    if (!activeDocument) return;

    return this._wikiService.deletePage(state.companyId, activeDocument.id).pipe(
      tap(() => {
        const documents = this._deleteDocument(state.documents, activeDocument.id);
        ctx.patchState({
          ...document,
          activeDocument: documents[0],
        });

        this.portalService.presentToast('Page has been deleted.', ToastType.Success);
        ctx.dispatch(new SelectDocument(documents[0].id));
      }),
      catchError((err) => {
        const isRoot = this._isRoot(activeDocument.id, state.documents);
        const errorMessage = isRoot ? 'You can’t delete the root page.' : 'You can’t delete a page with subpages.';
        this.portalService.presentToast(errorMessage, ToastType.Error);
        return of(err);
      }),
    );
  }

  @Action(CreatePage)
  createPage(ctx: StateContext<WikiStateModel>) {
    const state = ctx.getState();
    const haveRoot = state.documents.length > 0;
    const parentPageId = haveRoot ? state.documents[0].id : null;

    return this._wikiService.addPage(state.companyId, parentPageId).pipe(
      tap((response: ISubPageResponse) => {
        const documents = R.clone(state.documents);
        // update from root
        documents[0].children.unshift(response);

        ctx.patchState({
          documents,
        });

        ctx.dispatch(new SelectDocument(response.id, true));
      }),
    );
  }

  private findDocumentById(documents: ICompanyWikiDocument[], documentId: number): ICompanyWikiDocument | null {
    for (const document of documents) {
      if (document.id === documentId) {
        return {
          ...document,
          path: [{ id: document.id, title: document.name }],
        };
      }

      if (document.children && document.children.length > 0) {
        const foundDocument = this.findDocumentById(document.children, documentId);
        if (foundDocument) {
          return {
            ...foundDocument,
            path: [{ id: document.id, title: document.name }, ...foundDocument.path],
          };
        }
      }
    }

    return null;
  }

  private findDocumentByPath(documents: ICompanyWikiDocument[], path: string[]): ICompanyWikiDocument | null {
    const [title, ...rest] = path;
    const document = documents.find((doc) => doc.name === title);

    if (!document) return null;
    if (rest.length === 0) return document;

    return this.findDocumentByPath(document.children, rest);
  }

  private patchDocument(
    documents: ICompanyWikiDocument[],
    editedDocument: ICompanyWikiDocument,
  ): ICompanyWikiDocument[] {
    return documents.map((document) => {
      if (document.id === editedDocument.id) {
        return editedDocument;
      }

      if (document.children && document.children.length > 0) {
        return {
          ...document,
          children: this.patchDocument(document.children, editedDocument),
        };
      }

      return document;
    });
  }

  private _deleteDocument(documents: ICompanyWikiDocument[], documentId: number): ICompanyWikiDocument[] {
    return documents.filter((document) => {
      if (document.id === documentId) return false;
      if (document.children && document.children.length > 0) {
        document.children = this._deleteDocument(document.children, documentId);
      }

      return true;
    });
  }

  private _isRoot(activeId: number, documents: ICompanyWikiDocument[]): boolean {
    return documents[0].id === activeId;
  }
}
