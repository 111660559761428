import { inject, Injectable } from '@angular/core';
import { Router, UrlSerializer } from '@angular/router';
import { type ContactType } from '@clover/conversations-v4/conversation/state/contacts/contacts.model';
import { RouterSelectors } from '@clover/custom-router-state-serializer';
import { ConversationsCategory } from '@conversations/conversations/categories';
import {
  parseExternalAccountParam,
  parseLabelParam,
  parseStatusParam,
} from '@conversations/conversations/conversations.resolver';
import {
  Conversation,
  ConversationPerformer,
  ConversationSource,
  ConversationStatus,
} from '@conversations/conversations/state/conversations/conversations-state.model';
import { getWorkspaceIdByAlias } from '@conversations/workspaces/state/workspaces/workspaces-state.helpers';
import { WorkspacesSelectors } from '@conversations/workspaces/state/workspaces/workspaces.selectors';
import { getPagingOptionsParams, PagingOptions, PagingWrapper } from '@core/helpers/paging';
import { HttpService } from '@core/services/http.service';
import { Store } from '@ngxs/store';
import { Observable, of } from 'rxjs';
import { map } from 'rxjs/operators';

export interface ConversationResponse {
  id: string;
  workspaceId: number;
  externalAccountId: number;
  source: ConversationSource;
  name: string;
  lastMessage: LastMessageResponse | undefined; // Sometimes backend returns undefined
  lastMessageAt: string;
  createdAt: string;
  updatedAt: string;
  unreadMessages: number;
  tagIds: number[];
  isPrioritized: boolean;
  status: ConversationStatus;
  isSnoozed: boolean;
  snoozedUntil: string;
  hasAttachments: boolean;
  sender: UserResponse;
}

interface LastMessageResponse {
  snippet: string;
  sender: UserResponse;
  createdAt: string;
  updatedAt: string;
  hasAttachments: boolean;
  isDraft: boolean;
  messageDraftId: number;
}

export interface UserResponse {
  id: number;
  name: string;
  title: string;
  logoUrl: string;
  companyName: string;
  companyLogoUrl: string;
  contactType: ContactType;
}

export interface ConversationCategoryFilterParams {
  labelIds: number[] | undefined;
  externalAccountId: number | undefined;
  status: ConversationStatus | undefined;
}

export type ConversationsSortingProperty =
  | 'created_at'
  | 'member_count'
  | 'unread_count'
  | 'has_unread'
  | 'last_updated_at';

export function mapConversation(c: ConversationResponse): Conversation | undefined {
  try {
    return {
      id: c.id,
      workspaceId: c.workspaceId,
      externalAccountId: c.externalAccountId,
      subject: c.name || undefined,
      initialSender: mapConversationPerformer(c.sender),
      latestMessage: {
        sender: mapConversationPerformer(c.lastMessage.sender),
        messageSnippet: c.lastMessage.snippet,
        draft: c.lastMessage.isDraft,
        createdAt: c.lastMessage.createdAt,
        updatedAt: c.lastMessage.updatedAt,
      },

      unreadMessages: c.unreadMessages,
      status: c.status,
      labelIds: c.tagIds,
      hasAttachments: c.hasAttachments,
      prioritized: c.isPrioritized,
      snoozedUntil: c.isSnoozed ? c.snoozedUntil : undefined,
      updatedAt: c.updatedAt,
    };
  } catch (error) {
    console.error('Error while mapping conversation', {
      conversation: c,
      error,
    });
    return undefined;
  }
}

export function mapConversationPerformer(user: UserResponse | undefined): ConversationPerformer | undefined {
  if (!user) return undefined;

  return {
    id: user.id.toString(),
    name: user.name,
    avatarUrl: user.logoUrl,
    type: user.contactType,
  };
}

function getCategoryEndpoint(categoryId: ConversationsCategory): string[] {
  const endpointsMap: Record<ConversationsCategory, string[]> = {
    [ConversationsCategory.Inbox]: ['inbox', 'search'],
    [ConversationsCategory.AssignedToMe]: ['assignedToMe', 'search'],
    [ConversationsCategory.Mentions]: ['mentions', 'search'], // Not implemented yet
    [ConversationsCategory.Tasks]: ['tasks', 'search'], // Not implemented yet
    [ConversationsCategory.Sent]: ['sent', 'search'],
    [ConversationsCategory.Drafts]: ['drafts'],
    [ConversationsCategory.Prioritized]: ['prioritized', 'search'],
    [ConversationsCategory.Snoozed]: ['snoozed', 'search'],
    [ConversationsCategory.Important]: ['important', 'search'], // Not implemented yet
    [ConversationsCategory.AllMail]: ['all-mail', 'search'],
    [ConversationsCategory.Spam]: ['spam', 'search'],
    [ConversationsCategory.Trash]: ['trash', 'search'],
  };

  return endpointsMap[categoryId];
}

@Injectable({
  providedIn: 'root',
})
export class ConversationsService {
  private readonly http = inject(HttpService);
  private readonly router = inject(Router);
  private readonly serializer = inject(UrlSerializer);
  private readonly store = inject(Store);

  getConversations(
    workspaceId: number,
    categoryId: ConversationsCategory,
    filterParams: ConversationCategoryFilterParams,
    pagingOptions?: PagingOptions<ConversationsSortingProperty>,
  ): Observable<PagingWrapper<Conversation>> {
    const categoryEndpointSegments = getCategoryEndpoint(categoryId);

    const urlTree = this.router.createUrlTree(['api', 'stream-conversations', ...categoryEndpointSegments], {
      queryParams: {
        workspaceId,
        tagIds: filterParams.labelIds,
        externalAccountId: filterParams.externalAccountId,
        status: filterParams.status,
        ...getPagingOptionsParams(pagingOptions),
      },
    });

    const path = this.serializer.serialize(urlTree);
    return this.http.getV2<PagingWrapper<ConversationResponse>>(path).pipe(
      map((response) => ({
        ...response,
        data: response.data.map(mapConversation).filter((c) => c !== undefined),
      })),
    );
  }

  getConversation(conversationId: string): Observable<Conversation> {
    return this.http
      .getV2<ConversationResponse>(`api/stream-conversations/${conversationId}`)
      .pipe(map(mapConversation));
  }

  checkExistenceInCurrentView(conversationId: string): Observable<Conversation | undefined> {
    const { workspaceAlias, categoryId, status, label, account } = this.store.selectSnapshot(
      RouterSelectors.state,
    ).params;

    const workspaces = this.store.selectSnapshot(WorkspacesSelectors.workspaces);
    const workspaceId = getWorkspaceIdByAlias(workspaces, workspaceAlias);
    const categoryEndpointSegments = getCategoryEndpoint(categoryId);
    if (!categoryEndpointSegments) return of(undefined);

    const filterParams: ConversationCategoryFilterParams = {
      labelIds: parseLabelParam(label),
      externalAccountId: parseExternalAccountParam(account),
      status: parseStatusParam(status),
    };

    const urlTree = this.router.createUrlTree(['api', 'stream-conversations', ...categoryEndpointSegments], {
      queryParams: {
        workspaceId,
        conversationId,
        tagIds: filterParams.labelIds,
        externalAccountId: filterParams.externalAccountId,
        status: filterParams.status,
      },
    });

    const path = this.serializer.serialize(urlTree);
    return this.http.getV2<PagingWrapper<ConversationResponse>>(path).pipe(
      map((response) => {
        return response.data[0] ? mapConversation(response.data[0]) : undefined;
      }),
    );
  }
}
