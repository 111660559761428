<div class="signature-editor">
  <div class="signature-editor__input">
    <cc-rich-text-editor
      class="signature-editor__input-inner"
      [formControl]="control()"
      [editor]="editor"
    />
  </div>

  <ng-scrollbar orientation="horizontal">
    <div class="signature-editor__actions">
      <cc-rich-text-editor-bold-control [editors]="[editor]" />
      <cc-rich-text-editor-italic-control [editors]="[editor]" />
      <cc-rich-text-editor-strike-control [editors]="[editor]" />
      <cc-rich-text-editor-underline-control [editors]="[editor]" />

      <div class="signature-editor__actions-divider"></div>

      <cc-rich-text-editor-font-family-control [editors]="[editor]" />

      <div class="signature-editor__actions-divider"></div>

      <cc-rich-text-editor-font-size-control [editors]="[editor]" />

      <div class="signature-editor__actions-divider"></div>

      <cc-rich-text-editor-bullet-list-control [editors]="[editor]" />
      <cc-rich-text-editor-ordered-list-control [editors]="[editor]" />
      <cc-rich-text-editor-table-control [editors]="[editor]" />

      <!-- <div class="signature-editor__actions-divider"></div> -->
    </div>
  </ng-scrollbar>
</div>
