<div #tableDropdownTrigger>
  <app-button
    (click)="tableFocused ? (tableDropdownVisible = !tableDropdownVisible) : handleTableAction('insertTable')"
    [active]="tableDropdownVisible || tableFocused"
    [ccTooltip]="
      tableFocused
        ? ('conversations-v4.composer.tableEditor.modifyTable' | translate)
        : ('conversations-v4.composer.tableEditor.insertTable' | translate)
    "
    [size]="ButtonSize.Small"
    [type]="ButtonType.Ghost"
  >
    <i
      class="icon-table"
      slot="ghost-icon"
    ></i>
  </app-button>
</div>

<ng-template
  (detach)="tableDropdownVisible = false"
  (overlayOutsideClick)="tableDropdownVisible = getOverlayVisibilityAfterOutsideClick($event, tableDropdownTrigger)"
  [cdkConnectedOverlayOpen]="tableDropdownVisible"
  [cdkConnectedOverlayOrigin]="tableDropdownTrigger"
  [cdkConnectedOverlayPositions]="dropdownPositionStrategy"
  cdkConnectedOverlay
>
  <cc-dropdown [shadow]="false">
    <!-- Columns -->
    <cc-dropdown-text>
      <ng-container slot="title">
        {{ 'conversations-v4.composer.tableEditor.columns.title' | translate }}
      </ng-container>
    </cc-dropdown-text>

    <cc-dropdown-action
      (click)="handleTableAction('addColumnBefore')"
      leadingIcon="icon-column-insert-left"
    >
      <ng-container slot="title">
        {{ 'conversations-v4.composer.tableEditor.columns.addColumnBefore' | translate }}
      </ng-container>
    </cc-dropdown-action>

    <cc-dropdown-action
      (click)="handleTableAction('addColumnAfter')"
      leadingIcon="icon-column-insert-right"
    >
      <ng-container slot="title">
        {{ 'conversations-v4.composer.tableEditor.columns.addColumnAfter' | translate }}
      </ng-container>
    </cc-dropdown-action>

    @if (canDeleteColumn) {
      <cc-dropdown-action
        (click)="handleTableAction('deleteColumn')"
        leadingIcon="icon-column-remove"
      >
        <ng-container slot="title">
          {{ 'conversations-v4.composer.tableEditor.columns.deleteColumn' | translate }}
        </ng-container>
      </cc-dropdown-action>
    }

    <!-- Rows -->
    <cc-dropdown-text>
      <ng-container slot="title">
        {{ 'conversations-v4.composer.tableEditor.rows.title' | translate }}
      </ng-container>
    </cc-dropdown-text>

    <cc-dropdown-action
      (click)="handleTableAction('addRowAbove')"
      leadingIcon="icon-row-insert-top"
    >
      <ng-container slot="title">
        {{ 'conversations-v4.composer.tableEditor.rows.addRowAbove' | translate }}
      </ng-container>
    </cc-dropdown-action>

    <cc-dropdown-action
      (click)="handleTableAction('addRowBelow')"
      leadingIcon="icon-row-insert-bottom"
    >
      <ng-container slot="title">
        {{ 'conversations-v4.composer.tableEditor.rows.addRowBelow' | translate }}
      </ng-container>
    </cc-dropdown-action>

    @if (canDeleteRow) {
      <cc-dropdown-action
        (click)="handleTableAction('deleteRow')"
        leadingIcon="icon-row-remove"
      >
        <ng-container slot="title">
          {{ 'conversations-v4.composer.tableEditor.rows.deleteRow' | translate }}
        </ng-container>
      </cc-dropdown-action>
    }

    <!-- Cells -->
    <cc-dropdown-text>
      <ng-container slot="title">
        {{ 'conversations-v4.composer.tableEditor.cells.title' | translate }}
      </ng-container>
    </cc-dropdown-text>

    <cc-dropdown-action
      (click)="handleTableAction('toggleHeaderCell')"
      [leadingIcon]="isHeaderCell ? 'icon-table-down' : 'icon-table-up'"
    >
      <ng-container slot="title">
        @if (isHeaderCell) {
          {{ 'conversations-v4.composer.tableEditor.cells.disableHeaderCell' | translate }}
        } @else {
          {{ 'conversations-v4.composer.tableEditor.cells.enableHeaderCell' | translate }}
        }
      </ng-container>
    </cc-dropdown-action>

    @if (canMergeCells) {
      <cc-dropdown-action
        leadingIcon="icon-merge-alt"
        (click)="handleTableAction('mergeCells')"
      >
        <ng-container slot="title">
          {{ 'conversations-v4.composer.tableEditor.cells.mergeCells' | translate }}
        </ng-container>
      </cc-dropdown-action>
    }

    @if (canSplitCell) {
      <cc-dropdown-action
        leadingIcon="icon-split"
        (click)="handleTableAction('splitCell')"
      >
        <ng-container slot="title">
          {{ 'conversations-v4.composer.tableEditor.cells.splitCell' | translate }}
        </ng-container>
      </cc-dropdown-action>
    }

    <cc-dropdown-divider />

    <cc-dropdown-action
      (click)="handleTableAction('deleteTable')"
      leadingIcon="icon-table-minus"
    >
      <ng-container slot="title">
        {{ 'conversations-v4.composer.tableEditor.deleteTable' | translate }}
      </ng-container>
    </cc-dropdown-action>
  </cc-dropdown>
</ng-template>
