import { ChangeDetectionStrategy, Component, inject, input, Input, OnInit, output } from '@angular/core';
import { UntilDestroy } from '@ngneat/until-destroy';
import { TooltipAlignment, TooltipPosition } from '@design/overlays/tooltip/tooltip';
import { CoreModule } from '@core/core.module';
import { AsyncPipe, NgOptimizedImage } from '@angular/common';
import { TooltipDirective } from '@design/overlays/tooltip/tooltip.directive';
import { DateFormatDistancePipe } from '@core/pipes/date-format.pipe';
import { Conversation } from '@conversations/conversations/state/conversations/conversations-state.model';
import { Observable } from 'rxjs';
import { Store } from '@ngxs/store';
import { WorkspacesSelectors } from '@conversations/workspaces/state/workspaces/workspaces.selectors';
import { map } from 'rxjs/operators';
import { ExternalAccountStatus } from '@conversations/workspaces/state/workspaces/workspaces-state.model';
import { LabelsSelectors } from '@conversations/workspaces/state/labels/labels.selectors';
import { TranslateModule } from '@ngx-translate/core';
import { UserAvatarComponent } from '../../../../../stories/misc/user-avatar/user-logo.component';

export const getLastMessageDateLocale = (): Locale => {
  return {
    formatDistance: (token, count) => {
      const formatDistanceLocale = {
        xSeconds: '<1m',
        xMinutes: '{{count}}m',
        xHours: '{{count}}h',
        xDays: '{{count}}d',
        xMonths: '{{count}}mo',
        xYears: '{{count}}y',
      };
      return formatDistanceLocale[token].replace('{{count}}', count);
    },
  };
};

@UntilDestroy()
@Component({
  selector: 'cc-conversations-list-item',
  standalone: true,
  templateUrl: './conversations-list-item.component.html',
  styleUrls: ['./conversations-list-item.component.scss'],
  changeDetection: ChangeDetectionStrategy.OnPush,
  imports: [
    CoreModule,
    NgOptimizedImage,
    TooltipDirective,
    DateFormatDistancePipe,
    AsyncPipe,
    TranslateModule,
    UserAvatarComponent,
  ],
})
export class ConversationsListItemComponent implements OnInit {
  @Input()
  active: boolean;

  @Input()
  conversation: Conversation;

  readToggleDisabled = input<boolean>(false);

  markAsRead = output<void>();
  markAsUnread = output<void>();

  protected parentAccountHasIssues$: Observable<boolean>;
  protected hasLabels$: Observable<boolean>;

  protected readonly TooltipAlignment = TooltipAlignment;
  protected readonly TooltipPosition = TooltipPosition;
  protected readonly getLastMessageDateLocale = getLastMessageDateLocale;

  private readonly store = inject(Store);

  get senderAvatar(): string | undefined {
    return this.conversation.latestMessage.sender?.avatarUrl;
  }

  get subjectEmpty(): boolean {
    return !this.conversation.subject?.trim();
  }

  get messageEmpty(): boolean {
    return !this.conversation.latestMessage?.messageSnippet?.trim();
  }

  ngOnInit(): void {
    this.parentAccountHasIssues$ = this.store
      .select(WorkspacesSelectors.externalAccountById(this.conversation.externalAccountId))
      .pipe(
        map((account) => {
          return [
            ExternalAccountStatus.Disconnected,
            ExternalAccountStatus.Expired,
            ExternalAccountStatus.Failed,
          ].includes(account?.status);
        }),
      );

    this.hasLabels$ = this.store.select(LabelsSelectors.labels).pipe(
      map((labels) => {
        return labels?.filter((label) => !label.system && this.conversation.labelIds.includes(label.id)).length > 0;
      }),
    );
  }

  toggleReadStatus(event: Event): void {
    event.stopPropagation();

    if (this.conversation.unreadMessages > 0) {
      this.markAsRead.emit();
    } else {
      this.markAsUnread.emit();
    }
  }
}
