import { ChangeDetectionStrategy, Component, inject, OnInit } from '@angular/core';
import { CommonModule } from '@angular/common';
import { ExternalAccount, Workspace } from '@conversations/workspaces/state/workspaces/workspaces-state.model';
import { ActivatedRoute } from '@angular/router';
import { combineLatest, map, Observable } from 'rxjs';
import { UserRole } from '@core/models/user';
import { UserService } from '@core/services/user.service';
import { Label } from '@conversations/workspaces/state/labels/labels-state.model';
import { Store } from '@ngxs/store';
import { LabelsSelectors } from '@conversations/workspaces/state/labels/labels.selectors';
import { startWith, take } from 'rxjs/operators';
import { FormControl } from '@angular/forms';
import { WorkspacesSelectors } from '@conversations/workspaces/state/workspaces/workspaces.selectors';
import { ButtonSize, ButtonType } from '@design/buttons/button/types';
import { TooltipPosition } from '@design/overlays/tooltip/tooltip';
import { CreateLabel, DeleteLabel, UpdateLabel } from '@conversations/workspaces/state/labels/labels.actions';
import { NgScrollbarModule } from 'ngx-scrollbar';
import { ScrollingModule } from '@angular/cdk/scrolling';
import { TableComponent } from '@design/table/table.component';
import { ThComponent } from '@design/table/th/th.component';
import { TrComponent } from '@design/table/tr/tr.component';
import { TdComponent } from '@design/table/td/td.component';
import { LabelIconComponent } from '@design/misc/label-icon/label-icon.component';
import { Dialog, DialogModule } from '@angular/cdk/dialog';
import {
  LabelEditorDialogData,
  LabelEditorDialogResult,
  LabelEditorModalComponent,
} from '@conversations/workspaces/modals/label-editor-modal/label-editor-modal.component';
import {
  ConfirmationDialogAppearance,
  ConfirmationDialogComponent,
  ConfirmationDialogData,
  ConfirmationDialogResult,
} from '@design/overlays/confirmation-dialog/confirmation-dialog.component';
import { ButtonComponent } from '@design/buttons/button/button.component';
import { ToggleComponent } from '@design/forms/toggle/toggle.component';
import { TooltipDirective } from '@design/overlays/tooltip/tooltip.directive';
import { TranslateModule, TranslateService } from '@ngx-translate/core';

@Component({
  selector: 'cc-workspace-settings-labels',
  standalone: true,
  imports: [
    CommonModule,
    NgScrollbarModule,
    ScrollingModule,
    TableComponent,
    ThComponent,
    TrComponent,
    TdComponent,
    LabelIconComponent,
    DialogModule,
    ButtonComponent,
    ToggleComponent,
    TooltipDirective,
    TranslateModule,
  ],
  templateUrl: './workspace-settings-labels.component.html',
  styleUrls: ['./workspace-settings-labels.component.scss'],
  changeDetection: ChangeDetectionStrategy.OnPush,
})
export class WorkspaceSettingsLabelsComponent implements OnInit {
  protected labels$: Observable<Label[]>;
  protected labelsCount$: Observable<number>;

  protected workspace: Workspace;
  protected externalAccounts: ExternalAccount[];

  protected searchFormControl = new FormControl<string>('');
  protected canEditLabels$: Observable<boolean>;

  protected readonly ButtonSize = ButtonSize;
  protected readonly ButtonType = ButtonType;
  protected readonly TooltipPosition = TooltipPosition;

  private readonly activatedRoute = inject(ActivatedRoute);
  private readonly store = inject(Store);
  private readonly userService = inject(UserService);
  private readonly dialog = inject(Dialog);
  private readonly translate = inject(TranslateService);

  get affectedAccountsString(): string {
    return this.externalAccounts.map((account) => account.email).join(', ');
  }

  ngOnInit(): void {
    this.workspace = this.activatedRoute.parent.snapshot.data['workspace'];

    const labels$ = this.store
      .select(LabelsSelectors.labelsByWorkspaceId(this.workspace.id))
      .pipe(map((labels) => labels.filter((label) => !label.system).sort((a, b) => a.name.localeCompare(b.name))));

    this.labels$ = combineLatest([labels$, this.searchFormControl.valueChanges.pipe(startWith(''))]).pipe(
      map(([labels, searchQuery]) => {
        labels = labels.filter((label) => !label.system);
        if (!searchQuery) return labels;

        return labels.filter((label) => {
          return label.name.toLowerCase().includes(searchQuery.toLowerCase());
        });
      }),
    );

    this.labelsCount$ = labels$.pipe(map((labels) => labels.length));

    this.externalAccounts = this.store.selectSnapshot(
      WorkspacesSelectors.externalAccountsByWorkspaceId(this.workspace.id),
    );

    this.canEditLabels$ = this.userService.profile$.pipe(
      map((user) => {
        if (this.workspace.ownerType === 'user') return true;
        return user.roles?.includes(UserRole.companyAdmin);
      }),
    );
  }

  createLabel(): void {
    const dialog = this.dialog.open<LabelEditorDialogResult, LabelEditorDialogData>(LabelEditorModalComponent, {
      data: {
        preselectedWorkspaceId: this.workspace.id,
      },
    });

    dialog.closed.pipe(take(1)).subscribe((newLabel: LabelEditorDialogResult | undefined) => {
      if (!newLabel) return;
      this.store.dispatch(new CreateLabel(this.workspace.id, newLabel));
    });
  }

  toggleLabelVisibility(label: Label, checked: boolean): void {
    this.store.dispatch(
      new UpdateLabel(this.workspace.id, label.id, {
        visibleInNavigation: checked,
      }),
    );
  }

  editLabel(label: Label): void {
    const dialog = this.dialog.open<LabelEditorDialogResult, LabelEditorDialogData>(LabelEditorModalComponent, {
      data: {
        labelToEdit: label,
        preselectedWorkspaceId: this.workspace.id,
      },
    });

    dialog.closed.pipe(take(1)).subscribe((editedLabel: LabelEditorDialogResult | undefined) => {
      if (!editedLabel) return;
      this.store.dispatch(new UpdateLabel(this.workspace.id, label.id, editedLabel));
    });
  }

  deleteLabel(label: Label): void {
    const dialog = this.dialog.open<ConfirmationDialogResult, ConfirmationDialogData>(ConfirmationDialogComponent, {
      data: {
        title: this.translate.instant('conversations-v4.workspaces.settings.labels.deletePrompt.title'),
        message: this.translate.instant('conversations-v4.workspaces.settings.labels.deletePrompt.message'),
        confirmText: this.translate.instant('conversations-v4.workspaces.settings.labels.deletePrompt.deleteLabel'),
        cancelText: this.translate.instant('common.buttons.cancel'),
        destructive: true,
        style: ConfirmationDialogAppearance.Default,
      },
    });

    dialog.closed.pipe(take(1)).subscribe((result: ConfirmationDialogResult | undefined) => {
      if (result !== 'confirm') return;
      this.store.dispatch(new DeleteLabel(this.workspace.id, label.id));
    });
  }
}
