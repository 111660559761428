<div class="task-embed-modal-placeholder">
  <div class="task-embed-modal-placeholder__icon">
    @switch (type) {
      @case ('empty') {
        <i class="icon-none"></i>
      }
      @case ('loading') {
        <div class="task-embed-modal-placeholder__icon-spinner"></div>
      }
      @case ('error') {
        <i class="icon-none"></i>
      }
    }
  </div>

  <div class="task-embed-modal-placeholder__title">
    @switch (type) {
      @case ('empty') {
        {{ 'conversations-v4.tasks.embedModal.noTasksPlaceholder.title' | translate }}
      }
      @case ('loading') {
        {{ 'conversations-v4.tasks.embedModal.loadingPlaceholder.title' | translate }}
      }
      @case ('error') {
        {{ 'conversations-v4.tasks.embedModal.errorPlaceholder.title' | translate }}
      }
    }
  </div>

  <div class="task-embed-modal-placeholder__description">
    @switch (type) {
      @case ('empty') {
        {{ 'conversations-v4.tasks.embedModal.errorPlaceholder.message' | translate }}
      }
      @case ('loading') {
        {{ 'conversations-v4.tasks.embedModal.errorPlaceholder.message' | translate }}
      }
      @case ('error') {
        {{ 'conversations-v4.tasks.embedModal.errorPlaceholder.message' | translate }}
      }
    }
  </div>
</div>
