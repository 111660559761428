<div
  (ccResize)="updateCompactMode($event)"
  class="conversation-header-actions"
>
  <div class="conversation-header-actions__left">
    <cc-button-group>
      <app-button
        [ccTooltip]="'common.buttons.exit' | translate"
        [routerLink]="['..']"
        [size]="ButtonSize.Small"
        [tooltipPosition]="TooltipPosition.Bottom"
        [type]="ButtonType.GhostText"
      >
        <i
          class="icon-arrow-left-line"
          slot="ghost-icon"
        ></i>
      </app-button>

      <app-button
        (click)="toggleStatus()"
        [ccTooltip]="
          details.status === ConversationStatus.Open
            ? ('conversations-v4.conversation.actions.close' | translate)
            : ('conversations-v4.conversation.actions.reopen' | translate)
        "
        [disabled]="!assignee || accountIssue"
        [size]="ButtonSize.Small"
        [tooltipPosition]="TooltipPosition.Bottom"
        [type]="ButtonType.GhostText"
      >
        <i
          [class.icon-check]="details.status === ConversationStatus.Open"
          [class.icon-reopen]="details.status !== ConversationStatus.Open"
          slot="ghost-icon"
        ></i>
      </app-button>
    </cc-button-group>

    @if (workspace.ownerType === 'team') {
      <cc-conversation-header-actions-assignee-picker
        [details]="details"
        [disabled]="accountIssue"
        [workspace]="workspace"
      ></cc-conversation-header-actions-assignee-picker>
    }
  </div>

  <div class="conversation-header-actions__right">
    <div class="conversation-header-actions__right-buttons-group">
      <div
        [class.conversation-header-actions__right-buttons-group--collapsible--collapsed]="compactMode"
        class="conversation-header-actions__right-buttons-group--collapsible"
      >
        <app-button
          (click)="togglePrioritizedStatus()"
          [ccTooltip]="
            details.prioritized
              ? ('conversations-v4.conversation.actions.unprioritize' | translate)
              : ('conversations-v4.conversation.actions.prioritize' | translate)
          "
          [disabled]="accountIssue"
          [size]="ButtonSize.Big"
          [tooltipPosition]="TooltipPosition.Bottom"
          [type]="ButtonType.Ghost"
          class="conversation-header-actions__right-buttons-group--prioritize"
        >
          <i
            [class.icon-star-fill]="details.prioritized"
            [class.icon-star]="!details.prioritized"
            slot="ghost-icon"
          ></i>
        </app-button>

        <div #snoozeButton>
          <app-button
            (click)="details.snoozedUntil ? unsnooze() : (snoozeDropdownVisible = !snoozeDropdownVisible)"
            [ccTooltip]="
              details.snoozedUntil
                ? ('conversations-v4.conversation.actions.unsnooze' | translate)
                : ('conversations-v4.conversation.actions.snooze' | translate)
            "
            [disabled]="!assignee || accountIssue"
            [size]="ButtonSize.Big"
            [tooltipPosition]="TooltipPosition.Bottom"
            [type]="ButtonType.Ghost"
          >
            <i
              class="icon-clock"
              slot="ghost-icon"
            ></i>
          </app-button>
        </div>

        <div #labelsButton>
          <app-button
            (click)="labelDropdownVisible = !labelDropdownVisible"
            [ccTooltip]="'conversations-v4.conversation.actions.label' | translate"
            [disabled]="!assignee || accountIssue"
            [size]="ButtonSize.Big"
            [tooltipPosition]="TooltipPosition.Bottom"
            [type]="ButtonType.Ghost"
          >
            <i
              class="icon-tag-alt"
              slot="ghost-icon"
            ></i>
          </app-button>
        </div>

        <app-button
          [ccTooltip]="'conversations-v4.conversation.actions.viewDetails' | translate"
          [size]="ButtonSize.Big"
          [tooltipPosition]="TooltipPosition.Bottom"
          [type]="ButtonType.Ghost"
        >
          <i
            class="icon-info"
            slot="ghost-icon"
          ></i>
        </app-button>
      </div>

      <div #moreActionsButton>
        <app-button
          (click)="
            moreActionsDropdownVisible = !moreActionsDropdownVisible;
            snoozeDropdownVisible = false;
            labelDropdownVisible = false
          "
          [ccTooltip]="'common.buttons.moreActions' | translate"
          [size]="ButtonSize.Big"
          [tooltipAlignment]="TooltipAlignment.End"
          [tooltipPosition]="TooltipPosition.Bottom"
          [type]="ButtonType.Ghost"
        >
          <i
            class="icon-more"
            slot="ghost-icon"
          ></i>
        </app-button>
      </div>
    </div>
  </div>
</div>

<ng-template
  (detach)="moreActionsDropdownVisible = false"
  (overlayOutsideClick)="moreActionsDropdownVisible = getOverlayVisibilityAfterOutsideClick($event, moreActionsButton)"
  [cdkConnectedOverlayOpen]="moreActionsDropdownVisible"
  [cdkConnectedOverlayOrigin]="moreActionsButton"
  [cdkConnectedOverlayPositions]="dropdownPositionStrategy"
  cdkConnectedOverlay
>
  <cc-dropdown>
    @if (compactMode) {
      <cc-dropdown-action
        (click)="moreActionsDropdownVisible = false; togglePrioritizedStatus()"
        [disabled]="accountIssue"
        [leadingIconColor]="details.prioritized ? '#fac300' : null"
        [leadingIcon]="details.prioritized ? 'icon-star-fill' : 'icon-star'"
      >
        <ng-container slot="title">
          {{
            details.prioritized
              ? ('conversations-v4.conversation.actions.unprioritize' | translate)
              : ('conversations-v4.conversation.actions.prioritize' | translate)
          }}
        </ng-container>
      </cc-dropdown-action>

      <cc-dropdown-action
        (click)="
          moreActionsDropdownVisible = false;
          details.snoozedUntil ? unsnooze() : (snoozeDropdownVisible = !snoozeDropdownVisible)
        "
        [disabled]="!assignee || accountIssue"
        leadingIcon="icon-clock"
      >
        <ng-container slot="title">
          {{ 'conversations-v4.conversation.actions.snooze' | translate }}
        </ng-container>
      </cc-dropdown-action>

      <cc-dropdown-action
        (click)="moreActionsDropdownVisible = false; labelDropdownVisible = !labelDropdownVisible"
        [disabled]="!assignee || accountIssue"
        leadingIcon="icon-tag-alt"
      >
        <ng-container slot="title">
          {{ 'conversations-v4.conversation.actions.label' | translate }}
        </ng-container>
      </cc-dropdown-action>

      <cc-dropdown-action leadingIcon="icon-info">
        <ng-container slot="title">
          {{ 'conversations-v4.conversation.actions.viewDetails' | translate }}
        </ng-container>
      </cc-dropdown-action>

      <cc-dropdown-divider />
    }

    <cc-dropdown-action
      (click)="moreActionsDropdownVisible = false; toggleFolder(ConversationFolder.Archive)"
      [disabled]="!assignee || accountIssue"
      leadingIcon="icon-all-mail"
    >
      <ng-container slot="title">
        {{
          details.folder === ConversationFolder.Archive
            ? ('conversations-v4.conversation.actions.unarchive' | translate)
            : ('conversations-v4.conversation.actions.archive' | translate)
        }}
      </ng-container>
    </cc-dropdown-action>

    <cc-dropdown-action
      (click)="moreActionsDropdownVisible = false; toggleFolder(ConversationFolder.Spam)"
      [disabled]="!assignee || accountIssue"
      leadingIcon="icon-spam"
    >
      <ng-container slot="title">
        {{
          details.folder === ConversationFolder.Spam
            ? ('conversations-v4.conversation.actions.notSpam' | translate)
            : ('conversations-v4.conversation.actions.moveToSpam' | translate)
        }}
      </ng-container>
    </cc-dropdown-action>

    <cc-dropdown-action
      (click)="moreActionsDropdownVisible = false; toggleFolder(ConversationFolder.Trash)"
      [disabled]="!assignee || accountIssue"
      leadingIcon="icon-trash"
    >
      <ng-container slot="title">
        {{
          details.folder === ConversationFolder.Trash
            ? ('conversations-v4.conversation.actions.restoreFromTrash' | translate)
            : ('conversations-v4.conversation.actions.moveToTrash' | translate)
        }}
      </ng-container>
    </cc-dropdown-action>
  </cc-dropdown>
</ng-template>

<ng-template
  (detach)="snoozeDropdownVisible = false"
  (overlayOutsideClick)="
    snoozeDropdownVisible = getOverlayVisibilityAfterOutsideClick(
      $event,
      compactMode ? moreActionsButton : snoozeButton
    )
  "
  [cdkConnectedOverlayOpen]="snoozeDropdownVisible"
  [cdkConnectedOverlayOrigin]="compactMode ? moreActionsButton : snoozeButton"
  [cdkConnectedOverlayPositions]="dropdownPositionStrategy"
  cdkConnectedOverlay
>
  <cc-dropdown width="260px">
    @for (snoozeOption of snoozeOptions; track snoozeOption.option) {
      <cc-dropdown-action
        (click)="setSnoozeOption(snoozeOption.option); snoozeDropdownVisible = false"
        leadingIcon="icon-snooze"
      >
        <ng-container slot="title">
          @switch (snoozeOption.option) {
            @case (SnoozeOption.LaterToday) {
              {{
                'conversations-v4.conversation.actions.snoozeOptions.today'
                  | translate
                    : {
                        date: snoozeOption.date | ccDateFormat: { time: 'default' },
                      }
              }}
            }
            @case (SnoozeOption.Tomorrow) {
              {{
                'conversations-v4.conversation.actions.snoozeOptions.tomorrow'
                  | translate
                    : {
                        date: snoozeOption.date | ccDateFormat: { time: 'default', date: 'long-no-year' },
                      }
              }}
            }
            @case (SnoozeOption.NextWeek) {
              {{
                'conversations-v4.conversation.actions.snoozeOptions.nextWeek'
                  | translate
                    : {
                        date: snoozeOption.date | ccDateFormat: { time: 'default', date: 'long-no-year' },
                      }
              }}
            }
          }
        </ng-container>
      </cc-dropdown-action>
    }
  </cc-dropdown>
</ng-template>

<ng-template
  (detach)="labelDropdownVisible = false"
  (overlayOutsideClick)="
    labelDropdownVisible = getOverlayVisibilityAfterOutsideClick($event, compactMode ? moreActionsButton : labelsButton)
  "
  [cdkConnectedOverlayOpen]="labelDropdownVisible"
  [cdkConnectedOverlayOrigin]="compactMode ? moreActionsButton : labelsButton"
  [cdkConnectedOverlayPositions]="dropdownPositionStrategy"
  cdkConnectedOverlay
>
  <cc-dropdown width="280px">
    <cc-label-picker
      (createLabel)="createLabel($event); labelDropdownVisible = false"
      (deselectLabel)="unassignLabel($event)"
      (manageLabels)="openWorkspaceSettingsLabelsTab(); labelDropdownVisible = false"
      (selectLabel)="assignLabel($event)"
      [primaryWorkspaceId]="details.workspaceId"
      [selectedLabelIds]="details.labelIds"
      [workspaceIds]="[details.workspaceId]"
      [selectionMode]="(isMicrosoftAccount$ | async) ? 'single-radio' : 'multiple'"
    />
  </cc-dropdown>
</ng-template>
