import { ChangeDetectionStrategy, Component, EventEmitter, Input, Output } from '@angular/core';

import { ButtonSize, ButtonType } from '@design/buttons/button/types';
import { ButtonComponent } from '@design/buttons/button/button.component';
import { TooltipDirective } from '@design/overlays/tooltip/tooltip.directive';
import { TranslateModule } from '@ngx-translate/core';

@Component({
  selector: 'cc-composer-actions-link-popup',
  standalone: true,
  imports: [ButtonComponent, TooltipDirective, TranslateModule],
  templateUrl: './composer-actions-link-popup.component.html',
  styleUrls: ['./composer-actions-link-popup.component.scss'],
  changeDetection: ChangeDetectionStrategy.OnPush,
})
export class ComposerActionsLinkPopupComponent {
  @Input()
  title: string;

  @Input()
  url: string;

  @Output()
  dismiss = new EventEmitter<void>();

  @Output()
  edit = new EventEmitter<void>();

  @Output()
  delete = new EventEmitter<void>();
  protected readonly ButtonType = ButtonType;
  protected readonly ButtonSize = ButtonSize;
}
