<div class="task-drawer-details">
  <div class="task-drawer-details__section">
    <div class="task-drawer-details__section-title">
      {{ 'conversations-v4.tasks.assignee' | translate }}
    </div>

    <div class="task-drawer-details__section-content task-drawer-details__section-content--performer">
      <div class="task-drawer-details__section-content--performer-select">
        <cc-task-performer-select
          [canAssignToCurrentUser]="canAssignToCurrentUser"
          [canChangeAssignee]="canChangeAssignee"
          [canReroute]="canReroute"
          [performerCompany]="task.assigneeCompany"
          [performer]="task.acceptedBy"
          [taskId]="task.id"
          type="assignee"
        ></cc-task-performer-select>
      </div>

      @if (task.acceptedBy) {
        <div class="task-drawer-details__section-content--performer-details">
          <span>{{ task.acceptedBy.title }}</span>
          <span class="task-drawer-details__section-content--performer-details-divider">&nbsp;•&nbsp;</span>
          <span>{{ task.assigneeCompany.name }}</span>
        </div>
      }
    </div>
  </div>

  <div class="task-drawer-details__section">
    <div class="task-drawer-details__section-title">
      {{ 'conversations-v4.tasks.assigner' | translate }}
    </div>

    <div class="task-drawer-details__section-content task-drawer-details__section-content--performer">
      <div class="task-drawer-details__section-content--performer-select">
        <cc-task-performer-select
          [performerCompany]="task.assignerCompany"
          [performer]="task.assigner"
          [taskId]="task.id"
          type="assigner"
        ></cc-task-performer-select>
      </div>

      <div class="task-drawer-details__section-content--performer-details">
        <span>{{ task.assigner.title }}</span>
        <span class="task-drawer-details__section-content--performer-details-divider">&nbsp;•&nbsp;</span>
        <span>{{ task.assignerCompany.name }}</span>
      </div>
    </div>
  </div>

  <div class="task-drawer-details__divider"></div>

  @if (task.product) {
    <div class="task-drawer-details__section">
      <div class="task-drawer-details__section-title">
        {{ 'conversations-v4.tasks.product' | translate }}
      </div>
      <div class="task-drawer-details__section-content">
        <cc-task-product-card [product]="task.product"></cc-task-product-card>
      </div>
    </div>
  }

  <div class="task-drawer-details__section">
    <div class="task-drawer-details__section-title">
      {{ 'conversations-v4.tasks.description' | translate }}
    </div>

    <div class="task-drawer-details__section-content">
      {{ task.description }}
    </div>
  </div>

  @if (usersWithAccess.length) {
    <div class="task-drawer-details__divider"></div>
    <div class="task-drawer-details__section">
      <div class="task-drawer-details__section-title">
        {{ 'conversations-v4.tasks.whoHasAccess' | translate }}

        <i
          [ccTooltip]="'conversations-v4.tasks.whoHasAccessTooltip' | translate"
          class="icon-info-fill"
        ></i>
      </div>
      <div class="task-drawer-details__section-content task-drawer-details__section-content--users-with-access">
        <div class="task-drawer-details__section-content--users-with-access-avatars">
          @for (user of usersWithAccess; track user.id; let first = $first) {
            <img
              [alt]="user.firstName + ' ' + user.lastName"
              [ccTooltip]="user.firstName + ' ' + user.lastName"
              [src]="user.avatarUrl"
              class="task-drawer-details__section-content--users-with-access-avatars-avatar"
              height="48px"
              width="48px"
            />
            @if (first && task.acceptedBy) {
              <div class="task-drawer-details__section-content--users-with-access-avatars-divider"></div>
            }
          }
        </div>
        <div class="task-drawer-details__section-content--users-with-access-count">
          @if (usersWithAccess.length === 1) {
            {{ 'conversations-v4.tasks.contactsCountSingular' | translate }}
          } @else {
            {{
              'conversations-v4.tasks.contactsCountPlural'
                | translate
                  : {
                      count: usersWithAccess.length,
                    }
            }}
          }
        </div>
      </div>
    </div>
  }
</div>
