import {
  AfterViewInit,
  ChangeDetectionStrategy,
  ChangeDetectorRef,
  Component,
  ElementRef,
  inject,
  Input,
  ViewChild,
} from '@angular/core';

import { CdkScrollableModule } from '@angular/cdk/scrolling';
import { NgScrollbar, NgScrollbarModule } from 'ngx-scrollbar';
import {
  ConversationDetails,
  Draft,
  Message,
  MessageType,
  PendingMessage,
} from '@conversations/conversation/state/conversation/conversation-state.model';
import { GroupMessagesPipe } from '@conversations/conversation/active-conversation/conversation/conversation-messages/group-messages.pipe';
import { MessageGroupDateHeaderComponent } from '@conversations/conversation/active-conversation/conversation/conversation-messages/message-group-date-header/message-group-date-header.component';
import { MessageComponent } from '@conversations/conversation/active-conversation/conversation/conversation-messages/message/message.component';
import { InfiniteScrollDirective } from 'ngx-infinite-scroll';
import { Store } from '@ngxs/store';
import {
  LoadMessagesAfterDate,
  LoadNextMessages,
  LoadPreviousMessages,
} from '@conversations/conversation/state/conversation/conversation.actions';
import { UntilDestroy, untilDestroyed } from '@ngneat/until-destroy';
import { ComposersSelectors } from '@conversations/composer/state/composers/composers.selectors';
import { ResetComposer, SetMessageType } from '@conversations/composer/state/composers/composers.actions';
import { ComposersActionsDistributorService } from '@conversations/composer/state/composers/composers-actions-distributor.service';
import { Dialog, DialogModule } from '@angular/cdk/dialog';
import {
  ConfirmationDialogAppearance,
  ConfirmationDialogComponent,
  ConfirmationDialogData,
  ConfirmationDialogResult,
} from '@design/overlays/confirmation-dialog/confirmation-dialog.component';
import { take } from 'rxjs/operators';
import { AutoAnimateDirective } from '@core/directives/auto-animate.directive';
import { ConversationScrollService } from '@conversations/conversation/state/conversation/conversation-scroll.service';
import { ResizeDirective } from '@core/directives/resize.directive';
import { subSeconds } from 'date-fns';
import { TranslateService } from '@ngx-translate/core';
import { asyncScheduler } from 'rxjs';
import { transformMessageToCompactMessage } from '@clover/conversations-v4/conversation/state/conversation/conversation.service';

const messagesGroupHeaderOffset = 16;

@UntilDestroy()
@Component({
  selector: 'cc-conversation-messages',
  standalone: true,
  imports: [
    NgScrollbarModule,
    InfiniteScrollDirective,
    CdkScrollableModule,
    GroupMessagesPipe,
    MessageGroupDateHeaderComponent,
    MessageComponent,
    DialogModule,
    AutoAnimateDirective,
    ResizeDirective,
  ],
  templateUrl: './conversation-messages.component.html',
  styleUrls: ['./conversation-messages.component.scss'],
  changeDetection: ChangeDetectionStrategy.OnPush,
})
export class ConversationMessagesComponent implements AfterViewInit {
  @Input()
  messages: Message[] = [];

  @Input()
  pendingMessages: PendingMessage[] = [];

  @Input()
  details: ConversationDetails;

  @Input()
  assignee: boolean;

  @Input()
  messagesLoadingStatus: 'void' | 'loading' | 'loaded' | 'loading-prev' | 'loading-next' | 'error' = 'void';

  @Input()
  hasPreviousMessages: boolean;

  @Input()
  hasNextMessages: boolean;

  @Input()
  accountIssue: 'failed' | 'expired' | undefined;

  @ViewChild('scrollbar', {
    static: true,
    read: NgScrollbar,
  })
  scrollbar: NgScrollbar;

  @ViewChild('messagesList', {
    static: true,
    read: ElementRef,
  })
  messagesList: ElementRef<HTMLDivElement>;

  protected scrollbarHeight: number;
  protected positionFromBottom = 0;
  protected animationsEnabled = true;

  private firstInfiniteScrollEvent = true;
  private previousPositionFromTop = 0;
  private previousPositionFromBottom = 0;

  private readonly store = inject(Store);
  private readonly dialog = inject(Dialog);
  private readonly conversationScrollService = inject(ConversationScrollService);
  private readonly composersActionsService = inject(ComposersActionsDistributorService);
  private readonly translate = inject(TranslateService);
  private readonly cdr = inject(ChangeDetectorRef);

  async ngAfterViewInit(): Promise<void> {
    this.scrollbar.afterInit.pipe(untilDestroyed(this)).subscribe(async () => {
      await this.scrollbar.scrollTo({ bottom: -1, duration: 0 });
    });

    this.conversationScrollService.scrollDown$.pipe(untilDestroyed(this)).subscribe(async () => {
      await this.scrollbar.scrollTo({ bottom: -1 });
    });

    this.conversationScrollService.scrollToMessage$.pipe(untilDestroyed(this)).subscribe(async (messageId: string) => {
      const messageElement = this.messagesList.nativeElement.querySelector(
        `[data-message-id="${messageId}"]`,
      ) as HTMLElement;
      if (!messageElement) return;

      await this.scrollbar.scrollToElement(messageElement, {
        top: -messagesGroupHeaderOffset,
      });
    });
  }

  loadMessages(direction: 'next' | 'prev'): void {
    if (direction === 'next' && !this.hasNextMessages) return;
    if (direction === 'prev' && !this.hasPreviousMessages) return;

    if (this.firstInfiniteScrollEvent) {
      this.firstInfiniteScrollEvent = false;
      return;
    }

    this.animationsEnabled = false;
    this.cdr.detectChanges();

    this.store
      .dispatch(direction === 'next' ? new LoadNextMessages() : new LoadPreviousMessages())
      .pipe(untilDestroyed(this))
      .subscribe(async () => {
        switch (direction) {
          case 'next':
            await this.scrollbar.scrollTo({ top: this.previousPositionFromTop, duration: 0 });
            break;
          case 'prev':
            await this.scrollbar.scrollTo({ bottom: this.previousPositionFromBottom, duration: 0 });
            break;
        }

        asyncScheduler.schedule(() => {
          this.animationsEnabled = true;
          this.cdr.detectChanges();
        });
      });
  }

  jumpToDate(dateISO: string): void {
    this.store.dispatch(new LoadMessagesAfterDate(dateISO));
  }

  jumpToMessage(messageDate: string): void {
    this.store.dispatch(new LoadMessagesAfterDate(subSeconds(new Date(messageDate), 1).toISOString()));
  }

  replyToMessage(message: Message): void {
    const getReplyMode = (): 'reply' | 'internal' => {
      if (!this.assignee) return 'internal';

      if (message.type === MessageType.Email) return 'reply';
      return 'internal';
    };

    const composer = this.store.selectSnapshot(ComposersSelectors.composerByConversationId(this.details.id));
    this.store.dispatch(new SetMessageType(composer.id, getReplyMode(), transformMessageToCompactMessage(message)));
  }

  editDraft(draft: Draft): void {
    const composer = this.store.selectSnapshot(ComposersSelectors.composerByConversationId(this.details.id));
    if (!composer || composer.draft?.id === draft.id) return;

    this.store.dispatch(new ResetComposer(composer.id, draft));
  }

  deleteDraft(draft: Draft): void {
    const dialog = this.dialog.open<ConfirmationDialogResult, ConfirmationDialogData>(ConfirmationDialogComponent, {
      data: {
        title: this.translate.instant('conversations-v4.composer.deleteDraftPrompt.title'),
        message: this.translate.instant('conversations-v4.composer.deleteDraftPrompt.message'),
        confirmText: this.translate.instant('common.buttons.delete'),
        cancelText: this.translate.instant('common.buttons.cancel'),
        destructive: true,
        style: ConfirmationDialogAppearance.Compact,
      },
    });

    dialog.closed.pipe(take(1)).subscribe((result: ConfirmationDialogResult | undefined) => {
      if (result !== 'confirm') return;
      this.composersActionsService.deleteDraft(draft);
    });
  }

  protected handleScroll(): void {
    const target = this.scrollbar.nativeElement;
    const positionFromTop = target.scrollTop;
    const positionFromBottom = Math.max(target.scrollHeight - target.scrollTop - target.clientHeight, 0);
    this.positionFromBottom = positionFromBottom;

    if (positionFromTop <= 10 && this.messagesLoadingStatus !== 'loading-prev') this.loadMessages('prev');
    if (positionFromBottom <= 10 && this.messagesLoadingStatus !== 'loading-next') this.loadMessages('next');

    this.previousPositionFromTop = positionFromTop;
    this.previousPositionFromBottom = positionFromBottom;
  }
}
