import { ChangeDetectionStrategy, Component, computed } from '@angular/core';
import { ButtonComponent } from '@design/buttons/button/button.component';
import { DropdownActionComponent } from '@design/overlays/dropdown/dropdown-action/dropdown-action.component';
import { DropdownDividerComponent } from '@design/overlays/dropdown/dropdown-divider/dropdown-divider.component';
import { DropdownTextComponent } from '@design/overlays/dropdown/dropdown-text/dropdown-text.component';
import { DropdownTriggerDirective } from '@design/overlays/dropdown/dropdown-trigger.directive';
import { DropdownComponent } from '@design/overlays/dropdown/dropdown.component';
import { TooltipDirective } from '@design/overlays/tooltip/tooltip.directive';
import { TranslateModule } from '@ngx-translate/core';
import { RichTextEditorBaseControlComponent } from '../base-action.component';

type TableActions =
  | 'deleteTable'
  | 'addColumnBefore'
  | 'addColumnAfter'
  | 'deleteColumn'
  | 'addRowAbove'
  | 'addRowBelow'
  | 'deleteRow'
  | 'mergeCells'
  | 'splitCell'
  | 'toggleHeaderCell'
  | 'toggleBorderless'
  | 'togglePaddingless';

@Component({
  selector: 'cc-rich-text-editor-table-control',
  standalone: true,
  imports: [
    ButtonComponent,
    DropdownComponent,
    DropdownTextComponent,
    DropdownActionComponent,
    TranslateModule,
    DropdownTriggerDirective,
    TooltipDirective,
    DropdownDividerComponent,
  ],
  templateUrl: './table-control.component.html',
  changeDetection: ChangeDetectionStrategy.OnPush,
})
export class RichTextEditorTableControlComponent extends RichTextEditorBaseControlComponent {
  focused = computed(() => {
    this.selectionNotifier.listen();
    return this.activeEditor()?.isActive('table');
  });

  canMergeCells = computed(() => {
    this.selectionNotifier.listen();
    return this.activeEditor()?.can().mergeCells();
  });

  canSplitCell = computed(() => {
    this.selectionNotifier.listen();
    return this.activeEditor()?.can().splitCell();
  });

  canDeleteColumn = computed(() => {
    this.selectionNotifier.listen();
    return this.activeEditor()?.can().deleteColumn();
  });

  canDeleteRow = computed(() => {
    this.selectionNotifier.listen();
    return this.activeEditor()?.can().deleteRow();
  });

  isHeaderCell = computed(() => {
    this.selectionNotifier.listen();
    return this.activeEditor()?.isActive('tableHeader');
  });

  isUnstyledCell = computed(() => {
    this.selectionNotifier.listen();
    return this.activeEditor()?.isActive({ tableCellAppearance: 'unstyled' });
  });

  isBorderless = computed(() => {
    this.selectionNotifier.listen();
    return this.activeEditor()?.isActive({ tableCellBorderColor: 'transparent' });
  });

  isPaddingless = computed(() => {
    this.selectionNotifier.listen();
    return this.activeEditor()?.isActive({ tableCellPadding: '0' });
  });

  protected insertTable(): void {
    if (!this.activeEditor()) return;
    this.activeEditor().chain().focus().insertTable({ rows: 3, cols: 3 }).run();
  }

  protected handleTableAction(action: TableActions): void {
    if (!this.activeEditor()) return;

    switch (action) {
      case 'deleteTable':
        this.activeEditor().chain().deleteTable().focus().run();
        break;
      case 'addColumnBefore':
        this.activeEditor().chain().addColumnBefore().focus().run();
        break;
      case 'addColumnAfter':
        this.activeEditor().chain().addColumnAfter().focus().run();
        break;
      case 'deleteColumn':
        this.activeEditor().chain().deleteColumn().focus().run();
        break;
      case 'addRowAbove':
        this.activeEditor().chain().addRowBefore().focus().run();
        break;
      case 'addRowBelow':
        this.activeEditor().chain().addRowAfter().focus().run();
        break;
      case 'deleteRow':
        this.activeEditor().chain().deleteRow().focus().run();
        break;
      case 'mergeCells':
        this.activeEditor().chain().mergeCells().focus().run();
        break;
      case 'splitCell':
        this.activeEditor().chain().splitCell().focus().run();
        break;
      case 'toggleHeaderCell':
        this.activeEditor().chain().toggleHeaderCell().focus().run();
        break;
      case 'toggleBorderless':
        this.activeEditor()
          .chain()
          .setBorderColor(this.isBorderless() ? '#e8eaeb' : 'transparent')
          .focus()
          .run();
        break;
      case 'togglePaddingless':
        this.activeEditor()
          .chain()
          .setPadding(this.isPaddingless() ? '8px 12px' : '0')
          .focus()
          .run();
        break;
    }

    this.selectionNotifier.notify();
  }
}
