import { ChangeDetectionStrategy, Component, inject, Input } from '@angular/core';
import { ComposerInstance } from '@conversations/composer/state/composers/composers-state.model';
import { ComposerSenderPickerComponent } from '@conversations/composer/composer-header/composer-sender-picker/composer-sender-picker.component';
import { ButtonSize, ButtonType } from '@design/buttons/button/types';
import { Store } from '@ngxs/store';
import { ChangePresentation, ChangeSender } from '@conversations/composer/state/composers/composers.actions';
import { ExternalAccount } from '@conversations/workspaces/state/workspaces/workspaces-state.model';

import { TooltipAlignment } from '@design/overlays/tooltip/tooltip';
import { ButtonComponent } from '@design/buttons/button/button.component';
import { TooltipDirective } from '@design/overlays/tooltip/tooltip.directive';
import { TranslateModule } from '@ngx-translate/core';

@Component({
  selector: 'cc-composer-header',
  standalone: true,
  templateUrl: './composer-header.component.html',
  styleUrls: ['./composer-header.component.scss'],
  changeDetection: ChangeDetectionStrategy.OnPush,
  imports: [ComposerSenderPickerComponent, ButtonComponent, TooltipDirective, TranslateModule],
})
export class ComposerHeaderComponent {
  @Input()
  composer: ComposerInstance;

  protected readonly ButtonType = ButtonType;
  protected readonly ButtonSize = ButtonSize;
  protected readonly TooltipAlignment = TooltipAlignment;

  private readonly store = inject(Store);

  togglePresentation(): void {
    if (this.composer.presentation === 'overlay-collapsed') {
      this.store.dispatch(new ChangePresentation(this.composer.id, 'overlay-expanded'));
      return;
    }

    this.store.dispatch(new ChangePresentation(this.composer.id, 'overlay-collapsed'));
  }

  activateInlineMode(): void {
    this.store.dispatch(new ChangePresentation(this.composer.id, 'inline'));
  }

  changeSender(account: ExternalAccount): void {
    this.store.dispatch(new ChangeSender(this.composer.id, account.id));
  }
}
