<div class="composer-actions">
  <div class="composer-actions__group">
    <div #plusDropdownTrigger>
      <app-button
        (click)="plusDropdownVisible = !plusDropdownVisible"
        [active]="plusDropdownVisible"
        [ccTooltip]="'common.buttons.moreActions' | translate"
        [size]="ButtonSize.Small"
        [tooltipAlignment]="TooltipAlignment.Start"
        [type]="ButtonType.Ghost"
        class="composer-actions__group-plus-button"
      >
        <i
          [class.composer-actions__group-plus-button-icon--active]="plusDropdownVisible"
          class="composer-actions__group-plus-button-icon icon-add"
          slot="ghost-icon"
        ></i>
      </app-button>
    </div>

    <ng-template
      (detach)="plusDropdownVisible = false"
      (overlayOutsideClick)="plusDropdownVisible = getOverlayVisibilityAfterOutsideClick($event, plusDropdownTrigger)"
      [cdkConnectedOverlayOpen]="plusDropdownVisible"
      [cdkConnectedOverlayOrigin]="plusDropdownTrigger"
      [cdkConnectedOverlayPositions]="dropdownPositionStrategy"
      cdkConnectedOverlay
    >
      <cc-dropdown [shadow]="false">
        @if (composer.messageType !== 'new') {
          <cc-dropdown-action
            [disabled]="newTaskCreationDisabled"
            leadingIcon="icon-create-task"
          >
            <ng-container slot="title">
              {{ 'conversations-v4.composer.actions.more.createNewTask' | translate }}
            </ng-container>
          </cc-dropdown-action>

          <cc-dropdown-divider></cc-dropdown-divider>
        }

        <cc-dropdown-text>
          <ng-container slot="title">
            {{ 'conversations-v4.composer.actions.more.attachments' | translate }}
          </ng-container>
        </cc-dropdown-text>

        <cc-dropdown-action
          (click)="presentTaskEmbedDialog(); plusDropdownVisible = false"
          leadingIcon="icon-tasks"
        >
          <ng-container slot="title">
            {{ 'conversations-v4.composer.actions.more.task' | translate }}
          </ng-container>
        </cc-dropdown-action>

        <cc-dropdown-action
          (click)="presentBusinessObjectEmbedDialog(); plusDropdownVisible = false"
          leadingIcon="icon-link"
        >
          <ng-container slot="title">
            {{ 'conversations-v4.composer.actions.more.bpi' | translate }}
          </ng-container>
        </cc-dropdown-action>

        @if (composer.messageType !== 'new') {
          <cc-dropdown-action
            (click)="attachFileFromCloverStorage(); plusDropdownVisible = false"
            leadingIcon="icon-files"
          >
            <ng-container slot="title">
              {{ 'conversations-v4.composer.actions.more.cloverFileStorage' | translate }}
            </ng-container>
          </cc-dropdown-action>

          <cc-dropdown-action
            (click)="fileUploader.click(); plusDropdownVisible = false"
            leadingIcon="icon-upload-file"
          >
            <ng-container slot="title">
              {{ 'conversations-v4.composer.actions.more.uploadFromComputer' | translate }}
            </ng-container>
          </cc-dropdown-action>
        }
      </cc-dropdown>
    </ng-template>

    <div class="composer-actions__group-divider"></div>

    <div class="composer-actions__group-formatting-actions">
      <app-button
        (click)="insertEmoji()"
        [ccTooltip]="'conversations-v4.composer.actions.emoji' | translate"
        [size]="ButtonSize.Small"
        [type]="ButtonType.Ghost"
      >
        <i
          class="icon-emoji"
          slot="ghost-icon"
        ></i>
      </app-button>

      <app-button
        (click)="toggleMark('bold')"
        [active]="bold"
        [ccTooltip]="'conversations-v4.composer.actions.bold' | translate"
        [size]="ButtonSize.Small"
        [type]="ButtonType.Ghost"
      >
        <i
          class="icon-bold"
          slot="ghost-icon"
        ></i>
      </app-button>

      <app-button
        (click)="toggleMark('italic')"
        [active]="italic"
        [ccTooltip]="'conversations-v4.composer.actions.italic' | translate"
        [size]="ButtonSize.Small"
        [type]="ButtonType.Ghost"
      >
        <i
          class="icon-italic"
          slot="ghost-icon"
        ></i>
      </app-button>

      <app-button
        (click)="toggleMark('underline')"
        [active]="underline"
        [ccTooltip]="'conversations-v4.composer.actions.underline' | translate"
        [size]="ButtonSize.Small"
        [type]="ButtonType.Ghost"
      >
        <i
          class="icon-underline"
          slot="ghost-icon"
        ></i>
      </app-button>

      <app-button
        (click)="toggleMark('strike')"
        [active]="strikethrough"
        [ccTooltip]="'conversations-v4.composer.actions.strikethrough' | translate"
        [size]="ButtonSize.Small"
        [type]="ButtonType.Ghost"
      >
        <i
          class="icon-strikethrough"
          slot="ghost-icon"
        ></i>
      </app-button>

      <app-button
        (click)="toggleMark('code')"
        [active]="code"
        [ccTooltip]="'conversations-v4.composer.actions.code' | translate"
        [size]="ButtonSize.Small"
        [type]="ButtonType.Ghost"
      >
        <i
          class="icon-code"
          slot="ghost-icon"
        ></i>
      </app-button>

      <app-button
        (click)="link ? toggleMark('link') : presentLinkEditor('insert')"
        [active]="link"
        [ccTooltip]="
          link
            ? ('conversations-v4.composer.actions.removeLink' | translate)
            : ('conversations-v4.composer.actions.insertLink' | translate)
        "
        [size]="ButtonSize.Small"
        [type]="ButtonType.Ghost"
      >
        <i
          class="icon-link"
          slot="ghost-icon"
        ></i>
      </app-button>

      <cc-composer-actions-table-editor
        [editor]="activeEditor"
        [selectionUpdate$]="editorSelectionUpdate$"
      />
    </div>

    @if (composer.draft) {
      <div class="composer-actions__group-divider"></div>
      <app-button
        (click)="deleteDraft()"
        [size]="ButtonSize.Small"
        [type]="ButtonType.Ghost"
        [ccTooltip]="'conversations-v4.composer.actions.deleteDraft' | translate"
      >
        <i
          class="icon-delete"
          slot="ghost-icon"
        ></i>
      </app-button>

      <app-button
        (click)="createNewDraft()"
        [size]="ButtonSize.Small"
        [type]="ButtonType.Ghost"
        [ccTooltip]="'conversations-v4.composer.actions.newDraft' | translate"
      >
        <i
          class="icon-pencil-plus"
          slot="ghost-icon"
        ></i>
      </app-button>
    }
  </div>

  <div
    [class.composer-actions__draft-status--saved]="composer.draftSaveStatus === 'saved'"
    class="composer-actions__draft-status"
  >
    @if (composer.draftSaveStatus === 'saving') {
      {{ 'conversations-v4.composer.saving' | translate }}
    }
    @if (composer.draftSaveStatus === 'saved') {
      @if (draftSavedJustNow) {
        {{ 'conversations-v4.composer.savedJustNow' | translate }}
      } @else {
        {{
          'conversations-v4.composer.savedAgo'
            | translate
              : {
                  ago: composer.draft.updatedAt | ccDateFormatDistance: { strict: true },
                }
        }}
      }
    }
  </div>

  <app-button
    (click)="send()"
    [disabled]="sendDisabled"
    [size]="ButtonSize.Small"
    [type]="ButtonType.Primary"
  >
    {{ 'conversations-v4.composer.send' | translate }}
  </app-button>
</div>

<input
  #fileUploader
  (change)="uploadFile($event)"
  [multiple]="false"
  style="display: none"
  type="file"
/>
