<div class="link-info">
  <app-button
    (click)="dismiss.emit()"
    [ccTooltip]="'common.buttons.close' | translate"
    [size]="ButtonSize.Small"
    [type]="ButtonType.Ghost"
    class="link-info__close-button"
  >
    <i
      class="icon-close"
      slot="ghost-icon"
    ></i>
  </app-button>

  <div class="link-info__body">
    <div class="link-info__body-title">{{ title }}</div>
    <a
      [href]="url"
      class="link-info__body-url"
      target="_blank"
    >
      {{ url }}
    </a>
  </div>

  <div class="link-info__actions">
    <app-button
      (click)="edit.emit()"
      [size]="ButtonSize.Small"
      [type]="ButtonType.Secondary"
    >
      {{ 'common.buttons.edit' | translate }}
    </app-button>

    <app-button
      (click)="delete.emit()"
      [size]="ButtonSize.Small"
      [type]="ButtonType.Danger"
    >
      {{ 'common.buttons.delete' | translate }}
    </app-button>
  </div>

  <div class="link-info__arrow">
    <div class="link-info__arrow-inner"></div>
  </div>
</div>
