import { ChangeDetectionStrategy, Component, computed, inject, input, ViewEncapsulation } from '@angular/core';
import { DomSanitizer } from '@angular/platform-browser';
import { SignaturesService } from '@clover/conversations-v4/workspaces/state/signatures/signatures.service';
import { sanitize } from '@core/helpers/sanitize-email/sanitizer';
import isHTMLEmpty from '@design/forms/rich-text-editor/helpers/is-empty';
import { TranslateModule } from '@ngx-translate/core';
import { NgScrollbarModule } from 'ngx-scrollbar';

@Component({
  selector: 'cc-signature-preview',
  standalone: true,
  imports: [NgScrollbarModule, TranslateModule],
  templateUrl: './signature-preview.component.html',
  styleUrl: './signature-preview.component.scss',
  changeDetection: ChangeDetectionStrategy.OnPush,
  encapsulation: ViewEncapsulation.ShadowDom,
})
export class SignaturePreviewComponent {
  rawContent = input.required<string>();

  protected readonly personalizedContent = computed(() => {
    return this.signaturesService.getPersonalizedSignature(this.rawContent());
  });

  protected readonly safeHtmlContent = computed(() => {
    const content = this.personalizedContent();

    const sanitizedContent = sanitize(content, null, {
      id: 'signature-content',
    });

    return this.domSanitizer.bypassSecurityTrustHtml(sanitizedContent);
  });

  protected readonly contentEmpty = computed(() => isHTMLEmpty(this.personalizedContent()));

  private readonly signaturesService = inject(SignaturesService);
  private readonly domSanitizer = inject(DomSanitizer);
}
