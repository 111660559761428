import type { ContactType } from '@clover/conversations-v4/conversation/state/contacts/contacts.model';
import { EMPTY_PAGING, PagingWrapper } from '@core/helpers/paging';

export interface ConversationsStateModel {
  conversations: PagingWrapper<Conversation>;
  loadingStatus: 'void' | 'loading' | 'loaded' | 'error' | 'loading-next';
}

export const defaultConversationsState: ConversationsStateModel = {
  conversations: EMPTY_PAGING,
  loadingStatus: 'void',
};

export interface Conversation {
  id: string;
  workspaceId: number;
  externalAccountId: number;
  subject: string | undefined;
  initialSender: ConversationPerformer;
  latestMessage: ConversationLastMessage;
  unreadMessages: number;
  status: ConversationStatus;
  labelIds: number[];
  hasAttachments: boolean;
  prioritized: boolean;
  snoozedUntil: string | undefined;
  updatedAt: string;
}

export interface ConversationLastMessage {
  sender: ConversationPerformer;
  messageSnippet: string;
  draft: boolean;
  createdAt: string;
  updatedAt: string;
}

export interface ConversationPerformer {
  id: string;
  name: string;
  avatarUrl: string | undefined;
  type: ContactType;
}

export enum ConversationStatus {
  Open = 'Open',
  Closed = 'Closed',
}

export enum ConversationSource {
  Email = 'email',
}
