import { ICompanyWikiDocument } from '@network/company/state/wiki/wiki.entities';

export const DEFAULT_DOCUMENT: ICompanyWikiDocument = {
  id: null,
  author: {
    name: 'Darius Smith',
    logoUrl: '',
  },
  children: [],
  createdAt: '2021-09-01T00:00:00',
  markdownContent: `
     ## Welcome to your new Partner Wiki!

     Your wiki is visible to any or your organization's connected companies. It's fully editable, full-featured wiki! Here's some of the ways you can use it:

     1. **FAQs** - Got questions that keep popping up? Add them here with clear answers.
     2. **How-To Guides** - Create step-by-step guides for things like ordering, shipping, and returns.
     3. **Product Info** - Share specs, pricing, images, and videos of your products. Keep suppliers in the loop about new launches and updates.
     4. **Onboarding** - Build a workflow for new suppliers to walk them through everything they need to know about working with you, and collection the information you need to work with them. Then, link the workflow here and direct your suppliers to it.
     5. **Marketing Stuff** - Share your brand guidelines, logos, and templates for joint marketing campaigns.
     6. **Important Links & Contacts** - Put all your key links and contacts in one place for easy access.
     7. **News & Updates** - Keep everyone informed about policy changes, events, and other important news.

     You can create an unlimited number of pages of richly format text, and includes links to [internal]() and [external]() content, emojis 😄, images, and more. You can also create sub-pages to keep things organized.

     Have an existing document you'd like to load? Contact your contact manager, we can help with that :-)
  `,
  name: 'Welcome to your new Partner Wiki',
  companyId: 0,
  parentPageId: 0,
  path: [
    {
      id: 0,
      title: 'Welcome to your new Partner Wiki',
    },
  ],
  updatedAt: '2021-09-01T00:00:00',
  updatedBy: 0,
  createdBy: 0,
};
