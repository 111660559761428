import { ChangeDetectionStrategy, Component, Input } from '@angular/core';
import { Task } from '@conversations/tasks/tasks.model';

@Component({
  selector: 'cc-task-drawer-results',
  standalone: true,
  templateUrl: './task-drawer-results.component.html',
  styleUrls: ['./task-drawer-results.component.scss'],
  changeDetection: ChangeDetectionStrategy.OnPush,
})
export class TaskDrawerResultsComponent {
  @Input()
  task: Task;
}
