import {
  ChangeDetectionStrategy,
  Component,
  inject,
  Input,
  OnChanges,
  OnDestroy,
  OnInit,
  output,
  SimpleChanges,
  ViewEncapsulation,
} from '@angular/core';
import { TaskAutocompleteService } from '@conversations/composer/composer-message-input/task-autocomplete/task-autocomplete.service';
import { SaveDraft, SetMessage } from '@conversations/composer/state/composers/composers.actions';
import { UntilDestroy, untilDestroyed } from '@ngneat/until-destroy';
import { TranslateService } from '@ngx-translate/core';
import { Store } from '@ngxs/store';
import { Editor } from '@tiptap/core';
import { History } from '@tiptap/extension-history';
import { Placeholder } from '@tiptap/extension-placeholder';
import { Typography } from '@tiptap/extension-typography';
import { NgxTiptapModule } from 'ngx-tiptap';
import { BehaviorSubject, filter } from 'rxjs';
import { debounceTime, switchMap, tap } from 'rxjs/operators';
import { BaseKit } from './kits/base-kit';
import { MarksKit } from './kits/marks-kit';
import { TableKit } from './kits/table-kit';

@UntilDestroy()
@Component({
  selector: 'cc-composer-message-input',
  standalone: true,
  templateUrl: './composer-message-input.component.html',
  styleUrls: ['./composer-message-input.component.scss'],
  changeDetection: ChangeDetectionStrategy.OnPush,
  imports: [NgxTiptapModule],
  encapsulation: ViewEncapsulation.ShadowDom,
})
export class ComposerMessageInputComponent implements OnInit, OnChanges, OnDestroy {
  @Input()
  composerId: string;

  @Input()
  message: string;

  focusIn = output<void>();

  editor: Editor;

  updatedLocally = false;

  private readonly store = inject(Store);
  private readonly taskAutocompleteService = inject(TaskAutocompleteService);
  private readonly translate = inject(TranslateService);

  ngOnInit(): void {
    const message$ = new BehaviorSubject<string>(this.message);

    this.editor = new Editor({
      content: this.message,
      parseOptions: {
        preserveWhitespace: true,
      },
      extensions: [
        BaseKit,
        MarksKit,
        TableKit,
        Placeholder.configure({
          placeholder: this.translate.instant('conversations-v4.composer.input.placeholder'),
        }),
        History,
        Typography,
        this.taskAutocompleteService.TaskReference,
      ],

      onUpdate: ({ editor }) => {
        message$.next(editor.getHTML());
        this.updatedLocally = true;
      },
      onFocus: () => this.focusIn.emit(),
    });

    message$
      .pipe(
        untilDestroyed(this),
        filter((message) => message !== this.message),
        tap((message) => {
          this.store.dispatch(new SetMessage(this.composerId, message));
        }),
        debounceTime(300),
        switchMap(() => this.store.dispatch(new SaveDraft(this.composerId))),
      )
      .subscribe();
  }

  ngOnChanges(changes: SimpleChanges): void {
    if (changes['message']) this.updateEditorContent();
  }

  updateEditorContent(): void {
    if (!this.editor) return;

    if (this.updatedLocally) {
      this.updatedLocally = false;
      return;
    }

    this.editor.commands.setContent(this.message, false, {
      preserveWhitespace: true,
    });
  }

  ngOnDestroy(): void {
    this.editor.destroy();
  }
}
