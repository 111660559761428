import { ChangeDetectionStrategy, ChangeDetectorRef, Component, inject, Input } from '@angular/core';
import { DropdownComponent } from '@design/overlays/dropdown/dropdown.component';
import { DropdownActionComponent } from '@design/overlays/dropdown/dropdown-action/dropdown-action.component';
import { TaskNodeAttributes } from '@conversations/composer/composer-message-input/task-autocomplete/task-autocomplete.service';

@Component({
  selector: 'cc-task-autocomplete',
  standalone: true,
  imports: [DropdownComponent, DropdownActionComponent],
  templateUrl: './task-autocomplete.component.html',
  changeDetection: ChangeDetectionStrategy.OnPush,
})
export class TaskAutocompleteComponent {
  @Input()
  items: TaskNodeAttributes[] = [];

  @Input()
  // eslint-disable-next-line @typescript-eslint/ban-types
  accept: Function;

  private readonly cdr = inject(ChangeDetectorRef);

  updateItems(items: TaskNodeAttributes[]): void {
    this.items = items;
    this.cdr.detectChanges();
  }
}
