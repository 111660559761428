import { inject, Injectable } from '@angular/core';
import { generateGenericAvatar } from '@clover/core/helpers/generateGenericAvatar';
import { HttpService } from '@clover/core/services/http.service';
import { UserService } from '@clover/core/services/user.service';
import { map, type Observable } from 'rxjs';
import type { Signature, SignatureDefaults, SignaturePreview } from './signatures-state.model';

interface SignatureResponse {
  id: number;
  name: string;
  workspaceId: number;
  content: string;
}

type SignaturePreviewResponse = Pick<SignatureResponse, 'id' | 'name' | 'workspaceId'>;

interface SignatureDefaultsResponse {
  workspaceId: number;
  newEmailSignature: SignaturePreviewResponse | undefined;
  replyEmailSignature: SignaturePreviewResponse | undefined;
}

function mapSignaturePreview(s: SignaturePreviewResponse): SignaturePreview {
  return {
    id: s.id,
    name: s.name,
    workspaceId: s.workspaceId,
  };
}

function mapSignature(s: SignatureResponse): Signature {
  return {
    ...mapSignaturePreview(s),
    content: s.content,
  };
}

function mapSignatureDefaults(s: SignatureDefaultsResponse): SignatureDefaults {
  return {
    workspaceId: s.workspaceId,
    newEmailSignatureId: s.newEmailSignature?.id,
    replyEmailSignatureId: s.replyEmailSignature?.id,
  };
}

@Injectable({
  providedIn: 'root',
})
export class SignaturesService {
  private readonly http = inject(HttpService);
  private readonly userService = inject(UserService);

  getWorkspaceSignatures(workspaceId: number): Observable<SignaturePreview[]> {
    return this.http
      .getV2<SignaturePreviewResponse[]>(`/api/workspaces/${workspaceId}/signatures`)
      .pipe(map((signatures) => signatures.map(mapSignaturePreview)));
  }

  getWorkspaceSignature(workspaceId: number, signatureId: number): Observable<Signature> {
    return this.http
      .getV2<SignatureResponse>(`/api/workspaces/${workspaceId}/signatures/${signatureId}`)
      .pipe(map(mapSignature));
  }

  createSignature(workspaceId: number, signature: Pick<Signature, 'name' | 'content'>): Observable<Signature> {
    return this.http
      .postV2<SignatureResponse>(`/api/workspaces/${workspaceId}/signatures`, {
        name: signature.name,
        content: signature.content,
      })
      .pipe(map(mapSignature));
  }

  updateSignature(
    workspaceId: number,
    signatureId: number,
    signature: Pick<Signature, 'name' | 'content'>,
  ): Observable<Signature> {
    return this.http
      .putV2<SignatureResponse>(`/api/workspaces/${workspaceId}/signatures/${signatureId}`, {
        name: signature.name,
        content: signature.content,
      })
      .pipe(map(mapSignature));
  }

  deleteSignature(workspaceId: number, signatureId: number): Observable<void> {
    return this.http.deleteV2(`/api/workspaces/${workspaceId}/signatures/${signatureId}`);
  }

  getSignatureDefaults(workspaceId: number): Observable<SignatureDefaults> {
    return this.http
      .getV2<SignatureDefaultsResponse>(`/api/workspaces/${workspaceId}/signatures/defaults`)
      .pipe(map(mapSignatureDefaults));
  }

  updateSignatureDefaults(
    workspaceId: number,
    defaults: Pick<SignatureDefaults, 'newEmailSignatureId' | 'replyEmailSignatureId'>,
  ): Observable<void> {
    return this.http.postV2(`/api/workspaces/${workspaceId}/signatures/defaults/set`, {
      newEmailSignatureId: defaults.newEmailSignatureId ?? null,
      replyEmailSignatureId: defaults.replyEmailSignatureId ?? null,
    });
  }

  getPersonalizedSignature(rawContent: string): string {
    const user = this.userService.userProfile;

    const placeholdersMap = new Map<string, string>([
      [
        'profile_logo',
        `<img src="${user.logoUrl || generateGenericAvatar(user.firstName || '–', user.lastName, 128)}" alt="${user.fullName}" />`,
      ],
      ['first_name', user.firstName || ''],
      ['last_name', user.lastName || ''],
      ['full_name', user.fullName || ''],
      ['title', user.title || ''],
      ['company_name', user.companyName || ''],
      ['work_email', user.workEmail || ''],
      ['work_phone_number', user.workPhoneNumber || ''],
    ]);

    return rawContent.replace(/\[\[([^\]]+)\]\]/g, (match, token) => {
      return placeholdersMap.get(token.trim()) ?? match;
    });
  }
}
