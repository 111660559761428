import {
  Component,
  ElementRef,
  inject,
  Input,
  type OnChanges,
  OnInit,
  type SimpleChanges,
  TemplateRef,
  ViewChild,
  ViewContainerRef,
} from '@angular/core';
import { CommonModule } from '@angular/common';
import { Store } from '@ngxs/store';
import {
  AddSubPage,
  DeleteDocument,
  EnterEditMode,
  MovePage,
  SelectDocument,
  SelectDocumentByPath,
} from '@network/company/state/wiki/wiki.actions';

import { ButtonSize, ButtonType } from '@design/buttons/button/types';

import { MarkdownModule } from 'ngx-markdown';
import { UntilDestroy } from '@ngneat/until-destroy';
import { getOverlayVisibilityAfterOutsideClick } from '@core/helpers/get-overlay-visibility-after-outside-click';

import { type ConnectionPositionPair, OverlayModule, type OverlayRef } from '@angular/cdk/overlay';

import { CdkPortalService } from '@core/services/cdk-portal.service';
import { MovePageComponent } from '@network/company/company-wiki/move-page/move-page.component';
import { ICompanyWikiDocument } from '@network/company/state/wiki/wiki.entities';
import { UserService } from '@core/services/user.service';
import { ButtonComponent } from '@design/buttons/button/button.component';
import { TooltipDirective } from '@design/overlays/tooltip/tooltip.directive';
import { DropdownComponent } from '@design/overlays/dropdown/dropdown.component';
import { DropdownActionComponent } from '@design/overlays/dropdown/dropdown-action/dropdown-action.component';
import {
  ConfirmationDialogAppearance,
  ConfirmationDialogComponent,
  ConfirmationDialogData,
  ConfirmationDialogResult,
} from '@design/overlays/confirmation-dialog/confirmation-dialog.component';
import { Dialog, DialogModule } from '@angular/cdk/dialog';
import { map, take } from 'rxjs/operators';
import { WikiSelectors } from '@network/company/state/wiki/wiki.selectors';
import { Observable } from 'rxjs';

@UntilDestroy()
@Component({
  selector: 'cc-company-wiki-document-preview',
  standalone: true,
  imports: [
    CommonModule,
    MarkdownModule,
    OverlayModule,
    ButtonComponent,
    TooltipDirective,
    MovePageComponent,
    DropdownComponent,
    DropdownActionComponent,
    DialogModule,
  ],
  templateUrl: './company-wiki-document-preview.component.html',
  styleUrls: ['./company-wiki-document-preview.component.scss'],
})
export class ICompanyWikiDocumentPreviewComponent implements OnInit, OnChanges {
  @Input()
  document: ICompanyWikiDocument;

  @ViewChild('scrollableArea', { static: true, read: ElementRef })
  scrollableArea: ElementRef;

  @ViewChild('movePageModal', { static: true })
  movePageModal: TemplateRef<MovePageComponent>;

  canEdit$: Observable<boolean>;

  protected isActionsDropdownVisible = false;
  protected actionsDropdownPositionStrategy: ConnectionPositionPair[] = [
    {
      offsetX: 0,
      offsetY: 4,
      originX: 'end',
      originY: 'bottom',
      overlayX: 'end',
      overlayY: 'top',
      panelClass: null,
    },
  ];
  protected readonly getOverlayVisibilityAfterOutsideClick = getOverlayVisibilityAfterOutsideClick;
  protected readonly ButtonType = ButtonType;
  protected readonly ButtonSize = ButtonSize;

  private modalOverlayRef: OverlayRef | null = null;

  private readonly store = inject(Store);
  private readonly _userService = inject(UserService);
  author = this._userService.userProfile;
  private readonly cdkPortalService = inject(CdkPortalService);
  private readonly viewContainerRef = inject(ViewContainerRef);
  private readonly userService = inject(UserService);
  private readonly dialog = inject(Dialog);

  ngOnInit() {
    this.canEdit$ = this.store.select(WikiSelectors.companyId).pipe(
      map((companyId) => {
        if (!this.userService.permissions.Company_ResourcePage_Manage) return false;

        const myCompanyId = this.userService.userCompany.id.toString();
        return companyId === myCompanyId;
      }),
    );
  }

  async ngOnChanges(changes: SimpleChanges) {
    if (changes['document']) {
      this.scrollableArea.nativeElement.scrollTop = 0;
    }
  }

  navigateToDocument(id: number): void {
    this.store.dispatch(new SelectDocument(id));
  }

  interceptMarkdownLinkClick(event: MouseEvent) {
    // TODO (Oleksandr D.): Implement proper handling of markdown links

    const target = event.target as HTMLElement;

    if (target.tagName === 'A') {
      const href = target.getAttribute('href');

      if (href && href.includes('://')) return;
      if (href && !href.endsWith('.md')) return;

      event.preventDefault();

      const path = href.replace('.md', '').split('/').map(decodeURIComponent);
      this.store.dispatch(new SelectDocumentByPath(path));
    }
  }

  enterEditMode(): void {
    this.store.dispatch(new EnterEditMode());
  }

  deleteDocument(): void {
    const dialog = this.dialog.open<ConfirmationDialogResult, ConfirmationDialogData>(ConfirmationDialogComponent, {
      data: {
        title: 'Are you sure you want to delete page?',
        message: `${this.document['title']} page will be removed from resources and never restored.`,
        confirmText: 'Delete',
        cancelText: 'Cancel',
        destructive: true,
        style: ConfirmationDialogAppearance.Default,
      },
    });

    dialog.closed.pipe(take(1)).subscribe((result: ConfirmationDialogResult | undefined) => {
      if (result !== 'confirm') return;
      this.store.dispatch(new DeleteDocument());
    });
  }

  closeModal(): void {
    if (!this.modalOverlayRef) return;
    this.modalOverlayRef.dispose();
    this.modalOverlayRef.detach();
  }

  addSubPage(): void {
    this.store.dispatch(new AddSubPage());
  }

  openMovePage() {
    this.closeModal();

    this.modalOverlayRef = this.cdkPortalService.openPortal({
      containerRef: this.viewContainerRef,
      hasBackdrop: true,
      templateRef: this.movePageModal,
    });
  }

  movePage(id: number): void {
    this.closeModal();
    this.store.dispatch(new MovePage(id));
  }
}
