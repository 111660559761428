<div class="composer-actions-link-editor-dialog">
  <div class="composer-actions-link-editor-dialog__header">
    <div class="composer-actions-link-editor-dialog__header-title">
      @switch (dialogData.mode) {
        @case ('insert') {
          {{ 'conversations-v4.composer.linkEditor.insertLink' | translate }}
        }
        @case ('edit') {
          {{ 'conversations-v4.composer.linkEditor.editLink' | translate }}
        }
      }
    </div>

    <div class="composer-actions-link-editor-dialog__header-close"></div>

    <app-button
      (click)="dialogRef.close()"
      [ccTooltip]="'common.buttons.close' | translate"
      [size]="ButtonSize.Small"
      [type]="ButtonType.Ghost"
    >
      <i
        class="icon-close"
        slot="ghost-icon"
      ></i>
    </app-button>
  </div>

  <div
    [formGroup]="linkForm"
    class="composer-actions-link-editor-dialog__body"
  >
    <app-textbox
      [label]="'conversations-v4.composer.linkEditor.linkTitle' | translate"
      [placeholder]="'conversations-v4.composer.linkEditor.linkTitlePlaceholder' | translate"
      formControlName="title"
      width="100%"
    >
    </app-textbox>

    <app-textbox
      [errorMessage]="linkErrorMessage"
      [label]="'conversations-v4.composer.linkEditor.linkUrl' | translate"
      [placeholder]="'conversations-v4.composer.linkEditor.linkUrlPlaceholder' | translate"
      [type]="TextboxType.Url"
      formControlName="link"
      width="100%"
    >
    </app-textbox>
  </div>

  <div class="composer-actions-link-editor-dialog__footer">
    <app-button
      (click)="dialogRef.close()"
      [size]="ButtonSize.Small"
      [type]="ButtonType.Secondary"
    >
      {{ 'common.buttons.cancel' | translate }}
    </app-button>

    <app-button
      (click)="submit()"
      [disabled]="linkForm.invalid"
      [size]="ButtonSize.Small"
      [type]="ButtonType.Primary"
    >
      {{ 'common.buttons.ok' | translate }}
    </app-button>
  </div>
</div>
