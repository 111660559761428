import { ChangeDetectionStrategy, Component, inject, input } from '@angular/core';
import { ReactiveFormsModule, type FormControl } from '@angular/forms';
import { RichTextEditorBoldControlComponent } from '@design/forms/rich-text-editor/extensions/bold/bold-control.component';
import { withBold } from '@design/forms/rich-text-editor/extensions/bold/bold.extension';
import { RichTextEditorBulletListControlComponent } from '@design/forms/rich-text-editor/extensions/bullet-list/bullet-list-control.component';
import { withBulletList } from '@design/forms/rich-text-editor/extensions/bullet-list/bullet-list.extension';
import { createExtensionKit } from '@design/forms/rich-text-editor/extensions/create-extension-kit';
import { RichTextEditorFontFamilyControlComponent } from '@design/forms/rich-text-editor/extensions/font-family/font-family-control.component';
import { withFontFamily } from '@design/forms/rich-text-editor/extensions/font-family/font-family.extension';
import { RichTextEditorFontSizeControlComponent } from '@design/forms/rich-text-editor/extensions/font-size/font-size-control.component';
import { withFontSize } from '@design/forms/rich-text-editor/extensions/font-size/font-size.extension';
import { RichTextEditorItalicControlComponent } from '@design/forms/rich-text-editor/extensions/italic/italic-control.component';
import { withItalic } from '@design/forms/rich-text-editor/extensions/italic/italic.extension';
import { withLink } from '@design/forms/rich-text-editor/extensions/link/link.extension';
import { RichTextEditorOrderedListControlComponent } from '@design/forms/rich-text-editor/extensions/ordered-list/ordered-list-control.component';
import { withOrderedList } from '@design/forms/rich-text-editor/extensions/ordered-list/ordered-list.extension';
import { withPlaceholder } from '@design/forms/rich-text-editor/extensions/placeholder/placeholder.extension';
import { RichTextEditorStrikeControlComponent } from '@design/forms/rich-text-editor/extensions/strike/strike-control.component';
import { withStrike } from '@design/forms/rich-text-editor/extensions/strike/strike.extension';
import { RichTextEditorTableControlComponent } from '@design/forms/rich-text-editor/extensions/table/table-control.component';
import { withTable } from '@design/forms/rich-text-editor/extensions/table/table.extension';
import { RichTextEditorUnderlineControlComponent } from '@design/forms/rich-text-editor/extensions/underline/underline-control.component';
import { withUnderline } from '@design/forms/rich-text-editor/extensions/underline/underline.extension';
import { RichTextEditorComponent } from '@design/forms/rich-text-editor/rich-text-editor.component';
import { TranslateService } from '@ngx-translate/core';
import { Editor } from '@tiptap/core';
import { NgScrollbarModule } from 'ngx-scrollbar';

@Component({
  selector: 'cc-signature-editor',
  standalone: true,
  imports: [
    RichTextEditorComponent,
    ReactiveFormsModule,
    NgScrollbarModule,
    RichTextEditorBoldControlComponent,
    RichTextEditorItalicControlComponent,
    RichTextEditorStrikeControlComponent,
    RichTextEditorUnderlineControlComponent,
    RichTextEditorFontFamilyControlComponent,
    RichTextEditorFontSizeControlComponent,
    RichTextEditorBulletListControlComponent,
    RichTextEditorOrderedListControlComponent,
    RichTextEditorTableControlComponent,
  ],
  templateUrl: './signature-editor.component.html',
  styleUrl: './signature-editor.component.scss',
  changeDetection: ChangeDetectionStrategy.OnPush,
})
export class SignatureEditorComponent {
  control = input.required<FormControl<string>>();

  protected readonly editor = new Editor({
    content: '',
    extensions: [
      createExtensionKit(
        withBold(),
        withItalic(),
        withStrike(),
        withUnderline(),
        withLink(),
        withFontFamily(),
        withFontSize(),
        withBulletList(),
        withOrderedList(),
        withTable(),
        withPlaceholder(
          inject(TranslateService).instant('conversations-v4.workspaces.dialogs.signatureEditor.signature.placeholder'),
        ),
      ),
    ],
  });
}
