import { Routes } from '@angular/router';
import { defaultCategory } from '@conversations/conversations/categories';
import { conversationRoutes } from '@conversations/conversation/routes';
import { conversationsResolver } from '@conversations/conversations/conversations.resolver';
import { ConversationsComponent } from '@conversations/conversations/conversations.component';
import { canActivateCategory } from '@conversations/conversations/conversations.guard';

export const conversationsRoutes: Routes = [
  {
    path: ':categoryId',
    component: ConversationsComponent,
    children: conversationRoutes,
    resolve: {
      params: conversationsResolver,
    },
    canActivate: [canActivateCategory],
  },
  {
    path: '',
    pathMatch: 'full',
    redirectTo: defaultCategory,
  },
  {
    path: '**',
    redirectTo: defaultCategory,
  },
];
