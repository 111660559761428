import { Component, inject, Input, type OnInit } from '@angular/core';

import { ButtonSize, ButtonType } from '@design/buttons/button/types';

import { FormControl, ReactiveFormsModule } from '@angular/forms';
import { Store } from '@ngxs/store';
import { LeaveEditMode, SaveDocument, UpdateDocument } from '@network/company/state/wiki/wiki.actions';
import { MarkdownEditorComponent } from '@network/company/company-wiki/company-wiki-document-editor/markdown-editor/markdown-editor.component';
import { UntilDestroy, untilDestroyed } from '@ngneat/until-destroy';
import { ButtonComponent } from '@design/buttons/button/button.component';
import { TextboxComponent } from '@design/forms/textbox/textbox.component';
import { TooltipDirective } from '@design/overlays/tooltip/tooltip.directive';
import {
  ConfirmationDialogAppearance,
  ConfirmationDialogComponent,
  ConfirmationDialogData,
  ConfirmationDialogResult,
} from '@design/overlays/confirmation-dialog/confirmation-dialog.component';
import { take } from 'rxjs/operators';
import { Dialog, DialogModule } from '@angular/cdk/dialog';
import { ICompanyWikiDocument } from '@network/company/state/wiki/wiki.entities';

@UntilDestroy()
@Component({
  selector: 'cc-company-wiki-document-editor',
  standalone: true,
  templateUrl: './company-wiki-document-editor.component.html',
  styleUrls: ['./company-wiki-document-editor.component.scss'],
  imports: [
    ReactiveFormsModule,
    MarkdownEditorComponent,
    ButtonComponent,
    TextboxComponent,
    TooltipDirective,
    DialogModule,
  ],
})
export class ICompanyWikiDocumentEditorComponent implements OnInit {
  @Input()
  document: ICompanyWikiDocument;

  documentTitleFormControl = new FormControl<string>('');

  protected readonly ButtonType = ButtonType;
  protected readonly ButtonSize = ButtonSize;

  private readonly store = inject(Store);
  private readonly dialog = inject(Dialog);

  ngOnInit(): void {
    this.documentTitleFormControl.setValue(this.document.name, { emitEvent: false });

    this.documentTitleFormControl.valueChanges.pipe(untilDestroyed(this)).subscribe((title) => {
      this.store.dispatch(new UpdateDocument({ name: title }));
    });
  }

  leaveEditMode(): void {
    const dialog = this.dialog.open<ConfirmationDialogResult, ConfirmationDialogData>(ConfirmationDialogComponent, {
      data: {
        title: 'Leave edit mode?',
        message: 'All unsaved changes will be lost.',
        confirmText: 'Discard changes',
        cancelText: 'Cancel',
        destructive: true,
        style: ConfirmationDialogAppearance.Default,
      },
    });

    dialog.closed.pipe(take(1)).subscribe((result: ConfirmationDialogResult | undefined) => {
      if (result !== 'confirm') return;
      this.store.dispatch(new LeaveEditMode());
    });
  }

  saveDocument(): void {
    this.store.dispatch(new SaveDocument());
  }
}
