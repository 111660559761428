import { ChangeDetectionStrategy, Component, inject, Input, type OnInit } from '@angular/core';

import { ButtonGroupComponent } from '@design/buttons/button-group/button-group.component';
import { ButtonSize, ButtonType } from '@design/buttons/button/types';
import {
  ConversationDetails,
  ConversationFolder,
} from '@conversations/conversation/state/conversation/conversation-state.model';
import { TooltipAlignment, TooltipPosition } from '@design/overlays/tooltip/tooltip';
import { ConversationStatus } from '@conversations/conversations/state/conversations/conversations-state.model';
import { CoreModule } from '@core/core.module';
import { ResizeDirective, ResizeEvent } from '@core/directives/resize.directive';
import { DropdownComponent } from '@design/overlays/dropdown/dropdown.component';
import { DropdownDividerComponent } from '@design/overlays/dropdown/dropdown-divider/dropdown-divider.component';
import { DropdownActionComponent } from '@design/overlays/dropdown/dropdown-action/dropdown-action.component';
import { ConnectedPosition, OverlayModule } from '@angular/cdk/overlay';
import { getOverlayVisibilityAfterOutsideClick } from '@core/helpers/get-overlay-visibility-after-outside-click';
import { Dialog, DialogModule } from '@angular/cdk/dialog';
import {
  ConfirmationDialogAppearance,
  ConfirmationDialogComponent,
  ConfirmationDialogData,
  ConfirmationDialogResult,
} from '@design/overlays/confirmation-dialog/confirmation-dialog.component';
import { map, take } from 'rxjs/operators';
import {
  getMoveToFolderDialogConfirmTextKey,
  getMoveToFolderDialogMessageKey,
  getMoveToFolderDialogTitleKey,
} from '@conversations/conversation/active-conversation/conversation/conversation-header/conversation-header-actions/conversation-header-actions.helpers';
import { RouterLink } from '@angular/router';
import { LabelPickerComponent } from '@design/overlays/label-picker/label-picker.component';
import { Label } from '@conversations/workspaces/state/labels/labels-state.model';
import { Store } from '@ngxs/store';
import { Navigate } from '@ngxs/router-plugin';
import { CONVERSATIONS_BASE_URL } from '@conversations/routes';
import { WorkspacesSelectors } from '@conversations/workspaces/state/workspaces/workspaces.selectors';
import {
  LabelEditorDialogData,
  LabelEditorDialogResult,
  LabelEditorModalComponent,
} from '@conversations/workspaces/modals/label-editor-modal/label-editor-modal.component';
import { CreateLabel } from '@conversations/workspaces/state/labels/labels.actions';
import {
  ConversationSnoozeService,
  SnoozeOption,
  SnoozeOptionDetails,
} from '@conversations/conversation/state/conversation/conversation-snooze.service';
import { ConversationActionsDistributorService } from '@conversations/conversation/state/conversation/conversation-actions-distributor.service';
import { ConversationHeaderActionsAssigneePickerComponent } from '@conversations/conversation/active-conversation/conversation/conversation-header/conversation-header-actions/conversation-header-actions-assignee-picker/conversation-header-actions-assignee-picker.component';
import { ProviderType, Workspace } from '@conversations/workspaces/state/workspaces/workspaces-state.model';
import { ButtonComponent } from '@design/buttons/button/button.component';
import { TooltipDirective } from '@design/overlays/tooltip/tooltip.directive';
import { DateFormatPipe } from '@core/pipes/date-format.pipe';
import {
  WORKSPACE_SETTINGS_MODAL_OUTLET,
  WorkspaceSettingsModalTab,
} from '@conversations/workspaces/modals/workspace-settings-modal/routes';
import { TranslateModule, TranslateService } from '@ngx-translate/core';
import type { Observable } from 'rxjs';
import { AsyncPipe } from '@angular/common';
import { DropdownTextComponent } from '../../../../../../../stories/overlays/dropdown/dropdown-text/dropdown-text.component';

const compactModeBreakpoint = 560;

@Component({
  selector: 'cc-conversation-header-actions',
  standalone: true,
  imports: [
    ButtonGroupComponent,
    CoreModule,
    DropdownComponent,
    ButtonComponent,
    TooltipDirective,
    DropdownDividerComponent,
    DropdownActionComponent,
    OverlayModule,
    DialogModule,
    RouterLink,
    LabelPickerComponent,
    ConversationHeaderActionsAssigneePickerComponent,
    ResizeDirective,
    DateFormatPipe,
    TranslateModule,
    AsyncPipe,
    DropdownTextComponent,
  ],
  templateUrl: './conversation-header-actions.component.html',
  styleUrls: ['./conversation-header-actions.component.scss'],
  changeDetection: ChangeDetectionStrategy.OnPush,
})
export class ConversationHeaderActionsComponent implements OnInit {
  @Input()
  details: ConversationDetails;

  @Input()
  assignee: boolean;

  @Input()
  accountIssue: 'failed' | 'expired' | 'disconnected' | undefined;

  @Input()
  workspace: Workspace;

  protected compactMode = false;
  protected snoozeDropdownVisible = false;
  protected labelDropdownVisible = false;
  protected moreActionsDropdownVisible = false;

  protected isMicrosoftAccount$: Observable<boolean>;

  protected readonly dropdownPositionStrategy: ConnectedPosition[] = [
    {
      originX: 'end',
      originY: 'bottom',
      overlayX: 'end',
      overlayY: 'top',
      offsetY: 4,
      panelClass: 'conversation-header-actions-dropdown',
    },
  ];

  protected readonly ButtonType = ButtonType;
  protected readonly ButtonSize = ButtonSize;
  protected readonly TooltipPosition = TooltipPosition;
  protected readonly TooltipAlignment = TooltipAlignment;
  protected readonly ConversationStatus = ConversationStatus;
  protected readonly ConversationFolder = ConversationFolder;
  protected readonly SnoozeOption = SnoozeOption;
  protected readonly getOverlayVisibilityAfterOutsideClick = getOverlayVisibilityAfterOutsideClick;

  private readonly conversationActionsService = inject(ConversationActionsDistributorService);
  private readonly conversationSnoozeService = inject(ConversationSnoozeService);
  private readonly dialog = inject(Dialog);
  private readonly store = inject(Store);
  private readonly translate = inject(TranslateService);

  get snoozeOptions(): SnoozeOptionDetails[] {
    return this.conversationSnoozeService.snoozeOptions;
  }

  ngOnInit(): void {
    this.isMicrosoftAccount$ = this.store
      .select(WorkspacesSelectors.externalAccountById(this.details.externalAccountId))
      .pipe(map((account) => account?.providerType === ProviderType.Microsoft));
  }

  toggleStatus(): void {
    const newStatus =
      this.details.status === ConversationStatus.Open ? ConversationStatus.Closed : ConversationStatus.Open;

    this.conversationActionsService.setStatus(this.details.id, newStatus);
  }

  togglePrioritizedStatus(): void {
    const newPrioritizedStatus = !this.details.prioritized;
    this.conversationActionsService.setPrioritizedStatus(this.details.id, newPrioritizedStatus);
  }

  setSnoozeOption(option: SnoozeOption): void {
    this.conversationActionsService.setSnooze(this.details.id, option);
  }

  unsnooze(): void {
    this.conversationActionsService.unsnooze(this.details.id);
  }

  assignLabel(label: Label): void {
    this.conversationActionsService.assignLabel(this.details.externalAccountId, this.details.id, label.id);
  }

  unassignLabel(label: Label): void {
    this.conversationActionsService.unassignLabel(this.details.id, label.id);
  }

  toggleFolder(folder: ConversationFolder): void {
    const currentFolder = this.details.folder;
    const newFolder = currentFolder === folder ? ConversationFolder.Inbox : folder;

    const dialog = this.dialog.open<ConfirmationDialogResult, ConfirmationDialogData>(ConfirmationDialogComponent, {
      data: {
        title: this.translate.instant(getMoveToFolderDialogTitleKey(currentFolder, newFolder)),
        message: this.translate.instant(getMoveToFolderDialogMessageKey(currentFolder, newFolder)),
        confirmText: this.translate.instant(getMoveToFolderDialogConfirmTextKey(newFolder)),
        cancelText: this.translate.instant('common.buttons.cancel'),
        destructive: [ConversationFolder.Spam, ConversationFolder.Trash].includes(newFolder),
        style: ConfirmationDialogAppearance.Compact,
      },
    });

    dialog.closed.pipe(take(1)).subscribe((result: ConfirmationDialogResult | undefined) => {
      if (result !== 'confirm') return;
      this.conversationActionsService.moveToFolder(this.details.id, newFolder);
    });
  }

  createLabel(predefinedName: string): void {
    const dialog = this.dialog.open<LabelEditorDialogResult, LabelEditorDialogData>(LabelEditorModalComponent, {
      data: {
        preselectedWorkspaceId: this.details.workspaceId,
        predefinedName,
      },
    });

    dialog.closed.pipe(take(1)).subscribe((newLabel: LabelEditorDialogResult | undefined) => {
      if (!newLabel) return;
      this.store.dispatch(new CreateLabel(this.details.workspaceId, newLabel));
    });
  }

  openWorkspaceSettingsLabelsTab(): void {
    const workspaceAlias = this.store.selectSnapshot(WorkspacesSelectors.workspaceAliasById(this.details.workspaceId));
    this.store.dispatch(
      new Navigate([
        '/',
        CONVERSATIONS_BASE_URL,
        { outlets: { [WORKSPACE_SETTINGS_MODAL_OUTLET]: [workspaceAlias, WorkspaceSettingsModalTab.Labels] } },
      ]),
    );
  }

  protected updateCompactMode({ newRect: { width } }: ResizeEvent): void {
    this.compactMode = width < compactModeBreakpoint;
  }
}
