import { ICompanyWikiDocument, MockDocumentsType } from '@network/company/state/wiki/wiki.entities';

export class LoadDocuments {
  static readonly type = '[Wiki] Load Documents';

  constructor(
    public companyId: string,
    public companyName?: string,
  ) {}
}

export class LoadMockDocuments {
  static readonly type = '[Wiki] Load Mock Documents';

  constructor(public mockType: MockDocumentsType) {}
}

export class SelectDocument {
  static readonly type = '[Wiki] Select Document';

  constructor(
    public documentId: number,
    public withEdit = false,
  ) {}
}

export class SelectDocumentByPath {
  static readonly type = '[Wiki] Select Document By Path';

  constructor(public path: string[]) {}
}

export class EnterEditMode {
  static readonly type = '[Wiki] Enter Edit Mode';
}

export class LeaveEditMode {
  static readonly type = '[Wiki] Leave Edit Mode';
}

export class SaveDocument {
  static readonly type = '[Wiki] Save Document';
}

export class UpdateDocument {
  static readonly type = '[Wiki] Update Document';

  constructor(public document: Partial<ICompanyWikiDocument>) {}
}

export class DeleteDocument {
  static readonly type = '[Wiki] Delete Document';

  constructor() {}
}

export class LoadDocumentContent {
  static readonly type = '[Wiki] Load Document Content';

  constructor(
    public documentId: number,
    public companyId: string,
  ) {}
}

export class AddSubPage {
  static readonly type = '[Wiki] Add Sub Page';

  constructor() {}
}

export class MovePage {
  static readonly type = '[Wiki] Move Page';

  constructor(public parentPageId: number) {}
}

export class CreatePage {
  static readonly type = '[Wiki] Create Page';
}
