import { Routes } from '@angular/router';
import { ConversationsPageComponent } from '@conversations/conversations-page.component';
import { conversationsRoutes } from '@conversations/conversations/routes';
import { workspacesResolver } from '@conversations/workspaces/workspaces.resolver';
import {
  WORKSPACE_SETTINGS_MODAL_OUTLET,
  workspaceSettingsModalRoutes,
} from '@conversations/workspaces/modals/workspace-settings-modal/routes';
import { provideStates } from '@ngxs/store';
import { WorkspacesState } from '@conversations/workspaces/state/workspaces/workspaces.state';
import { LabelsState } from '@conversations/workspaces/state/labels/labels.state';
import { ConversationsState } from '@conversations/conversations/state/conversations/conversations.state';
import { ConversationState } from '@conversations/conversation/state/conversation/conversation.state';
import { ComposersState } from '@conversations/composer/state/composers/composers.state';
import { TasksService as LegacyTasksService } from '@clover/tasks/tasks.service';
import { NetworkService as LegacyNetworkService } from '@clover/network/network.service';
import { SignaturesState } from './workspaces/state/signatures/signatures.state';

export const CONVERSATIONS_BASE_URL = 'conversations-v4';

export const conversationsPageRoutes: Routes = [
  {
    path: '',
    resolve: {
      workspacesState: workspacesResolver,
    },
    providers: [
      provideStates([
        WorkspacesState,
        LabelsState,
        SignaturesState,
        ConversationsState,
        ConversationState,
        ComposersState,
      ]),
      // TODO (Oleksandr D.): This is part of legacy code, should be rewritten in the future.
      // This service is used inside the legacy task re-route component, which is used inside the legacy modal service
      // inside new standalone task-card component. This is a temporary solution.
      LegacyTasksService,
      LegacyNetworkService,
    ],
    component: ConversationsPageComponent,
    children: [
      {
        path: ':workspaceAlias',
        children: conversationsRoutes,
      },
      {
        path: ':workspaceAlias',
        outlet: WORKSPACE_SETTINGS_MODAL_OUTLET,
        children: workspaceSettingsModalRoutes,
      },
    ],
  },
];
