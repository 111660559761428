import { ChangeDetectionStrategy, Component, inject, Input, OnChanges, SimpleChanges } from '@angular/core';
import { MessageCollapsibleGroupComponent } from '@conversations/conversation/active-conversation/conversation/conversation-messages/message/message-collapsible-group/message-collapsible-group.component';
import { AsyncPipe } from '@angular/common';
import { TasksService } from '@conversations/tasks/tasks.service';
import { BehaviorSubject, Observable } from 'rxjs';
import { TaskPreview } from '@conversations/tasks/tasks.model';
import { catchError, distinctUntilChanged, switchMap, tap } from 'rxjs/operators';
import * as R from 'ramda';
import { TaskCardComponent } from '@conversations/tasks/task-card/task-card.component';
import { TranslateModule } from '@ngx-translate/core';

@Component({
  selector: 'cc-message-task-references',
  standalone: true,
  templateUrl: './message-task-references.component.html',
  styleUrls: ['./message-task-references.component.scss'],
  changeDetection: ChangeDetectionStrategy.OnPush,
  imports: [MessageCollapsibleGroupComponent, AsyncPipe, TaskCardComponent, TranslateModule],
})
export class MessageTaskReferencesComponent implements OnChanges {
  @Input()
  tasksIds: number[] = [];

  tasks$: Observable<TaskPreview[]>;
  tasksLoadingStatus: 'void' | 'loading' | 'loaded' | 'error' = 'void';

  collapsed = true;

  private taskIds$ = new BehaviorSubject<number[]>([]);

  private readonly tasksService = inject(TasksService);

  ngOnChanges(changes: SimpleChanges): void {
    if (changes['tasksIds']) this.taskIds$.next(this.tasksIds);
  }

  handleCollapsibleGroupExpand(): void {
    if (this.tasksLoadingStatus === 'loading') return;
    if (this.tasksLoadingStatus !== 'loaded') return this.loadTasks();
    this.collapsed = false;
  }

  private loadTasks(): void {
    this.tasksLoadingStatus = 'loading';

    this.tasks$ = this.taskIds$.pipe(
      distinctUntilChanged((a, b) => R.equals(a, b)),
      switchMap((taskIds) => {
        this.tasksLoadingStatus = 'loading';

        if (!taskIds.length) return [];
        return this.tasksService.getTasksPreviews(taskIds);
      }),
      tap(() => {
        this.tasksLoadingStatus = 'loaded';
        this.collapsed = false;
      }),
      catchError(() => {
        this.tasksLoadingStatus = 'error';
        return [];
      }),
    );
  }
}
