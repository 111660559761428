import { inject, Injectable } from '@angular/core';
import { HttpService } from '@core/services/http.service';
import { LabelResponse } from '@conversations/workspaces/state/labels/labels.service';
import {
  ConversationPerformer,
  ConversationStatus,
} from '@conversations/conversations/state/conversations/conversations-state.model';
import { ContactType } from '../contacts/contacts.model';
import {
  mapConversationPerformer,
  UserResponse,
} from '@conversations/conversations/state/conversations/conversations.service';
import {
  ConversationDetails,
  ConversationFolder,
  Draft,
  DraftAttachment,
  DraftMessageType,
  EmailParticipant,
  EmailParticipants,
  Message,
  MessageAttachment,
  MessageType,
  CompactMessage,
  SystemMessageType,
  SystemMetadata,
} from '@conversations/conversation/state/conversation/conversation-state.model';
import { Observable } from 'rxjs';
import { map } from 'rxjs/operators';
import { getPagingOptionsParams, PagingOptions, PagingWrapper } from '@core/helpers/paging';
import { Router, UrlSerializer } from '@angular/router';

interface ConversationDetailsResponse {
  id: string;
  name: string;
  workspaceId: number;
  externalAccountId: number;
  lastEmailMessage: MessageResponse;
  lastMessage: MessageResponse;
  lastMessageDraft: DraftResponse;
  tags: LabelResponse[];
  createdAt: string;
  assignee: UserResponse | undefined;
  workspaceMembers: UserResponse[];
  isPrioritized: boolean;
  isSnoozed: boolean;
  status: ConversationStatus;
  snoozedUntil: string | undefined;
  isSpam: boolean;
  isTrash: boolean;
  isInbox: boolean;
  isArchive: boolean;
  isNoReply: boolean;
}

export interface MessageResponse {
  id: string;
  cid: string;
  workspaceId: number;
  externalAccountId: number;
  messageType: MessageType;
  text: string;
  snippet: string;
  sender: UserResponse | undefined;
  attachments: MessageAttachmentResponse[] | undefined;
  attachedTasks: AttachedTaskResponse[] | undefined;
  hasAttachments: boolean;
  metadata: MessageMetadataResponse;
  drafts: DraftResponse[] | undefined;
  replyToMessageInfo: CompactMessageResponse | undefined;
  createdAt: string;
  updatedAt: string;
}

export interface MessageAttachmentResponse {
  fileId: string;
  name: string;
  contentType: string;
  size: number;
}

export interface DraftAttachmentResponse {
  id: number;
  name: string;
  contentType: string;
  size: number;
}

interface AttachedTaskResponse {
  taskId: number;
}

interface MessageMetadataResponse {
  from: EmailParticipantResponse[];
  to: EmailParticipantResponse[];
  cc: EmailParticipantResponse[];
  bcc: EmailParticipantResponse[];
  messageSystemType: SystemMessageType | undefined;
  assigneeUser: UserResponse | undefined;
  performer: UserResponse | undefined;
  members: UserResponse[];
}

interface EmailParticipantResponse {
  name: string;
  email: string;
  logoUrl: string | undefined;
}

export interface DraftResponse {
  id: number;
  workspaceId: number;
  externalAccountId: number;
  streamConversationId: string;
  subject: string;
  content: string;
  quotedContent: string;
  attachments: DraftAttachmentResponse[] | undefined;
  attachedTasks: AttachedTaskResponse[] | undefined;
  to: EmailParticipantResponse[];
  cc: EmailParticipantResponse[];
  bcc: EmailParticipantResponse[];
  type: DraftMessageType;
  replyToMessageInfo: CompactMessageResponse | undefined;
  updatedAt: string;
  createdAt: string;
}

interface CompactMessageResponse {
  streamMessageId: string;
  messageType: MessageType;
  snippet: string;
  metadata: MessageMetadataResponse;
  sender: UserResponse;
  createdAt: string;
}

export type MessagesSortingProperty = 'last_updated' | 'last_message_at' | 'updated_at' | 'created_at';

const systemSender: ConversationPerformer = {
  id: 'system',
  name: 'Clover',
  avatarUrl: 'assets/svg/common/clover.svg',
  type: ContactType.CloverUser,
};

function mapConversationDetails(details: ConversationDetailsResponse): ConversationDetails {
  return {
    id: details.id,
    subject: details.name,
    workspaceId: details.workspaceId,
    externalAccountId: details.externalAccountId,
    labelIds: details.tags.map((tag) => tag.id),
    lastDraft: mapDraft(details.lastMessageDraft),

    // TODO (Oleksandr D.): Remove this mapping chain when BE returns compact message in the response.
    lastMessage: transformMessageToCompactMessage(mapMessage(details.lastMessage)),
    lastReplyableMessage: transformMessageToCompactMessage(mapMessage(details.lastEmailMessage)),

    replyForbidden: details.isNoReply || false,
    folder: mapFolder(details),
    status: details.status,
    assignment: {
      assignee: details.assignee ? mapConversationPerformer(details.assignee) : undefined,
      availableAssignees: details.workspaceMembers.map(mapConversationPerformer),
    },
    prioritized: details.isPrioritized,
    snoozedUntil: details.snoozedUntil,
    createdAt: details.createdAt,
    updatedAt: details.lastMessage.updatedAt,
  };
}

export function mapMessage(message: MessageResponse): Message {
  const conversationId = mapConversationId(message.cid);

  return {
    id: message.id,
    conversationId,
    type: message.messageType,
    content: message.text,
    snippet: message.snippet,
    sender: message.messageType !== MessageType.System ? mapConversationPerformer(message.sender) : systemSender,
    emailParticipants: mapEmailMetadata(message.metadata),
    systemMetadata: message.messageType === MessageType.System ? mapSystemMetadata(message.metadata) : undefined,
    tasksIds: message.attachedTasks?.map((task) => task.taskId) || [],
    attachments: message.hasAttachments ? message.attachments?.map(mapMessageAttachment) || [] : [],
    drafts: message.drafts?.map((draft) => mapDraft(draft, message)) || [],
    replyToMessage: message.replyToMessageInfo
      ? mapCompactMessage(message.replyToMessageInfo, conversationId)
      : undefined,
    createdAt: message.createdAt,
    updatedAt: message.updatedAt,
  };
}

function mapConversationId(cid: string): string {
  return cid.split('messaging:')[1]; // TODO: Remove this when conversationId is available in the response.
}

function mapEmailMetadata(metadata: MessageMetadataResponse): EmailParticipants | undefined {
  if (!metadata) return undefined;

  return {
    from: metadata.from.map(mapParticipant)[0],
    to: metadata.to.map(mapParticipant),
    cc: metadata.cc.map(mapParticipant),
    bcc: metadata.bcc.map(mapParticipant),
  };
}

function mapSystemMetadata(metadata: MessageMetadataResponse): SystemMetadata | undefined {
  if (!metadata?.messageSystemType) return undefined;

  return {
    type: metadata.messageSystemType,
    performer: metadata.performer ? mapConversationPerformer(metadata.performer) : undefined,
    assigneeUser: metadata.assigneeUser ? mapConversationPerformer(metadata.assigneeUser) : undefined,
    members: metadata.members.map(mapConversationPerformer),
  };
}

export function mapDraft(draft: DraftResponse | undefined, baseMessage?: MessageResponse): Draft | undefined {
  if (!draft) return undefined;

  // Removing drafts from base message to avoid circular references.
  baseMessage = {
    ...baseMessage,
    drafts: undefined,
  };

  return {
    id: draft.id,
    conversationId: draft.streamConversationId,
    subject: draft.subject,
    type: draft.type,
    content: draft.content,
    quote: draft.quotedContent,
    emailParticipants:
      draft.type === DraftMessageType.Note
        ? undefined
        : {
            to: draft.to.map(mapParticipant),
            cc: draft.cc.map(mapParticipant),
            bcc: draft.bcc.map(mapParticipant),
          },
    tasksIds: draft.attachedTasks?.map((task) => task.taskId) || [],
    attachments: draft.attachments?.map(mapDraftAttachment) || [],
    replyToMessage: draft.replyToMessageInfo
      ? mapCompactMessage(draft.replyToMessageInfo, draft.streamConversationId)
      : transformMessageToCompactMessage(mapMessage(baseMessage)),
    createdAt: draft.createdAt,
    updatedAt: draft.updatedAt,
  };
}

function mapParticipant(participant: EmailParticipantResponse): EmailParticipant {
  return {
    name: participant.name,
    email: participant.email,
    avatarUrl: participant.logoUrl || undefined,
  };
}

export function mapMessageAttachment(attachment: MessageAttachmentResponse): MessageAttachment {
  return {
    id: attachment.fileId,
    name: attachment.name,
    size: attachment.size,
    mimeType: attachment.contentType,
  };
}

export function mapDraftAttachment(attachment: DraftAttachmentResponse): DraftAttachment {
  return {
    id: attachment.id,
    name: attachment.name,
    size: attachment.size,
    mimeType: attachment.contentType,
  };
}

function mapFolder(details: ConversationDetailsResponse): ConversationFolder {
  if (details.isInbox) {
    return ConversationFolder.Inbox;
  } else if (details.isArchive) {
    return ConversationFolder.Archive;
  } else if (details.isTrash) {
    return ConversationFolder.Trash;
  } else {
    return ConversationFolder.Spam;
  }
}

function mapCompactMessage(
  compactMessage: CompactMessageResponse,
  conversationId: string, // BE cannot provide conversationId in the compact message response, so we need to pass it manually.
): CompactMessage {
  return {
    id: compactMessage.streamMessageId,
    conversationId,
    type: compactMessage.messageType,
    snippet: compactMessage.snippet,
    emailParticipants: mapEmailMetadata(compactMessage.metadata),
    sender: mapConversationPerformer(compactMessage.sender),
    createdAt: compactMessage.createdAt,
  };
}

export function transformMessageToCompactMessage(message: Message): CompactMessage {
  return {
    id: message.id,
    conversationId: message.conversationId,
    type: message.type,
    snippet: message.snippet,
    emailParticipants: message.emailParticipants,
    sender: message.sender,
    createdAt: message.createdAt,
  };
}

@Injectable({
  providedIn: 'root',
})
export class ConversationService {
  private readonly http = inject(HttpService);
  private readonly router = inject(Router);
  private readonly serializer = inject(UrlSerializer);

  getConversationDetails(conversationId: string): Observable<ConversationDetails> {
    return this.http
      .getV2<ConversationDetailsResponse>(`/api/stream-conversations/${conversationId}/details`)
      .pipe(map(mapConversationDetails));
  }

  getMessages(
    conversationId: string,
    pagingOptions?: PagingOptions<MessagesSortingProperty>,
    fromDateIso?: string,
    toDateIso?: string,
  ): Observable<PagingWrapper<Message>> {
    const urlTree = this.router.createUrlTree(['api', 'stream-conversations', conversationId, 'messages', 'search'], {
      queryParams: {
        from: fromDateIso,
        to: toDateIso,
        ...getPagingOptionsParams(pagingOptions),
      },
    });

    const path = this.serializer.serialize(urlTree);
    return this.http
      .getV2<PagingWrapper<MessageResponse>>(path)
      .pipe(map((response) => ({ ...response, data: response.data.map(mapMessage) })));
  }

  getMessage(conversationId: string, messageId: string): Observable<Message> {
    return this.http
      .getV2<MessageResponse>(`/api/stream-conversations/${conversationId}/messages/${messageId}`)
      .pipe(map(mapMessage));
  }
}
