import { Injectable, inject } from '@angular/core';
import { HttpService } from '@clover/core/services/http.service';
import { map, of, type Observable } from 'rxjs';
import {
  ContactCompany,
  ContactSourceType,
  ContactType,
  type Contact,
  type ContactEmail,
  type ContactPhoneNumber,
} from './contacts.model';

interface ContactResponse {
  id: number;
  firstName: string;
  lastName: string;
  pictureUrl: string;
  emails: ContactEmailResponse[];
  phoneNumbers: ContactPhoneResponse[];
  companyId: number | undefined;
  companyName: string | undefined;
  companyLogoUrl: string | undefined;
  jobTitle: string;
  labels: string[];
  source: ContactSourceType;
  contactType: ContactType;
}

interface ContactEmailResponse {
  email: string;
  type: string | undefined;
}

interface ContactPhoneResponse {
  phoneNumber: string;
  type: string | undefined;
}

function mapContact(c: ContactResponse): Contact {
  return {
    id: c.id,
    name: `${c.firstName || ''} ${c.lastName || ''}`.trim(),
    avatarUrl: c.pictureUrl,
    emails: c.emails.map(mapContactEmail),
    phoneNumbers: c.phoneNumbers.map(mapContactPhone),
    company: mapContactCompany(c),
    source: c.source,
    type: c.contactType,
  };
}

function mapContactEmail(e: ContactEmailResponse): ContactEmail {
  return {
    email: e.email,
    type: e.type || undefined,
  };
}

function mapContactPhone(p: ContactPhoneResponse): ContactPhoneNumber {
  return {
    phoneNumber: p.phoneNumber,
    type: p.type || undefined,
  };
}

function mapContactCompany(c: ContactResponse): ContactCompany | undefined {
  if (c.contactType !== ContactType.CloverUser) return;
  if (!c.companyId) return;

  return {
    id: c.companyId,
    name: c.companyName,
    logoUrl: c.companyLogoUrl || undefined,
    title: c.jobTitle || undefined,
  };
}

@Injectable({
  providedIn: 'root',
})
export class ContactsService {
  private readonly http = inject(HttpService);

  getContactById(id: number, type?: ContactType): Observable<Contact | undefined> {
    if (!id) return of(undefined);

    return this.http
      .getV2<ContactResponse>(`/api/stream-conversations/contacts/${id}?type=${type || 'Unknown'}`)
      .pipe(map((c) => (c ? mapContact(c) : undefined)));
  }
}
