import { Action, State, StateContext, Store } from '@ngxs/store';
import { inject, Injectable } from '@angular/core';
import {
  ComposersStateModel,
  defaultComposersState,
  FileUploadError,
} from '@conversations/composer/state/composers/composers-state.model';
import {
  AttachFileFromCloverStorage,
  ChangePresentation,
  ChangeSender,
  CreateDraft,
  DestroyComposer,
  InitActiveConversationComposer,
  InitNewConversationComposer,
  RemoveActiveFileUpload,
  RemoveDraftAttachment,
  ResetComposer,
  SaveDraft,
  SendDraft,
  SendNewEmail,
  SetMessage,
  SetMessageType,
  SetQuote,
  SetRecipients,
  SetSubject,
  UpdateDraft,
  UploadFile,
} from '@conversations/composer/state/composers/composers.actions';
import { ConversationSelectors } from '@conversations/conversation/state/conversation/conversation.selectors';
import {
  composerTypeToDraftMessageType,
  createConversationComposer,
  createNewConversationComposer,
  draftToPendingMessage,
  emptyEditorHtml,
  getInitialEmailRecipients,
} from '@conversations/composer/state/composers/composers-state.helpers';
import { append, insertItem, patch, removeItem, updateItem } from '@ngxs/store/operators';
import { ComposersSelectors } from '@conversations/composer/state/composers/composers.selectors';
import { defer, Observable, of, Subject } from 'rxjs';
import { WorkspacesSelectors } from '@conversations/workspaces/state/workspaces/workspaces.selectors';
import { catchError, map, switchMap, takeUntil, tap } from 'rxjs/operators';
import {
  ComposersService,
  QuoteType,
  SaveDraftPayload,
} from '@conversations/composer/state/composers/composers.service';
import { ComposersActionsDistributorService } from '@conversations/composer/state/composers/composers-actions-distributor.service';
import { v4 as uuid } from 'uuid';
import { HttpEventType } from '@angular/common/http';
import {
  DraftAttachmentResponse,
  mapDraftAttachment,
} from '@conversations/conversation/state/conversation/conversation.service';
import { AddPendingMessage } from '@conversations/conversation/state/conversation/conversation.actions';
import { Draft } from '@conversations/conversation/state/conversation/conversation-state.model';
import { UserService } from '@core/services/user.service';
import { CdkPortalService } from '@core/services/cdk-portal.service';
import { ToastType } from '@design/overlays/toast/toast';
import { TranslateService } from '@ngx-translate/core';

@State<ComposersStateModel>({
  name: 'composers',
  defaults: defaultComposersState,
})
@Injectable()
export class ComposersState {
  private readonly store = inject(Store);
  private readonly composersService = inject(ComposersService);
  private readonly composersActionsService = inject(ComposersActionsDistributorService);
  private readonly userService = inject(UserService);
  private readonly portalService = inject(CdkPortalService);
  private readonly translate = inject(TranslateService);

  @Action(InitActiveConversationComposer)
  initActiveConversationComposer(
    ctx: StateContext<ComposersStateModel>,
    { composerId }: InitActiveConversationComposer,
  ): void {
    const state = ctx.getState();

    // Get active conversation details.
    const activeConversationDetails = this.store.selectSnapshot(ConversationSelectors.details);

    // Prevent creating duplicate composers for the same conversation.
    const existingComposer = state.composers.find(
      (composer) => composer.conversationId === activeConversationDetails.id,
    );
    if (existingComposer) throw new Error(`Composer for conversation ${activeConversationDetails.id} already exists.`);

    // Creating a new composer.
    ctx.setState(
      patch<ComposersStateModel>({
        composers: insertItem(
          createConversationComposer(
            composerId,
            activeConversationDetails,
            activeConversationDetails.lastDraft || undefined,
          ),
        ),
      }),
    );
  }

  @Action(InitNewConversationComposer)
  initNewConversationComposer(
    ctx: StateContext<ComposersStateModel>,
    { composerId, externalAccountId }: InitNewConversationComposer,
  ): Observable<void> {
    // Creating a new composer.
    ctx.setState(
      patch<ComposersStateModel>({
        composers: insertItem(createNewConversationComposer(composerId, externalAccountId)),
      }),
    );

    // Force changing the presentation to overlay-expanded to ensure other overlay composers are collapsed.
    return this.store.dispatch(new ChangePresentation(composerId, 'overlay-expanded'));
  }

  @Action(DestroyComposer)
  destroyComposer(ctx: StateContext<ComposersStateModel>, { composerId }: DestroyComposer): void {
    ctx.setState(
      patch<ComposersStateModel>({
        composers: removeItem((composer) => composer.id === composerId),
      }),
    );
  }

  @Action(ChangePresentation)
  changePresentation(ctx: StateContext<ComposersStateModel>, { composerId, presentation }: ChangePresentation): void {
    const composer = this.store.selectSnapshot(ComposersSelectors.composerById(composerId));
    const activeConversationDetails = this.store.selectSnapshot(ConversationSelectors.details);

    // If we changing the presentation of the composer, which conversation is not active, to inline, we need to destroy it.
    if (
      presentation === 'inline' &&
      (!composer.conversationId || composer.conversationId !== activeConversationDetails?.id)
    ) {
      ctx.dispatch(new DestroyComposer(composerId));
      return;
    }

    // When changing presentation to overlay-expanded, we need to collapse all other overlay composers.
    if (presentation === 'overlay-expanded') {
      ctx.setState(
        patch<ComposersStateModel>({
          composers: updateItem(
            (composer) => composer.presentation === 'overlay-expanded',
            patch({ presentation: 'overlay-collapsed' }),
          ),
        }),
      );
    }

    // Finally, update the composer presentation.
    ctx.setState(
      patch<ComposersStateModel>({
        composers: updateItem(
          (composer) => composer.id === composerId,
          patch({
            presentation,
          }),
        ),
      }),
    );
  }

  @Action(ChangeSender)
  changeSender(ctx: StateContext<ComposersStateModel>, { composerId, senderAccountId }: ChangeSender): void {
    ctx.setState(
      patch<ComposersStateModel>({
        composers: updateItem((composer) => composer.id === composerId, patch({ senderAccountId })),
      }),
    );
  }

  @Action(SaveDraft)
  saveDraft(ctx: StateContext<ComposersStateModel>, { composerId }: SaveDraft): Observable<void> {
    const composer = this.store.selectSnapshot(ComposersSelectors.composerById(composerId));
    if (composer.messageType === 'new') return of(null);

    const existingDraftId = composer.draft?.id || undefined;

    const replyToMessageChanged = composer.draft && composer.replyToMessage.id !== composer.draft.replyToMessage.id;
    if (replyToMessageChanged) this.composersActionsService.removeDraftFromMessage(composer.draft);

    const draftPayload: SaveDraftPayload = {
      externalAccountId: composer.senderAccountId,
      conversationId: composer.conversationId,
      replyToMessageId: composer.replyToMessage.id,
      subject: composer.subject,
      content: composer.message ? composer.message.replace(/&nbsp;/g, ' ') : '',
      quotedContent: composer.quote || '',
      emailParticipants: composer.emailParticipants,
      type: composerTypeToDraftMessageType(composer.messageType),
    };

    if (existingDraftId) return ctx.dispatch(new UpdateDraft(composerId, existingDraftId, draftPayload));
    return ctx.dispatch(new CreateDraft(composerId, draftPayload));
  }

  @Action(CreateDraft)
  createDraft(ctx: StateContext<ComposersStateModel>, { composerId, draftPayload }: CreateDraft): Observable<void> {
    const composer = this.store.selectSnapshot(ComposersSelectors.composerById(composerId));

    if (composer.draftSaveStatus === 'saving') return of(null);

    ctx.setState(
      patch<ComposersStateModel>({
        composers: updateItem((composer) => composer.id === composerId, patch({ draftSaveStatus: 'saving' })),
      }),
    );

    return this.composersService.saveDraft(undefined, draftPayload).pipe(
      tap((draft) => {
        this.composersActionsService.upsertDraft(draft);

        ctx.setState(
          patch<ComposersStateModel>({
            composers: updateItem(
              (composer) => composer.id === composerId,
              patch({
                draft,
                draftSaveStatus: 'saved',
              }),
            ),
          }),
        );
      }),
      switchMap(() => ctx.dispatch(new SaveDraft(composerId))),
      catchError(() => {
        ctx.setState(
          patch<ComposersStateModel>({
            composers: updateItem((composer) => composer.id === composerId, patch({ draftSaveStatus: 'error' })),
          }),
        );
        return of(null);
      }),
      map(() => undefined),
    );
  }

  @Action(UpdateDraft, { cancelUncompleted: true })
  updateDraft(
    ctx: StateContext<ComposersStateModel>,
    { composerId, draftId, draftPayload }: UpdateDraft,
  ): Observable<void> {
    const composer = this.store.selectSnapshot(ComposersSelectors.composerById(composerId));
    if (composer.draftSaveStatus === 'void' || !composer.draft) return of(null);

    ctx.setState(
      patch<ComposersStateModel>({
        composers: updateItem((composer) => composer.id === composerId, patch({ draftSaveStatus: 'saving' })),
      }),
    );

    return this.composersService.saveDraft(draftId, draftPayload).pipe(
      tap((draft) => {
        this.composersActionsService.upsertDraft(draft);

        ctx.setState(
          patch<ComposersStateModel>({
            composers: updateItem(
              (composer) => composer.id === composerId,
              patch({
                draft,
                draftSaveStatus: 'saved',
              }),
            ),
          }),
        );
      }),
      catchError(() => {
        ctx.setState(
          patch<ComposersStateModel>({
            composers: updateItem((composer) => composer.id === composerId, patch({ draftSaveStatus: 'error' })),
          }),
        );
        return of(null);
      }),
      map(() => undefined),
    );
  }

  @Action(SetMessageType, { cancelUncompleted: true })
  setMessageType(
    ctx: StateContext<ComposersStateModel>,
    { composerId, newMessageType, newReplyToMessage }: SetMessageType,
  ): Observable<void> {
    const composer = this.store.selectSnapshot(ComposersSelectors.composerById(composerId));

    // Skip if nothing changed.
    if (composer.replyToMessage.id === newReplyToMessage.id && composer.messageType === newMessageType) return of(null);

    // Skip if conversation is unreplyable, and the message type is reply or reply all.
    if (composer.replyForbidden && (newMessageType === 'reply' || newMessageType === 'replyAll')) {
      this.portalService.presentToast(
        this.translate.instant('conversations-v4.composer.messageTypes.replyForbiddenNotice'),
        ToastType.Info,
      );

      return of(null);
    }

    // Determine new email participants.
    const myEmail = this.store.selectSnapshot(WorkspacesSelectors.externalAccountById(composer.senderAccountId)).email;
    const emailParticipants = getInitialEmailRecipients(newReplyToMessage, newMessageType, myEmail);

    // Determine if we need to load quoted content.
    const loadQuotedContent = (() => {
      // Internal messages don't have quoted content.
      if (newMessageType === 'internal') return false;

      // Reply and reply all have the same quoted content.
      return !(
        (composer.messageType === 'reply' && newMessageType === 'replyAll') ||
        (composer.messageType === 'replyAll' && newMessageType === 'reply')
      );
    })();

    return of(null).pipe(
      switchMap(() => {
        if (loadQuotedContent)
          return this.composersService.getQuotedContent(
            composer.senderAccountId,
            newReplyToMessage.id,
            newMessageType === 'forward' ? QuoteType.Forward : QuoteType.Reply,
          );

        if (newMessageType === 'internal') return of(emptyEditorHtml);
        return of(composer.quote);
      }),
      tap((quote) => {
        ctx.setState(
          patch<ComposersStateModel>({
            composers: updateItem(
              (composer) => composer.id === composerId,
              patch({
                messageType: newMessageType,
                quote,
                emailParticipants,
                replyToMessage: newReplyToMessage,
              }),
            ),
          }),
        );
      }),
      switchMap(() => ctx.dispatch(new SaveDraft(composerId))),
    );
  }

  @Action(SetRecipients)
  setRecipients(ctx: StateContext<ComposersStateModel>, { composerId, recipients }: SetRecipients): void {
    ctx.setState(
      patch<ComposersStateModel>({
        composers: updateItem((composer) => composer.id === composerId, patch({ emailParticipants: recipients })),
      }),
    );

    ctx.dispatch(new SaveDraft(composerId));
  }

  @Action(SetSubject)
  setSubject(ctx: StateContext<ComposersStateModel>, { composerId, subject }: SetSubject): void {
    ctx.setState(
      patch<ComposersStateModel>({
        composers: updateItem((composer) => composer.id === composerId, patch({ subject })),
      }),
    );

    // Because subject is an input field that can be changed very frequently,
    // we don't want to save draft on every change. The same applies to the message and quote fields.
    // Saving drafts for these fields should be handled in the composer components.
  }

  @Action(SetMessage)
  setMessage(ctx: StateContext<ComposersStateModel>, { composerId, message }: SetMessage): void {
    ctx.setState(
      patch<ComposersStateModel>({
        composers: updateItem((composer) => composer.id === composerId, patch({ message })),
      }),
    );
  }

  @Action(SetQuote)
  setQuote(ctx: StateContext<ComposersStateModel>, { composerId, quote }: SetQuote): void {
    ctx.setState(
      patch<ComposersStateModel>({
        composers: updateItem((composer) => composer.id === composerId, patch({ quote })),
      }),
    );
  }

  @Action(UploadFile)
  uploadFile(ctx: StateContext<ComposersStateModel>, { composerId, file }: UploadFile): Observable<void> {
    const composer = this.store.selectSnapshot(ComposersSelectors.composerById(composerId));

    if (composer.messageType === 'new') return;

    if (!composer.draft)
      return ctx
        .dispatch(new SaveDraft(composerId))
        .pipe(switchMap(() => ctx.dispatch(new UploadFile(composerId, file))));

    // Uploading the file.
    const fileId = uuid();
    const cancel$ = new Subject<void>();

    return this.composersService.uploadAttachment(composer.draft.id, file).pipe(
      tap((event) => {
        switch (event.type) {
          case HttpEventType.Sent:
            return ctx.setState(
              patch<ComposersStateModel>({
                composers: updateItem(
                  (composer) => composer.id === composerId,
                  patch({
                    activeFileUploads: insertItem({
                      id: fileId,
                      file,
                      status: 'uploading',
                      progress: 0,
                      cancel$,
                    }),
                  }),
                ),
              }),
            );

          case HttpEventType.UploadProgress:
            return ctx.setState(
              patch<ComposersStateModel>({
                composers: updateItem(
                  (composer) => composer.id === composerId,
                  patch({
                    activeFileUploads: updateItem(
                      (upload) => upload.id === fileId,
                      patch({
                        progress: event.loaded / event.total,
                      }),
                    ),
                  }),
                ),
              }),
            );

          case HttpEventType.Response:
            ctx.setState(
              patch<ComposersStateModel>({
                composers: updateItem(
                  (composer) => composer.id === composerId,
                  patch({
                    activeFileUploads: removeItem((upload) => upload.id === fileId),
                    draft: patch({
                      attachments: insertItem(mapDraftAttachment(event.body as DraftAttachmentResponse)),
                    }),
                  }),
                ),
              }),
            );

            return ctx.dispatch(new SaveDraft(composerId));
        }
      }),
      catchError(() => {
        ctx.setState(
          patch<ComposersStateModel>({
            composers: updateItem(
              (composer) => composer.id === composerId,
              patch({
                activeFileUploads: updateItem(
                  (upload) => upload.id === fileId,
                  patch({
                    status: 'error',
                    error: FileUploadError.Unknown,
                  }),
                ),
              }),
            ),
          }),
        );
        return of(null);
      }),
      map(() => undefined),
      takeUntil(cancel$),
    );
  }

  @Action(AttachFileFromCloverStorage)
  attachFileFromCloverStorage(
    ctx: StateContext<ComposersStateModel>,
    { composerId, userFileIds, companyFileIds }: AttachFileFromCloverStorage,
  ): Observable<void> {
    const composer = this.store.selectSnapshot(ComposersSelectors.composerById(composerId));

    if (composer.messageType === 'new') return;

    if (!composer.draft)
      return ctx
        .dispatch(new SaveDraft(composerId))
        .pipe(switchMap(() => ctx.dispatch(new AttachFileFromCloverStorage(composerId, userFileIds, companyFileIds))));

    return this.composersService.attachFileFromCloverStorage(composer.draft.id, userFileIds, companyFileIds).pipe(
      tap((attachments) => {
        ctx.setState(
          patch<ComposersStateModel>({
            composers: updateItem(
              (composer) => composer.id === composerId,
              patch({
                draft: patch({
                  attachments: append(attachments),
                }),
              }),
            ),
          }),
        );

        ctx.dispatch(new SaveDraft(composerId));
      }),
      map(() => undefined),
    );
  }

  @Action(RemoveActiveFileUpload)
  removeActiveFileUpload(
    ctx: StateContext<ComposersStateModel>,
    { composerId, uploadId }: RemoveActiveFileUpload,
  ): void {
    ctx.setState(
      patch<ComposersStateModel>({
        composers: updateItem(
          (composer) => composer.id === composerId,
          patch({
            activeFileUploads: removeItem((upload) => upload.id === uploadId),
          }),
        ),
      }),
    );
  }

  @Action(RemoveDraftAttachment)
  removeDraftAttachment(
    ctx: StateContext<ComposersStateModel>,
    { composerId, attachmentId }: RemoveDraftAttachment,
  ): Observable<void> {
    const composer = this.store.selectSnapshot(ComposersSelectors.composerById(composerId));
    if (composer.messageType === 'new') return;

    const draft = composer.draft;
    if (!draft) return;

    return this.composersService.removeAttachment(draft.id, attachmentId).pipe(
      tap(() => {
        ctx.setState(
          patch<ComposersStateModel>({
            composers: updateItem(
              (composer) => composer.id === composerId,
              patch({
                draft: patch({
                  attachments: removeItem((attachment) => attachment.id === attachmentId),
                }),
              }),
            ),
          }),
        );

        ctx.dispatch(new SaveDraft(composerId));
      }),
    );
  }

  @Action(SendNewEmail)
  sendNewEmail(ctx: StateContext<ComposersStateModel>, { composerId }: SendNewEmail): Observable<void> {
    const composer = this.store.selectSnapshot(ComposersSelectors.composerById(composerId));

    ctx.setState(
      patch<ComposersStateModel>({
        composers: updateItem((composer) => composer.id === composerId, patch({ sendStatus: 'sending' })),
      }),
    );

    return this.composersService.sendNewEmail(composer).pipe(
      tap(() => {
        this.portalService.presentToast(
          this.translate.instant('conversations-v4.composer.toasts.emailSent'),
          ToastType.Success,
        );
      }),
      catchError(() => {
        this.portalService.presentToast(
          this.translate.instant('conversations-v4.composer.toasts.emailFailed'),
          ToastType.Error,
        );
        return of(null);
      }),
      switchMap(() => ctx.dispatch(new DestroyComposer(composerId))),
      map(() => undefined),
    );
  }

  @Action(SendDraft)
  sendDraft(ctx: StateContext<ComposersStateModel>, { composerId }: SendDraft): Observable<void> {
    const composer = this.store.selectSnapshot(ComposersSelectors.composerById(composerId));

    ctx.setState(
      patch<ComposersStateModel>({
        composers: updateItem((composer) => composer.id === composerId, patch({ sendStatus: 'sending' })),
      }),
    );

    return of(null).pipe(
      switchMap(() => {
        if (composer.messageType === 'internal') return this.composersService.sendDraftInternalMessage(composer);
        return this.composersService.sendDraftEmail(composer);
      }),
      switchMap((traceId: string) =>
        ctx.dispatch(
          new AddPendingMessage(
            composer.conversationId,
            draftToPendingMessage(
              composer.draft as Draft,
              composer.conversationId,
              traceId,
              this.userService.userProfile,
            ),
          ),
        ),
      ),
      tap(() => this.composersActionsService.removeDraft(composer.draft)),
      tap(() => {
        ctx.setState(
          patch<ComposersStateModel>({
            composers: updateItem((composer) => composer.id === composerId, patch({ sendStatus: 'void' })),
          }),
        );
      }),
      map(() => undefined),
    );
  }

  @Action(ResetComposer)
  resetComposer(
    ctx: StateContext<ComposersStateModel>,
    { composerId, preselectedDraft }: ResetComposer,
  ): Observable<void> {
    const composer = this.store.selectSnapshot(ComposersSelectors.composerById(composerId));
    const activeConversationDetails = this.store.selectSnapshot(ConversationSelectors.details);

    if (activeConversationDetails.id !== composer.conversationId || composer.messageType === 'new')
      return ctx.dispatch(new DestroyComposer(composerId));

    return defer(() => {
      // The reason we reload the draft is because backend only includes the full draft response in details.lastDraft.
      // Drafts returned with a message does not have all the fields needed for the composer (e.g., attachments).
      if (preselectedDraft) return this.composersService.getDraft(preselectedDraft.id);
      return of(undefined);
    }).pipe(
      tap((draft: Draft | undefined) => {
        const emptyComposer = createConversationComposer(composerId, activeConversationDetails, draft);

        ctx.setState(
          patch<ComposersStateModel>({
            composers: updateItem(
              (composer) => composer.id === composerId,
              patch({ ...emptyComposer, presentation: composer.presentation }),
            ),
          }),
        );
      }),
      map(() => undefined),
    );
  }
}
