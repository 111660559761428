<div class="conversations-list">
  <div class="conversations-list__header">
    <div
      [class.conversations-list__header-main--divided]="!filterableByStatus"
      class="conversations-list__header-main"
    >
      <div class="conversations-list__header-main-label">
        {{ categoryLabel }}
      </div>
    </div>

    @if (filterableByStatus) {
      <div
        @fadeInOut
        class="conversations-list__header-status-selector"
      >
        <cc-tab-selector
          (tabSelect)="setStatus($event)"
          [inlinePadding]="4"
          [selectedTabId]="activeStatus"
          [tabs]="statusTabs"
        ></cc-tab-selector>
      </div>
    }
  </div>

  <div
    [ccAutoAnimate]
    class="conversations-list__content"
  >
    @if (allAccountsSynced$ | async) {
      @if (['loaded', 'loading-next'].includes(loadingStatus)) {
        @if (conversations.length > 0) {
          <ng-scrollbar
            (scrolled)="loadNextConversations()"
            [infiniteScrollDistance]="4"
            [infiniteScrollThrottle]="0"
            [scrollWindow]="false"
            cdkScrollable
            infiniteScroll
            orientation="vertical"
          >
            <div
              [ccAutoAnimate]
              class="conversations-list__content-items"
            >
              @for (conversation of conversations; track conversation.id) {
                <cc-conversations-list-item
                  #rla="routerLinkActive"
                  (markAsRead)="updateReadStatus(conversation, true)"
                  (markAsUnread)="updateReadStatus(conversation, false)"
                  [active]="rla.isActive"
                  [conversation]="conversation"
                  [routerLink]="[conversation.id]"
                  routerLinkActive
                />
              }
            </div>
          </ng-scrollbar>
        } @else {
          <cc-conversations-list-placeholder
            class="conversations-list__content-placeholder"
            type="empty"
          />
        }
      }

      @if (loadingStatus === 'loading') {
        <cc-conversations-list-placeholder
          class="conversations-list__content-placeholder"
          type="loading"
        />
      }

      @if (loadingStatus === 'error') {
        <cc-conversations-list-placeholder
          class="conversations-list__content-placeholder"
          type="error"
        />
      }
    } @else {
      <cc-conversations-list-placeholder
        class="conversations-list__content-placeholder"
        type="initializing"
        [syncingAccounts]="syncingExternalAccounts$ | async"
      />
    }
  </div>
</div>
