import { ConnectionPositionPair, Overlay, OverlayModule } from '@angular/cdk/overlay';
import { ChangeDetectionStrategy, ChangeDetectorRef, Component, inject, Input, OnDestroy, OnInit } from '@angular/core';
import { ComposerInstance } from '@conversations/composer/state/composers/composers-state.model';
import { getOverlayVisibilityAfterOutsideClick } from '@core/helpers/get-overlay-visibility-after-outside-click';
import { ButtonSize, ButtonType } from '@design/buttons/button/types';
import { DropdownActionComponent } from '@design/overlays/dropdown/dropdown-action/dropdown-action.component';
import { DropdownDividerComponent } from '@design/overlays/dropdown/dropdown-divider/dropdown-divider.component';
import { DropdownTextComponent } from '@design/overlays/dropdown/dropdown-text/dropdown-text.component';
import { DropdownComponent } from '@design/overlays/dropdown/dropdown.component';
import { skip, Subject } from 'rxjs';

import { Dialog, DialogModule } from '@angular/cdk/dialog';
import { ComponentPortal } from '@angular/cdk/portal';
import { FilesModule } from '@clover/files/files.module';
import { FilesSelectorModalComponent } from '@clover/files/modals/files-selector-modal/files-selector-modal.component';
import { UploadedFile } from '@clover/files/models/file';
import {
  ComposerActionsLinkEditorDialogComponent,
  ComposerActionsLinkEditorDialogData,
  ComposerActionsLinkEditorDialogResult,
} from '@conversations/composer/composer-actions/composer-actions-link-editor-dialog/composer-actions-link-editor-dialog.component';
import { ComposerActionsLinkPopupComponent } from '@conversations/composer/composer-actions/composer-actions-link-popup/composer-actions-link-popup.component';
import { ComposerActionsTableEditorComponent } from '@conversations/composer/composer-actions/composer-actions-table-editor/composer-actions-table-editor.component';
import { ComposersActionsDistributorService } from '@conversations/composer/state/composers/composers-actions-distributor.service';
import {
  AttachFileFromCloverStorage,
  ResetComposer,
  SendDraft,
  SendNewEmail,
  UploadFile,
} from '@conversations/composer/state/composers/composers.actions';
import {
  TaskEmbedDialogData,
  TaskEmbedDialogResult,
  TaskEmbedModalComponent,
} from '@conversations/tasks/task-embed-modal/task-embed-modal.component';
import { EMAIL_REGEX_PATTERN } from '@core/constants/regex-patterns';
import { CoreModule } from '@core/core.module';
import { sanitize } from '@core/helpers/sanitize-email/sanitizer';
import { DateFormatDistancePipe } from '@core/pipes/date-format.pipe';
import { ConfigService } from '@core/services/config.service';
import { ModalService } from '@core/services/modal.service';
import { ButtonComponent } from '@design/buttons/button/button.component';
import {
  BusinessObjectModalComponent,
  type BPIEmbedDialogData,
  type BPIEmbedDialogResult,
} from '@design/overlays/business-object-modal/business-object-modal.component';
import { BusinessObjectService } from '@design/overlays/business-object-modal/business-object.service';
import {
  ConfirmationDialogAppearance,
  ConfirmationDialogComponent,
  ConfirmationDialogData,
  ConfirmationDialogResult,
} from '@design/overlays/confirmation-dialog/confirmation-dialog.component';
import { TooltipAlignment } from '@design/overlays/tooltip/tooltip';
import { TooltipDirective } from '@design/overlays/tooltip/tooltip.directive';
import { UntilDestroy, untilDestroyed } from '@ngneat/until-destroy';
import { TranslateModule, TranslateService } from '@ngx-translate/core';
import { Store } from '@ngxs/store';
import { Editor, type Content } from '@tiptap/core';
import { Mark, Node } from 'prosemirror-model';
import { EditorView } from 'prosemirror-view';
import { take } from 'rxjs/operators';

interface MarkPosition {
  from: number;
  to: number;
}

@UntilDestroy()
@Component({
  selector: 'cc-composer-actions',
  standalone: true,
  templateUrl: './composer-actions.component.html',
  styleUrls: ['./composer-actions.component.scss'],
  changeDetection: ChangeDetectionStrategy.OnPush,
  imports: [
    DropdownActionComponent,
    DropdownDividerComponent,
    DropdownTextComponent,
    DropdownComponent,
    OverlayModule,
    DialogModule,
    CoreModule,
    FilesModule,
    ButtonComponent,
    TooltipDirective,
    DateFormatDistancePipe,
    ComposerActionsTableEditorComponent,
    TranslateModule,
  ],
})
export class ComposerActionsComponent implements OnInit, OnDestroy {
  @Input()
  composer: ComposerInstance;

  @Input()
  newTaskCreationDisabled = false;

  @Input()
  fallbackEditor: Editor;

  protected activeEditor: Editor;
  protected editorSelectionUpdate$ = new Subject<void>();

  protected plusDropdownVisible = false;

  protected readonly dropdownPositionStrategy: ConnectionPositionPair[] = [
    { originX: 'start', originY: 'top', overlayX: 'start', overlayY: 'bottom', offsetY: -4 },
  ];
  protected readonly ButtonType = ButtonType;
  protected readonly ButtonSize = ButtonSize;
  protected readonly getOverlayVisibilityAfterOutsideClick = getOverlayVisibilityAfterOutsideClick;
  protected readonly TooltipAlignment = TooltipAlignment;

  private readonly cdr = inject(ChangeDetectorRef);
  private readonly dialog = inject(Dialog);
  private readonly overlay = inject(Overlay);
  private readonly store = inject(Store);
  private readonly legacyModalService = inject(ModalService);
  private readonly composersActionsService = inject(ComposersActionsDistributorService);
  private readonly businessObjectService = inject(BusinessObjectService);
  private readonly translate = inject(TranslateService);

  protected get bold(): boolean {
    return this.activeEditor.isActive('bold');
  }

  protected get italic(): boolean {
    return this.activeEditor.isActive('italic');
  }

  protected get underline(): boolean {
    return this.activeEditor.isActive('underline');
  }

  protected get strikethrough(): boolean {
    return this.activeEditor.isActive('strike');
  }

  protected get code(): boolean {
    return this.activeEditor.isActive('code');
  }

  protected get link(): boolean {
    return this.activeEditor.isActive('link');
  }

  protected get table(): boolean {
    return this.activeEditor.isActive('table');
  }

  protected get draftSavedJustNow(): boolean {
    if (!this.composer.draft) return false;

    const now = new Date();
    const draftSavedAt = new Date(this.composer.draft.updatedAt);
    return now.getTime() - draftSavedAt.getTime() < 60 * 1000;
  }

  protected get sendDisabled(): boolean {
    if (this.composer.sendStatus === 'sending') return true;

    if (this.composer.messageType !== 'internal') {
      // Recipients should be present and valid for email messages
      const { to, cc, bcc } = this.composer.emailParticipants;
      const recipients = [...to, ...cc, ...bcc];

      const recipientsInvalid =
        recipients.length < 1 || recipients.some((recipient) => !EMAIL_REGEX_PATTERN.test(recipient.email));

      if (recipientsInvalid) return true;
    }

    if (this.composer.messageType !== 'new') {
      // Draft should be saved
      const draftInvalid = !this.composer.draft || this.composer.draftSaveStatus !== 'saved';
      if (draftInvalid) return true;
    }

    if (this.composer.messageType === 'internal') {
      // Internal messages should have content
      const plainText = sanitize(this.composer.draft.content, null, {
        noWrapper: true,
        dropAllHtmlTags: true,
      });

      const emptyContent = !plainText.trim();
      if (emptyContent) return true;
    }

    return false;
  }

  ngOnInit(): void {
    this.setActiveEditor(this.fallbackEditor);
  }

  setActiveEditor(editor: Editor): void {
    this.activeEditor = editor;
    this.setupEditorListeners();

    this.activeEditor.view.setProps({
      handleClickOn: this.handleNodeClick.bind(this),
    });
  }

  ngOnDestroy(): void {
    this.destroyEditorListeners();
  }

  protected deleteDraft(): void {
    const dialog = this.dialog.open<ConfirmationDialogResult, ConfirmationDialogData>(ConfirmationDialogComponent, {
      data: {
        title: this.translate.instant('conversations-v4.composer.deleteDraftPrompt.title'),
        message: this.translate.instant('conversations-v4.composer.deleteDraftPrompt.message'),
        confirmText: this.translate.instant('common.buttons.delete'),
        cancelText: this.translate.instant('common.buttons.cancel'),
        destructive: true,
        style: ConfirmationDialogAppearance.Compact,
      },
    });

    dialog.closed.pipe(take(1)).subscribe((result: ConfirmationDialogResult | undefined) => {
      if (result !== 'confirm') return;
      this.composersActionsService.deleteDraft(this.composer.draft);
    });
  }

  protected createNewDraft(): void {
    const dialog = this.dialog.open<ConfirmationDialogResult, ConfirmationDialogData>(ConfirmationDialogComponent, {
      data: {
        title: this.translate.instant('conversations-v4.composer.newDraftPrompt.title'),
        message: this.translate.instant('conversations-v4.composer.newDraftPrompt.message'),
        confirmText: this.translate.instant('conversations-v4.composer.newDraftPrompt.save'),
        secondaryActionText: this.translate.instant('conversations-v4.composer.newDraftPrompt.dontSave'),
        cancelText: this.translate.instant('common.buttons.cancel'),
        destructive: false,
        style: ConfirmationDialogAppearance.Compact,
      },
    });

    dialog.closed.pipe(take(1)).subscribe((result: ConfirmationDialogResult | undefined) => {
      if (result !== 'confirm' && result !== 'secondaryAction') return;

      switch (result) {
        case 'confirm':
          this.store.dispatch(new ResetComposer(this.composer.id));
          break;
        case 'secondaryAction':
          this.composersActionsService.deleteDraft(this.composer.draft);
          break;
      }
    });
  }

  protected presentTaskEmbedDialog(): void {
    const dialog = this.dialog.open<TaskEmbedDialogResult, TaskEmbedDialogData>(TaskEmbedModalComponent);

    dialog.closed.pipe(take(1)).subscribe((task: TaskEmbedDialogResult | undefined) => {
      if (!task) return;

      const taskLinkTitle = `#${task.id}`;
      const taskLink = `${ConfigService.settings.apiUrl}/tasks/task_id=${task.id}`;

      this.activeEditor
        .chain()
        .insertContent({ type: 'text', text: taskLinkTitle, marks: [{ type: 'link', attrs: { href: taskLink } }] })
        .focus()
        .run();
    });
  }

  protected presentBusinessObjectEmbedDialog(): void {
    const dialog = this.dialog.open<BPIEmbedDialogResult, BPIEmbedDialogData>(BusinessObjectModalComponent);

    dialog.closed.pipe(take(1)).subscribe((result: BPIEmbedDialogResult | undefined) => {
      if (!result) return;

      const tableContent: Content | undefined =
        result.type === 'order'
          ? this.businessObjectService.generateOrderTable(result.order)
          : result.type === 'invoice'
            ? this.businessObjectService.generateInvoiceTable(result.invoice)
            : undefined;

      if (!tableContent) return;

      this.activeEditor
        .chain()
        .insertContent(tableContent, {
          parseOptions: { preserveWhitespace: false },
        })
        .focus()
        .run();
    });
  }

  protected uploadFile(event: Event): void {
    const uploadInput = event.target as HTMLInputElement;

    const [file] = Array.from(uploadInput.files);
    uploadInput.value = null;
    this.store.dispatch(new UploadFile(this.composer.id, file));
  }

  protected attachFileFromCloverStorage(): void {
    this.legacyModalService
      .open({
        content: FilesSelectorModalComponent,
        options: {
          size: 'xl',
        },
      })
      .result.then((files: UploadedFile[]) => {
        const userFilesIds = files.filter((file) => file.fileOwner === 'User').map((file) => file.id);
        const companyFilesIds = files.filter((file) => file.fileOwner === 'Company').map((file) => file.id);

        this.store.dispatch(new AttachFileFromCloverStorage(this.composer.id, userFilesIds, companyFilesIds));
      })
      .catch(() => {});
  }

  protected insertEmoji(): void {
    this.activeEditor.chain().insertContent('😀').focus().run();
  }

  protected toggleMark(mark: 'bold' | 'italic' | 'underline' | 'strike' | 'code' | 'link'): void {
    this.activeEditor.chain().toggleMark(mark).focus().run();
  }

  protected presentLinkEditor(
    mode: 'insert' | 'edit',
    title = '',
    link = '',
    position: MarkPosition | undefined = undefined,
  ): void {
    const getRange = () => {
      if (!position) {
        const selection = this.activeEditor.view.state.selection;
        const { from, to } = selection;
        return { from, to };
      }

      return position;
    };

    const { from, to } = getRange();

    const dialog = this.dialog.open<ComposerActionsLinkEditorDialogResult, ComposerActionsLinkEditorDialogData>(
      ComposerActionsLinkEditorDialogComponent,
      {
        data: {
          mode,
          title: title || this.activeEditor.view.state.doc.textBetween(from, to),
          link,
        },
      },
    );

    dialog.closed.pipe(take(1)).subscribe((result: ComposerActionsLinkEditorDialogResult | undefined) => {
      if (!result) return;

      const state = this.activeEditor.view.state;
      const tr = state.tr.replaceWith(
        from,
        to,
        state.schema.text(result.title, [state.schema.marks.link.create({ title: result.title, href: result.link })]),
      );
      this.activeEditor.view.dispatch(tr);
      this.activeEditor.view.focus();
    });
  }

  protected send(): void {
    if (this.composer.messageType === 'new') {
      this.store.dispatch(new SendNewEmail(this.composer.id));
      return;
    }

    this.store.dispatch(new SendDraft(this.composer.id));
  }

  private handleNodeClick(view: EditorView, pos: number, _: Node, __: number, event: MouseEvent): void {
    const node = view.state.doc.nodeAt(pos);
    if (!node) return;

    const linkMark = node.marks.find((mark) => mark.type.name === 'link');
    if (linkMark) this.handleLinkClick(node, linkMark, event);
  }

  private handleLinkClick(node: Node, mark: Mark, event: MouseEvent): void {
    const title = node.textContent;
    const url = mark.attrs.href;

    const linkElement = event.target as HTMLElement;
    const from = this.activeEditor.view.posAtDOM(linkElement, 0);
    const to = from + node.nodeSize;

    const overlay = this.overlay.create({
      positionStrategy: this.overlay
        .position()
        .flexibleConnectedTo(linkElement)
        .withPositions([
          {
            originX: 'center',
            originY: 'top',
            overlayX: 'start',
            overlayY: 'bottom',
            offsetX: -20,
            offsetY: -8,
          },
        ]),
      hasBackdrop: false,
      scrollStrategy: this.overlay.scrollStrategies.close(),
    });

    const linkPopup = overlay.attach(new ComponentPortal(ComposerActionsLinkPopupComponent));
    linkPopup.instance.title = title;
    linkPopup.instance.url = url;

    overlay
      .outsidePointerEvents()
      .pipe(skip(1), take(1))
      .subscribe(() => overlay.dispose());

    linkPopup.instance.dismiss.pipe(take(1)).subscribe(() => {
      overlay.dispose();
      this.activeEditor.view.focus();
    });

    linkPopup.instance.edit.pipe(take(1)).subscribe(() => {
      overlay.dispose();
      this.presentLinkEditor('edit', title, url, { from, to });
    });

    linkPopup.instance.delete.pipe(take(1)).subscribe(() => {
      overlay.dispose();
      const tr = this.activeEditor.view.state.tr.removeMark(from, to, mark);
      this.activeEditor.view.dispatch(tr);
      this.activeEditor.view.focus();
    });
  }

  private setupEditorListeners(): void {
    this.destroyEditorListeners();

    this.activeEditor.on('selectionUpdate', () => this.editorSelectionUpdate$.next());
    this.editorSelectionUpdate$.pipe(untilDestroyed(this)).subscribe(() => this.cdr.detectChanges());
  }

  private destroyEditorListeners(): void {
    this.activeEditor.off('selectionUpdate');
  }
}
