<div class="overlay-composers">
  @for (composer of overlayComposers$ | async; track composer.id) {
    <div
      @enterLeaveTransition
      [class.overlay-composers__composer--collapsed]="composer.presentation === 'overlay-collapsed'"
      class="overlay-composers__composer"
    >
      <cc-composer [composer]="composer"></cc-composer>
    </div>
  }
</div>

<div class="overlay-composers-anchor"></div>
