<cc-message-collapsible-group
  (collapse)="collapsed = true"
  (expand)="handleCollapsibleGroupExpand()"
  [collapsed]="collapsed"
>
  <ng-container slot="title">
    @if (tasksIds.length === 1) {
      {{ 'conversations-v4.tasks.taskReferenceCountSingular' | translate }}
    }
    @if (tasksIds.length !== 1) {
      {{ 'conversations-v4.tasks.taskReferenceCountPlural' | translate: { count: tasksIds.length } }}
    }
  </ng-container>

  <div class="message-task-references">
    @if (tasks$ | async; as tasks) {
      @for (task of tasks; track task.id) {
        <cc-task-card [task]="task" />
      }
    }
  </div>
</cc-message-collapsible-group>
