<div class="task-card">
  <div class="task-card__header">
    <div class="task-card__header-title">{{ task.name }}</div>

    <div class="task-card__header-metadata">
      <span class="task-card__header-metadata-id">#{{ task.id }}</span>
      <div class="task-card__header-metadata-divider"></div>
      <span class="task-card__header-metadata-status">
        {{
          'conversations-v4.tasks.assignedByAgo'
            | translate
              : {
                  name: task.assignerCompany.name,
                  ago:
                    task.assignedAt
                    | ccDateFormatDistance
                      : {
                          strict: true,
                          locale: getTaskAssignedAtLocale(),
                        },
                }
        }}
      </span>
    </div>
  </div>

  @if (task.product) {
    <cc-task-product-card [product]="task.product"></cc-task-product-card>
  }

  <div class="task-card__footer">
    <div class="task-card__footer-actions">
      <cc-task-performer-select
        [canAssignToCurrentUser]="canAssignToCurrentUser"
        [canChangeAssignee]="canChangeAssignee"
        [canReroute]="canReroute"
        [performerCompany]="task.assigneeCompany"
        [performer]="task.acceptedBy"
        [taskId]="task.id"
        type="assignee"
      ></cc-task-performer-select>
    </div>

    <div class="task-card__footer-info">
      <cc-task-status-chip [status]="task.status"></cc-task-status-chip>

      <div
        [ccTooltip]="'conversations-v4.tasks.usersWithAccess' | translate"
        [tooltipAlignment]="TooltipAlignment.End"
        class="task-card__footer-info-users"
      >
        <i class="task-card__footer-info-users-icon icon-users"></i>
        <div class="task-card__footer-info-users-value">{{ usersWithAccessCount }}</div>
      </div>
    </div>
  </div>

  <div
    [class.task-card__actions--keep-open]="moreActionsDropdownVisible"
    class="task-card__actions"
  >
    @if (canOpenTaskWizard) {
      <app-button
        [routerLink]="['/', 'tasks', 'view', task.id]"
        [size]="ButtonSize.Big"
        [type]="ButtonType.Ghost"
        [ccTooltip]="'conversations-v4.tasks.openInTaskWizard' | translate"
      >
        <i
          class="icon-external"
          slot="ghost-icon"
        ></i>
      </app-button>
      <div class="task-card__actions-divider"></div>
    }

    <div #moreActionsDropdownTrigger>
      <app-button
        (click)="moreActionsDropdownVisible = !moreActionsDropdownVisible"
        [ccTooltip]="'common.buttons.moreActions' | translate"
        [size]="ButtonSize.Big"
        [tooltipAlignment]="TooltipAlignment.End"
        [type]="ButtonType.Ghost"
      >
        <i
          class="icon-more"
          slot="ghost-icon"
        ></i>
      </app-button>
    </div>
  </div>
</div>

<ng-template
  (detach)="moreActionsDropdownVisible = false"
  (overlayOutsideClick)="
    moreActionsDropdownVisible = getOverlayVisibilityAfterOutsideClick($event, moreActionsDropdownTrigger)
  "
  [cdkConnectedOverlayOpen]="moreActionsDropdownVisible"
  [cdkConnectedOverlayOrigin]="moreActionsDropdownTrigger"
  [cdkConnectedOverlayPositions]="moreActionsDropdownPositionStrategy"
  cdkConnectedOverlay
>
  <cc-dropdown width="200px">
    <cc-dropdown-action
      (click)="moreActionsDropdownVisible = false"
      [routerLink]="[{ outlets: { info: ['task', task.id] } }]"
      leadingIcon="icon-info"
    >
      <ng-container slot="title">
        {{ 'conversations-v4.tasks.taskInfo' | translate }}
      </ng-container>
    </cc-dropdown-action>

    <cc-dropdown-action
      (click)="copyLink(); moreActionsDropdownVisible = false"
      leadingIcon="icon-link"
    >
      <ng-container slot="title">
        {{ 'conversations-v4.tasks.copyLink' | translate }}
      </ng-container>
    </cc-dropdown-action>

    @if (canSendReminder || canCloseTask) {
      <cc-dropdown-divider></cc-dropdown-divider>
      @if (canSendReminder) {
        <cc-dropdown-action
          (click)="sendReminder(); moreActionsDropdownVisible = false"
          leadingIcon="icon-notification-up"
        >
          <ng-container slot="title">
            {{ 'conversations-v4.tasks.sendReminder' | translate }}
          </ng-container>
        </cc-dropdown-action>
      }
      @if (canCloseTask) {
        <cc-dropdown-action
          (click)="closeTask(); moreActionsDropdownVisible = false"
          leadingIcon="icon-task-close"
        >
          <ng-container slot="title">
            {{ 'conversations-v4.tasks.closeTask' | translate }}
          </ng-container>
        </cc-dropdown-action>
      }
    }
  </cc-dropdown>
</ng-template>
