import { ComponentRef, inject, Injectable } from '@angular/core';
import { TasksService } from '@conversations/tasks/tasks.service';
import { mergeAttributes, Node } from '@tiptap/core';
import { Overlay, OverlayRef } from '@angular/cdk/overlay';
import { ComponentPortal } from '@angular/cdk/portal';
import { TaskAutocompleteComponent } from '@conversations/composer/composer-message-input/task-autocomplete/task-autocomplete.component';
import { Mention } from '@tiptap/extension-mention';
import { firstValueFrom } from 'rxjs';
import { PagingOrder } from '@core/helpers/paging';
import { map } from 'rxjs/operators';
import { ConfigService } from '@core/services/config.service';

export type TaskAutocompleteAcceptFn = (props: TaskNodeAttributes) => void;

export interface TaskNodeAttributes {
  id: number;
  label: string;
}

@Injectable({
  providedIn: 'root',
})
export class TaskAutocompleteService {
  private overlayRef: OverlayRef | null = null;
  private autocompleteRef: ComponentRef<TaskAutocompleteComponent> | null = null;

  private readonly tasksService = inject(TasksService);
  private readonly overlay = inject(Overlay);

  get TaskReference(): Node {
    return Mention.configure({
      renderHTML({ options, node }) {
        const attrs = node.attrs as TaskNodeAttributes;

        return [
          'a',
          mergeAttributes(
            { href: `${ConfigService.settings.apiUrl}/tasks/task_id=${attrs.id}` },
            options.HTMLAttributes,
          ),
          `${options.suggestion.char}${attrs.id}`,
        ];
      },
      suggestion: {
        char: '#',
        allowSpaces: false,

        items: async ({ query }): Promise<TaskNodeAttributes[]> => {
          const taskIdRegex = /^\d+$/;
          if (query && !taskIdRegex.test(query)) return [];

          return await firstValueFrom(
            this.tasksService
              .getTasksSuggestions(query, {
                limit: 5,
                orderBy: 'id',
                order: PagingOrder.Ascending,
              })
              .pipe(map((response) => response.data.map((task) => ({ id: task.id, label: task.name })))),
          );
        },

        render: () => {
          return {
            onStart: (props) => {
              const parent = props.decorationNode.parentElement;
              if (parent.tagName === 'A') return;

              this.setupAutocomplete(props.decorationNode, props.items, props.command);
            },

            onUpdate: (props) => {
              this.updateItems(props.items);
            },

            onKeyDown: (props) => {
              switch (props.event.key) {
                case 'Escape':
                  this.destroyAutocomplete();
                  return true;
              }

              return false;
            },

            onExit: () => {
              this.destroyAutocomplete();
            },
          };
        },
      },
    });
  }

  private setupAutocomplete(
    origin: Element,
    items: TaskNodeAttributes[],
    // eslint-disable-next-line @typescript-eslint/ban-types
    accept: Function,
  ): void {
    this.destroyAutocomplete();

    this.overlayRef = this.overlay.create({
      positionStrategy: this.overlay
        .position()
        .flexibleConnectedTo(origin)
        .withPositions([
          {
            originX: 'start',
            originY: 'top',
            overlayX: 'start',
            overlayY: 'bottom',
            offsetX: -8,
            offsetY: -6,
          },
          {
            originX: 'end',
            originY: 'top',
            overlayX: 'end',
            overlayY: 'bottom',
            offsetX: 8,
            offsetY: -6,
          },
        ]),
      hasBackdrop: false,
      scrollStrategy: this.overlay.scrollStrategies.close(),
    });

    this.autocompleteRef = this.overlayRef.attach(new ComponentPortal(TaskAutocompleteComponent));
    this.autocompleteRef.instance.items = items;
    this.autocompleteRef.instance.accept = accept;
  }

  private updateItems(items: TaskNodeAttributes[]): void {
    if (!this.autocompleteRef) return;

    this.autocompleteRef.instance.updateItems(items);
  }

  private destroyAutocomplete(): void {
    if (!this.overlayRef) return;

    this.overlayRef.detach();
    this.overlayRef.dispose();
  }
}
