import Link from '@tiptap/extension-link';

export function withLink() {
  return [
    Link.configure({
      autolink: true,
      openOnClick: false, // We have custom overlay for link editing
      linkOnPaste: true,
      HTMLAttributes: {
        style: ['color: #04689a;', 'font-weight: 400;', 'text-decoration-line: underline;'].join('; '),
      },
    }),
  ];
}
