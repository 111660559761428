import { Extension } from '@tiptap/core';
import { Bold } from '@tiptap/extension-bold';
import { Code } from '@tiptap/extension-code';
import { Italic } from '@tiptap/extension-italic';
import { Link } from '@tiptap/extension-link';
import { Strike } from '@tiptap/extension-strike';
import { Underline } from '@tiptap/extension-underline';

const linkStyles = 'color: #04689a;' + 'font-weight: 400;' + 'text-decoration-line: underline;';

export const MarksKit = Extension.create({
  name: 'marksKit',

  addExtensions() {
    return [
      Bold,
      Italic,
      Underline,
      Strike,
      Code,
      Link.configure({
        autolink: true,
        openOnClick: false, // We have custom overlay for link editing
        linkOnPaste: true,
        HTMLAttributes: {
          style: linkStyles,
        },
      }),
    ];
  },
});
