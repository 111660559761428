import { Extension } from '@tiptap/core';
import { Document } from '@tiptap/extension-document';
import { Paragraph } from '@tiptap/extension-paragraph';
import { Text } from '@tiptap/extension-text';

const paragraphStyles = 'margin: 0;' + 'min-height: 20px;';

export const BaseKit = Extension.create({
  name: 'baseKit',

  addExtensions() {
    return [
      Document,
      Paragraph.configure({
        HTMLAttributes: {
          style: paragraphStyles,
        },
      }),
      Text,
    ];
  },
});
